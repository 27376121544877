<script setup>
import HauptMenue from "../components/HauptMenue.vue";
import FooterMenu from "../components/FooterMenu.vue";
import DataVersorgungsdokumente from "../components/DataVersorgung.vue";
</script>
<script>
import { store } from "../components/store.vue";
/** View-Komponente zur Anzeige aller Versorgungsdokumente gegliedert nach PMNR
 * @displayName Seite Versorgungsdokumente
 */
export default {
  name: "VersorgungsDokumente",
  components: {
    // myRouter: router,
    HauptMenue,
    FooterMenu,
    DataVersorgungsdokumente,
  },
  data() {
    return {
      store,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    window._paq.push(["setDocumentTitle", this.name]);
    window._paq.push(["trackPageView"]);
  },
  methods: {},
};
</script>
<template>
  <div class="main-wrapper flex-grow" :class="store.accessRestriction">
    <HauptMenue />
    <div class="mt-36 md:mt-48 lg:mt-56 max-w-5xl mx-auto px-5 lg:px-0 mb-32">
      <h1
        class="text-primary/75 font-bold text-2xl md:text-3xl lg:text-4xl text-center mb-12 uppercase">
        Meine Versorgungsdokumente
      </h1>
      <div class="p-8 border rounded-md text-xs">
        Die Dokumente werden je nach Art nach 3 Jahren (jährlich) oder 12
        Monaten (monatlich) nicht weiter im Portal angezeigt. Bis zum Ablauf der
        jeweiligen gesetzlichen Aufbewahrungsfrist werden diese aber bei Pensus
        gespeichert.
      </div>
      <h2
        class="text-gray-700 mb-8 text-2xl font-bold pb-2 border-b border-primary mt-16">
        Übersicht meiner Versorgungsdokumente
      </h2>
      <div v-if="typeof store.masterData.data.Versorgung == 'object'">
        <div v-if="store.masterData.data.Versorgung.PMNR.length >= 0">
          <div
            v-for="(p, index) in store.masterData.data.Versorgung.PMNR"
            v-bind:key="index">
            <DataVersorgungsdokumente :PMNR="index" />
          </div>
        </div>
        <div v-else>
          <div>
            <DataVersorgungsdokumente :PMNR="-1" />
          </div>
        </div>
      </div>
      <div v-else>
        <p>
          Es wurden bislang keine Versorgungsdokumente in Ihrem Benutzerprofil
          hinterlegt.
        </p>
      </div>
      <button
        class="mt-8 bg-primary text-white py-4 px-4 rounded-md text-lg lg:text-xl mx-auto block hover:bg-tertiary transition disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-primary"
        @click="$router.push({ name: 'versorgungsdaten' })">
        Zu meinen Versorgungsdaten
      </button>
    </div>
  </div>
  <FooterMenu />
</template>
