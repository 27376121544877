<script>
import HauptMenue from "../components/HauptMenue.vue";
import FooterMenu from "../components/FooterMenu.vue";
import AenderungsauftraegeSelfService from "../components/AenderungsauftraegeSelfService.vue";
import AuftraegeSelfService from "../components/AuftraegeSelfService.vue";
import Alertbox from "../components/Alertbox.vue";
import { store } from "../components/store.vue";
/** View-Komponente zur Anzeige verfügbarer Selfservices sowie nicht abgeholter Änderungsaufträge
 * @displayName Seite Auftragsübersicht Selfservices
 */
export default {
  name: "AuftragPensus",
  components: {
    // myRouter: router,
    HauptMenue,
    FooterMenu,
    AenderungsauftraegeSelfService,
    AuftraegeSelfService,
    Alertbox,
  },
  data() {
    return {
      inBearbeitung: {
        AenderungPersoenlichedaten: false,
        AenderungBankverbindung: false,
        ErfassungSteuerId: false,
        AenderungKontaktdaten: false,
        AntragBetriebsrente: false,
        AenderungKrankenkasse: false,
        AenderungAnsprechpartnerdaten: false,
        AenderungFirmendaten: false,
        AenderungFirmenAdressdaten: false,
        AenderungFirmenBankdaten: false,
        KontaktPensus: false,
        AenderungFirmenFinanzamtsdaten: false,
      },
      unversandt: {
        AenderungPersoenlichedaten: false,
        AenderungBankverbindung: false,
        ErfassungSteuerId: false,
        AenderungKontaktdaten: false,
        AntragBetriebsrente: false,
        AenderungKrankenkasse: false,
        AenderungAnsprechpartnerdaten: false,
        AenderungFirmendaten: false,
        AenderungFirmenAdressdaten: false,
        AenderungFirmenBankdaten: false,
        KontaktPensus: false,
        AenderungFirmenFinanzamtsdaten: false,
      },
      store,
      showSchnellzugriff: false,
    };
  },
  computed: {
    isWartung() {
      let wartung = false;
      let omessage = store.masterData.config?.messages?.DBWartung ?? false;
      let now = new Date();
      if (
        omessage &&
        Date.parse(omessage.von) < now &&
        Date.parse(omessage.bis) > now
      ) {
        wartung = true;
      }
      return wartung;
    },
    messageWartung() {
      let message = "";
      if (this.isWartung) {
        message = store.masterData.config?.messages?.DBWartung?.message ?? "";
      }

      return message;
    },
  },
  methods: {
    /* getServices(store, subData = false) {
      this.showSchnellzugriff = false;
      //let filtered = JSON.stringify(store.selfService); // Objekt kopieren
      let filtered = {};
      //filtered = JSON.parse(filtered);
      const keys = Object.keys(store.selfService);
      keys.forEach((key) => {
        let e = store.selfService[key];
        if (
          // nötige Ausschlusskriterien für Services Masterdaten
          !subData &&
          e.image.length > 0 && //es muss ein Bild da sein
          (e.conditionHide.indexOf("goLive") < 0 || !store.goLive) && //golive darf nicht geforder sein ODER wir sind NICHT goLive
          (e.conditionHide.indexOf("notAnwaerter") < 0 ||
            this.$servertalk.checkAnwaerter()) && //nur für Anwäerter oder er ist Anwärter
          (e.conditionHide.indexOf("notRentner") < 0 ||
            this.$servertalk.checkRentner()) && //nur für Rentner
          (e.conditionHide.indexOf("notVerbotFirma") < 0 ||
            this.$servertalk.checkVerbotFirma()) && //nur erlaubte Firmen
          (e.conditionHide.indexOf("Steuernummer") < 0 ||
            store.satzart !== "person" ||
            store.masterData.data.Stammdaten?.STEUERIDNR.length <= 0) && //keine Steuernummer oder er hat keine
          e.conditionHide.indexOf(store.satzart) < 0 && // Wenn gesetzt, nicht für person / ansprechpartner
          e.conditionHide.indexOf("firmaNotSelected") < 0
        ) {
          filtered[key] = e;
        }
        if (
          // nötige Ausschlusskriterien für Services Masterdaten
          subData &&
          e.image.length > 0 && //es muss ein Bild da sein
          (e.conditionHide.indexOf("goLive") < 0 || !store.goLive) && //golive darf nicht gefordert sein ODER wir sind NICHT goLive
          e.conditionHide.indexOf("firmaNotSelected") >= 0 &&
          store.subData.data?.Firmenstammdaten
        ) {
          filtered[key] = e;
        }
      });

      this.$servertalk.doLog(filtered);
      /*
      const keys = Object.keys(filtered);
      this.$servertalk.doLog(keys);
      keys.forEach((key, index) => {
        if (
          filtered[key].image.length == 0 || // Ausschlusskriterien für Services
          (filtered[key].conditionHide.indexOf("goLive") >= 0 &&
            store.goLive) ||
          (filtered[key].conditionHide.indexOf("notAnwaerter") >= 0 &&
            this.$servertalk.checkAnwaerter()) ||
          (filtered[key].conditionHide.indexOf("Steuernummer") >= 0 &&
            store.masterData.data.Stammdaten.STEUERIDNR.length > 0)
        )
          delete filtered[key];
      });
      */
    /*
      this.showSchnellzugriff = true;
      return filtered;
    },
    getInBearbeitung(index) {
      return this.inBearbeitung[index];
    },
    getUnversandt(index) {
      return this.unversandt[index];
    }, */
    chckUnbearbeitet() {
      for (const [key] of Object.entries(this.inBearbeitung)) {
        //console.log("->subData.draft.sService.Entwuerfe." + key);
        this.inBearbeitung[key] =
          this.$servertalk.gettAttr(
            "subData.draft.sService.Entwuerfe." + key
          ) == "modified" ||
          this.$servertalk.gettAttr(
            "masterData.draft.sService.Entwuerfe." + key
          ) == "modified";
        this.unversandt[key] =
          this.$servertalk.checkForUnsentSelfService(key) ||
          this.$servertalk.checkForUnsentSelfService(key, store.subData);
      }
    },
  },
  watch: {
    "store.subData.draft.sService.Entwuerfe": {
      handler() {
        this.chckUnbearbeitet();
      },
      deep: true,
    },
    "store.mainData.draft.sService.Entwuerfe": {
      handler() {
        this.chckUnbearbeitet();
      },
      deep: true,
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.$servertalk.doLog("Inhalt Store:", store);
    this.chckUnbearbeitet();
    window._paq.push(["setDocumentTitle", this.name]);
    window._paq.push(["trackPageView"]);
    this.showSchnellzugriff = true;
  },
};
</script>
<template>
  <div class="main-wrapper flex-grow" :class="store.accessRestriction">
    <HauptMenue />
    <div class="mt-36 md:mt-48 lg:mt-56 mb-32 max-w-7xl mx-auto px-5 2xl:px-0">
      <h1
        class="text-primary/75 font-bold text-2xl lg:text-4xl text-center mb-8 sm:mb-16 uppercase">
        Ihr Auftrag an uns
      </h1>
      <Alertbox />
      <div
        v-if="isWartung"
        class="p-5 text-primary border border-primary bg-primary/10 rounded-md my-5 max-w-3xl mx-auto">
        {{ messageWartung }}
      </div>
      <div class="unversandt">
        <AenderungsauftraegeSelfService @removed="chckUnbearbeitet" />
      </div>
      <AuftraegeSelfService
        v-bind:nurOffene="false"
        v-model:unversandt="this.unversandt"
        v-model:inBearbeitung="this.inBearbeitung" />
    </div>
  </div>
  <FooterMenu />
</template>
