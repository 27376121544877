<template>
  <div class="max-w-screen-sm mx-auto px-5 lg:px-0">
    <h1 class="text-xl">Mein altes Passwort</h1>
    <p class="text-sm mb-2">Geben Sie hier Ihr altes Passwort ein.</p>
    <div class="relative">
      <inputPassword @passwordChecked="passwordCheckOld" />
      <span
        class="absolute -bottom-6 left-0 text-sm min-w-max"
        :class="pOldMeldungClass"
      >
        {{ pOldMeldung }}
      </span>
    </div>
    <!--
    <div class="alertbox" :class="{ group: store.message.text }">
      <div
        class="fixed translate-y-[999px] group-[.alertbox]:translate-y-0 bottom-8 transition-all duration-1000 left-1/2 -translate-x-1/2 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white z-[70]">
        <div class="mt-3 text-center">
          <div v-if="store.message.status === 'ok' || store.message.status === ''"
            class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
            <svg class="h-6 w-6 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
            </svg>
          </div>
          <div v-else class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-red-600" viewBox="0 0 192 512">
              <path
                d="M176 432c0 44.1-35.9 80-80 80s-80-35.9-80-80 35.9-80 80-80 80 35.9 80 80zM25.3 25.2l13.6 272C39.5 310 50 320 62.8 320h66.3c12.8 0 23.3-10 24-22.8l13.6-272C167.4 11.5 156.5 0 142.8 0H49.2C35.5 0 24.6 11.5 25.3 25.2z" />
            </svg>
          </div>

          <div class="mt-2 px-7 py-3">
            <p class="text-sm font-bold text-tertiary whitespace-pre-line">
              {{ store.message.text }}
            </p>
          </div>
        </div>
      </div>
    </div>
  --></div>

  <div class="max-w-screen-sm mx-auto px-5 lg:px-0">
    <div class="relative mb-16">
      <div class="label mb-2">
        <h1 class="text-xl">Mein neues Passwort</h1>
        <p class="text-sm">Geben Sie hier ein neues Passwort ein.</p>
        <p
          v-if="forceNew && !isPasswordStrong"
          class="text-sm mb-2 text-red-500"
        >
          Sie müssen hier ein neues, kompliziertes Passwort angeben.
        </p>
      </div>
      <div class="passwort-formular relative w-full">
        <input
          maxlength="255"
          autocomplete="new-password"
          class="w-full p-2 border border-secondary/50 rounded-md focus:border-tertiary/50 peer outline-0"
          :type="type"
          v-model="newPassword1"
        />
        <button
          data-tooltip-target="tooltipContentNeu"
          data-tooltip-placement="bottom"
          data-tooltip-style="light"
          id="tooltipButtonNeu"
          type="button"
          class="mx-auto absolute top-2 right-4"
          @click="
            showPassword();
            toggle = !toggle;
          "
          :disabled="store.masterData.isReadOnly"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            v-if="toggle"
            class="h-6 w-6 stroke-secondary hover:stroke-primary transition-all"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            v-if="!toggle"
            class="h-6 w-6 stroke-secondary hover:stroke-primary transition-all"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
            />
          </svg>
        </button>
        <div
          id="tooltipContentNeu"
          role="tooltip"
          class="absolute z-30 invisible inline-block px-3 py-2 text-sm font-medium text-gray-900 bg-gray-100 border-primary rounded-lg shadow-sm opacity-0 tooltip text-center w-40"
        >
          {{ btnText }}
          <div id="arrow" data-popper-arrow="true"></div>
        </div>
      </div>

      <PasswortTestBar
        :value="newPassword1"
        :extok="levDist"
        class="mt-2 mb-6"
        @status="passStrong"
        @changed="newPassChanged"
      />
    </div>
    <div class="relative">
      <div class="label mb-2">
        <h1 class="text-xl">Neues Passwort wiederholen</h1>
        <p class="text-sm">Geben Sie hier Ihr neues Passwort erneut ein.</p>
      </div>
      <PasswordControl @passwordChecked="passwordCheckMatch" />
      <p
        class="absolute left-0 text-sm -bottom-6"
        v-if="pControlMeldung"
        :class="pControlMeldungClass"
      >
        {{ pControlMeldung }}
      </p>
    </div>
  </div>
  <!--
  <button class="bg-primary text-white py-4 px-32 rounded-md text-xl mx-auto block hover:bg-tertiary transition mt-8"
    :class="[
      {
        'bg-secondary pointer-events-none':
          ((!isPasswordStrong || !isPasswordMatch || !isPasswordOldOK) && password.length > 0) || !emailOk
      },
    ]" @click="sendToDB($event)">
    Ändern
  </button>
  -->
</template>
<script>
import { ref } from "vue";
import PasswortTestBar from "../components/PasswortTestBar.vue";
import PasswordControl from "../components/PasswordControl.vue";
import inputPassword from "../components/inputPassword.vue";
//import { UserPassword as controlPassword} from "../components/UserPassword.vue";
import { store } from "./store.vue";
import { Tooltip } from "flowbite";
import { onMounted } from "vue";
import { getLevenshteinDistance } from "../utility";
/** Komponente zur Passwort-Änderung mit den entsprechenden Input-Feldern und einer Sicherheitsprüfung für das neue Passwort
 * verantwortlich für die Erzeugung eines guten neues Passwortes:
 * - das das alte Passwort muss eingegeben und überprüft werden
 * - ein neues, gutes Passwort muss erstellt werden
 * - das neue Passwort muss erneut, richtig eingegeben werden
 * die Kontrolle, ob das Passwort gut ist, wird an PasswortTestBar delegiert
 * @displayName Passwort Änderung
 */
export default {
  components: {
    PasswortTestBar,
    inputPassword,
    PasswordControl,
  },
  props: {
    /**
     * Kennung, ob Passwort Pflichtfeld ist
     * @values true, false
     */
    forceNew: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Die neue Mail-Adresse
     */
    newEmail: {
      type: String,
      required: false,
      default: "",
    },
  },
  setup() {
    onMounted(() => {
      const $targetElNeu = document.getElementById("tooltipContentNeu");
      const $triggerElNeu = document.getElementById("tooltipButtonNeu");

      const options = {
        placement: "bottom",
        triggerType: "hover",
      };
      const tooltip = new Tooltip($targetElNeu, $triggerElNeu, options);
      tooltip.hide();
    });
    /*    const password = ref("");
    const isPasswordStrong = ref(false);sword
    const isPasswordMatch = ref(false);
    const isPasswordOldOK = ref(false);

    return { password, isPasswordStrong, isPasswordMatch, isPasswordOldOK };*/
  },
  computed: {
    levDist() {
      return (
        this.isPasswordNew &&
        getLevenshteinDistance(this.newPassword1, this.oldPasswd) > 4 &&
        getLevenshteinDistance(this.newPassword1, store.masterData.data.Stammdaten.EMAIL) >
          4 &&
        this.newPassword1.trim() == this.newPassword1
      );
    },
  },
  data() {
    return {
      type: "password",
      btnText: "Passwort anzeigen",
      toggle: "true",
      store,
      meldung: "",
      pOldMeldung: "",
      pControlMeldung: "",
      newPassword1: "",
      newPassword2: "",
      oldPasswd: "",
      isPasswordStrong: false,
      isPasswordMatch: false,
      isPasswordOldOK: false,
      pOldMeldungClass: "",
      isPasswordNew: true,
    };
  },
  emits: ["status", "changed", "newpasswd", "passwdOK"],

  mounted() {
    /**
     * Das alte Passwort passt, das neue Passwort hat genug Score und wurde zum 2. Mal korrekt eingegeben ODER !forceNew und alle Felder sind leer
     * @property {boolean} forceNew (keinerlei Eingabe)
     */
    this.$emit("passwdOK", !this.forceNew);
  },
  //     pass:this.Password,
  watch: {
    newPassword1: {
      handler(newData) {
        //auch wenn Password1 sich ändert, muss Match gecheckt werden
        this.passwordCheckMatch();
        if (
          this.isPasswordStrong &&
          this.isPasswordMatch &&
          this.isPasswordOldOK
        ) {
          /**
           * Das neue Passwort bei jeder Änderung
           * @property {text} newPassword1 - neues Passwort
           */
          this.$emit("newpasswd", this.newPassword1);
          /**
           * Das alte Passwort passt, das neue Passwort hat genug Score und wurde zum 2. Mal korrekt eingegeben ODER !forceNew und alle Felder sind leer
           * @property {boolean} true (keinerlei Eingabe)
           */
          this.$emit("passwdOK", true);
        } else {
          let leerOk =
            !this.forceNew &&
            this.oldPasswd == "" &&
            this.newPassword1 == "" &&
            this.newPassword2 == "";
          /**
           * Das alte Passwort passt, das neue Passwort hat genug Score und wurde zum 2. Mal korrekt eingegeben ODER !forceNew und alle Felder sind leer
           * @property {boolean} leerOk (keinerlei Eingabe)
           */
          this.$emit("passwdOK", leerOk);
        }
      },
    },
    newPassword2: {
      handler(newData) {
        //auch wenn Password2 sich ändert, muss Match gecheckt werden
        //JA. DAS IST DOPPELT, muss aber so
        this.passwordCheckMatch();
        if (
          this.isPasswordStrong &&
          this.isPasswordMatch &&
          this.isPasswordOldOK
        ) {
          /**
           * Das neue Passwort bei jeder Änderung
           * @property {text} newPassword1 - neues Passwort
           */
          this.$emit("newpasswd", this.newPassword1);
          /**
           * Das alte Passwort passt, das neue Passwort hat genug Score und wurde zum 2. Mal korrekt eingegeben ODER !forceNew und alle Felder sind leer
           * @property {boolean} true (keinerlei Eingabe)
           */
          this.$emit("passwdOK", true);
        } else {
          let leerOk =
            !this.forceNew &&
            this.oldPasswd == "" &&
            this.newPassword1 == "" &&
            this.newPassword2 == "";
          /**
           * Das alte Passwort passt, das neue Passwort hat genug Score und wurde zum 2. Mal korrekt eingegeben ODER !forceNew und alle Felder sind leer
           * @property {boolean} leerOk (keinerlei Eingabe)
           */
          this.$emit("passwdOK", leerOk);
        }
      },
    },
    oldPasswd: {
      handler(newData) {
        //auch wenn oldPasswd sich ändert, muss Match gecheckt werden
        //JA. DAS IST DOPPELT, muss aber so
        this.passwordCheckMatch();
        if (
          this.isPasswordStrong &&
          this.isPasswordMatch &&
          this.isPasswordOldOK
        ) {
          /**
           * Das neue Passwort bei jeder Änderung
           * @property {text} newPassword1 - neues Passwort
           */
          this.$emit("newpasswd", this.newPassword1);
          /**
           * Das alte Passwort passt, das neue Passwort hat genug Score und wurde zum 2. Mal korrekt eingegeben ODER !forceNew und alle Felder sind leer
           * @property {boolean} true (keinerlei Eingabe)
           */
          this.$emit("passwdOK", true);
        } else {
          let leerOk =
            !this.forceNew &&
            this.oldPasswd == "" &&
            this.newPassword1 == "" &&
            this.newPassword2 == "";
          /**
           * Das alte Passwort passt, das neue Passwort hat genug Score und wurde zum 2. Mal korrekt eingegeben ODER !forceNew und alle Felder sind leer
           * @property {boolean} leerOk (keinerlei Eingabe)
           */
          this.$emit("passwdOK", leerOk);
        }
      },
    },

    /*
    "store.masterData.data.Stammdaten.IS_USER_INIT": {
      handler(newData) {
        if (newData + '' === '0' && store.masterData.data.Stammdaten.IS_PWD_INIT === "0")
          //this.$router.push("/startseite");
      },
    },
    "store.masterData.data.Stammdaten.IS_PWD_INIT": {
      handler(newData) {
        if (newData + '' === '0' && store.masterData.data.Stammdaten.IS_USER_INIT === "0")
          //this.$router.push("/startseite");
      },
    },
    */
  },
  methods: {
    newPassChanged(value) {
      this.isPasswordNew = true;
      this.$servertalk.doLog("##### " + value);
      this.isPasswordStrong = value >= 3; // && (getLevenshteinDistance(value, store.masterData.data.Stammdaten.EMAIL) > 5);
      this.$servertalk.doLog(
        "Lev Pass" + getLevenshteinDistance(this.newPassword1, this.oldPasswd)
      );
      this.$servertalk.doLog(
        "Lev EMail" +
          getLevenshteinDistance(this.newPassword1, store.masterData.data.Stammdaten.EMAIL)
      );

      if (this.isPasswordStrong && this.isPasswordOldOK) {
        this.$servertalk.checkPass(
          this.newPassword1,
          this.newEmail,
          (status) => {
            this.$servertalk.doLog(status);
            if (status != 200) {
              this.isPasswordStrong = false;
              this.isPasswordNew = false;
              store.message = {
                text: "Dieses Passwort darf nicht erneut verwendet werden.",
                status: "error",
              };
            } else {
              store.message = { text: "", status: "" };
              this.isPasswordNew = true;
            }
          }
        );
      }
      /**
       * Score des newPassword1 bei jeder Änderung des neuen (1.) Passwortes
       * @property {number} value Kennung der Passwort-Stärke
       */
      this.$emit("changed", value);
      this.$servertalk.doLog("isPasswordStrong1 " + this.isPasswordStrong);
    },
    passStrong(value) {
      this.$servertalk.doLog("pStrong " + value);
      /**
       score des PasswortTestBar, bei Emit des Testbars
       * @property {number} value Kennung der Passwort-Stärke
       */
      this.$emit("status", value);
      this.$servertalk.doLog(value);
      this.isPasswordStrong = value;
      this.$servertalk.doLog("isPasswordStrong2 " + this.isPasswordStrong);

      //das funktioniert hier nicht!
      //Methode wird nur aufgerufen, wenn Strong sich ändert.
    },
    passwordCheckOld(passwd) {
      passwd = passwd.trim();
      this.oldPasswd = passwd;
      if (store.masterData.data.Stammdaten && store.masterData.data.Stammdaten.EMAIL) {
        // Both store.masterData.data.Stammdaten and store.masterData.data.Stammdaten.EMAIL exist
        // You can safely access store.masterData.data.Stammdaten.EMAIL here
        this.isPasswordOldOK =
          this.$servertalk.calcIdent(store.masterData.data.Stammdaten.EMAIL, passwd) ==
          store.identhash;
      } else {
        // Handle the case where store.masterData.data.Stammdaten or store.masterData.data.Stammdaten.EMAIL does not exist
        // You may want to display an error message or take appropriate action
        this.isPasswordOldOK = false; // Or set to an appropriate value
        // Optionally, you can provide feedback or handle the absence of data here
      }

      this.$servertalk.doLog(passwd);
      this.$servertalk.doLog("isPasswordOldOK " + this.isPasswordOldOK);

      // Clear any previous message
      this.pOldMeldung = "";
      this.pOldMeldungClass = "";

      if (passwd === "") {
        return;
      }
      if (this.isPasswordOldOK) {
        this.pOldMeldung = "Das alte Passwort ist korrekt.";
        this.pOldMeldungClass = "text-green-600"; // Set the class for correct newPassword1
      } else {
        this.pOldMeldung = "Das alte Passwort ist noch nicht richtig.";
        this.pOldMeldungClass = "text-red-600"; // Set the class for incorrect newPassword1
      }
    },
    passwordCheckMatch(passwd = false) {
      if (passwd) this.newPassword2 = passwd;
      this.isPasswordMatch = this.newPassword2 == this.newPassword1;
      if (this.newPassword1 != "") {
        if (this.isPasswordMatch) {
          this.pControlMeldung = "Das wiederholte Passwort stimmt.";
          this.pControlMeldungClass = "text-green-600";
        } else {
          this.pControlMeldung = "Das wiederholte Passwort stimmt nicht.";
          this.pControlMeldungClass = "text-red-600";
        }
      } else {
        this.pControlMeldung = "";
        this.pControlMeldungClass = "bg-transparent";
      }
    },
    /*sendToDB($event) {
      store.message =
      {
        text: "Bitte warten Sie, bis Ihre Änderungen gespeichert wurden.",
        status: "ok",
      };
      this.$servertalk.doLog("###: " + this.newPassword1);
      if (this.newPassword1.length > 0) {
        this.store.newpasswd = this.newPassword1;
        this.store.masterData.data.passwdModified = true;
        this.store.masterData.data.Stammdaten.IS_PWD_INIT = 0;
      }
      this.$emit("submitted");
      //senden passiert Automatisch
      //this.$servertalk.sendDataToDB($event, this.setMessage);
    },*/
    showPassword() {
      if (this.type === "password") {
        this.type = "text";
        this.btnText = "Passwort verstecken";
      } else {
        this.type = "password";
        this.btnText = "Passwort anzeigen";
      }
    },
    setMessage(status) {
      if (status == 200) {
        store.message = { text: "Das Passwort wurde geändert.", status: "ok" };
        this.newPassword1 = "";
      } else {
        store.message = {
          text: "Bei der Passwort-Änderung trat ein Fehler auf.",
          status: "error",
        };
        if (status == 406) {
          store.message.text +=
            " Ihr neues Passwort entspricht einem alten Passwort von Ihnen.";
        }
        this.newPassword1 = this.alias;
      }
    },
  },
};
</script>
