<script setup>
import HauptMenue from "../components/HauptMenue.vue";
import FooterMenu from "../components/FooterMenu.vue";
import DokumenteHochladen from "../components/DokumenteHochladenService.vue";
import ButtonResetServiceData from "../components/ButtonResetServiceData.vue";
import DatenschutzHinweis from "../components/DatenschutzHinweis.vue";
import { store } from "../components/store.vue";
import CustomSelect from "../components/CustomSelect.vue";
import Alertbox from "../components/Alertbox.vue";
import { getPhoneNumberPattern } from "../utility";
/** View-Komponente zur Anzeige der Kontaktseite mit dem Formular
 * @displayName Seite Kontakt
 */
</script>
<script>
export default {
  name: "KontaktPensus",
  components: {
    // myRouter: router,
    HauptMenue,
    FooterMenu,
    CustomSelect,
    ButtonResetServiceData,
    DatenschutzHinweis,
    DokumenteHochladen,
    getPhoneNumberPattern,
    Alertbox,
  },
  data() {
    return {
      store,
      sAnliegenOptions: [{ text: "Bitte auswählen", value: -1 }].concat(
        this.$servertalk.getSelectOpts("WEB_KONT_GRUND")
      ),
      sAnliegen: {
        text: this.$servertalk.getName(
          "WEB_KONT_GRUND",
          store.masterData.draft.sService.Entwuerfe.KontaktPensus.ANLIEGEN
        ),
        key: store.masterData.draft.sService.Entwuerfe.KontaktPensus.ANLIEGEN,
      },
      saved: false,
      fullName: this.VorundNachname(),
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    window._paq.push(["setDocumentTitle", this.name]);
    window._paq.push(["trackPageView"]);
  },

  unmounted() {
    if (this.saved) {
      // Nach Beauftragung Service in Draft komplett löschen
      delete store.masterData.olddraft.sService.Entwuerfe.KontaktPensus;
      delete store.masterData.draft.sService.Entwuerfe.KontaktPensus;
      this.saved = false;
    }
  },
  beforeCreate: function () {
    //Form initialisieren
    let action = this.$servertalk.initSelfService(
      "KontaktPensus",
      '{"NAME":"","VORNAME":"","EMAIL":"","PTELEFON":"","ANLIEGEN":"-1","NACHRICHT":"","uuid":"","uploadsDokumente":[],"version":"1.0"}',
      "1.0"
    );

    if (action % 2 == 1) {
      // olddraft wurde initialisiert
      store.masterData.olddraft.sService.Entwuerfe.KontaktPensus.NAME =
        store.masterData.data.Stammdaten.NAME;
      store.masterData.olddraft.sService.Entwuerfe.KontaktPensus.VORNAME =
        store.masterData.data.Stammdaten.VORNAME;
      store.masterData.olddraft.sService.Entwuerfe.KontaktPensus.EMAIL = store.masterData.data.Stammdaten.EMAIL;
      store.masterData.olddraft.sService.Entwuerfe.KontaktPensus.PTELEFON = store.satzart == 'person' ? store.masterData.data.Privatadresse.PTELEFON : store.masterData.data.Stammdaten.TELEFON;
      store.masterData.olddraft.sService.Entwuerfe.KontaktPensus.ANLIEGEN = "-1"; // Dropdown
      store.masterData.olddraft.sService.Entwuerfe.KontaktPensus.uuid =
        this.$servertalk.getUUID();
    }

    if (action >= 2) {
      // draft wurde initialisiert
      this.$servertalk.copyToDraftSelfService("KontaktPensus");
    }
    this.$servertalk.doLog("zeige Init");
    this.$servertalk.doLog(store.masterData.olddraft.sService.Entwuerfe.KontaktPensus);
    this.$servertalk.doLog(store.masterData.draft.sService.Entwuerfe.KontaktPensus);
  },
  props: {
    value: String,
  },
  methods: {
    infoDocStatus(val) {
      this.$servertalk.doLog("infoDocStatus: ");
      this.$servertalk.doLog(val);
    },
    infoDocOk(val) {
      this.$servertalk.doLog("infoDocOk: " + val.uploadStatus);
    },
    docDocStatus(val) {
      this.$servertalk.doLog("docDocStatus: " + val.uploadStatus);
    },
    docDocOk(val) {
      this.$servertalk.doLog("docDocOk: " + val.uploadStatus);
    },
    changeFiles(val, i) {
      this.$servertalk.doLog("changeFiles");
      this.$servertalk.doLog(val);
      store.masterData.draft.sService.Entwuerfe.KontaktPensus.uploadsDokumente = val;
    },
    validPTELEFON() {
      const pattern = getPhoneNumberPattern();
      return (
        store.masterData.draft.sService.Entwuerfe.KontaktPensus.PTELEFON.match(pattern) ||
        store.masterData.draft.sService.Entwuerfe.KontaktPensus.PTELEFON === ""
      );
    },
    setAnliegen(val) {
      if (
        val != null &&
        typeof val === "object" &&
        typeof val.value !== "undefined"
      ) {
        this.$servertalk.doLog(val);
        store.masterData.draft.sService.Entwuerfe.KontaktPensus.ANLIEGEN = val.value + "";
      }
    },
    sendDataFinished() {
      let messageCode = this.$servertalk.sendDataSelfService("KontaktPensus");
      //this.$servertalk.doLog(store.uploadFiles.files);
      store.message =
        messageCode == 0
          ? {
            text: "Ihre Nachricht an Pensus wurde erfolgreich beauftragt.",
            status: "ok",
          }
          : {
            text:
              "Beim Speichern Ihrer Nachricht an Pensus trat ein Fehler auf (Error-Code " +
              messageCode +
              ").",
            status: "error",
          };
      //vielleicht den Store wieder frei geben
      //könnte ein Problem sein, denn die Files werden async hochgeladen
      //store.uploadFiles.files[store.masterData.draft.sService.Entwuerfe.KontaktPensus.uuid].splice(0, store.uploadFiles.files[store.masterData.draft.sService.Entwuerfe.KontaktPensus.uuid].length);
      //store.uploadFiles.files = [];

      //this.$servertalk.sendDataToDB(this.$event, this.setStatus);
      if (messageCode == 0) this.saved = true;
      this.$matomo?.trackEvent("SendService", "KontaktPensus");
      this.$router.push("/startseite"); // Nach dem Speichern der Nachricht wird hier zur Startseite verzweigt
    },

    resetData() {
      let messageCode =
        this.$servertalk.copyToDraftSelfService("KontaktPensus");
      //this.$refs.SelectAnliegen.reset(); // Zurücksetzen Select
      store.message =
        messageCode == 0
          ? {
            text: "Ihre Daten wurden zurückgesetzt.",
            status: "ok",
          }
          : {
            text:
              "Beim Zurücksetzen der Daten trat ein Fehler auf (Error-Code " +
              messageCode +
              ").",
            status: "error",
          };
    },
    VorundNachname() {
      // Hier wird der kombinierte Name zurückgegeben
      const titel = store.masterData.data.Stammdaten.TITEL || "";
      const vorname = store.masterData.data.Stammdaten.VORNAME || "";
      const namezus = store.masterData.data.Stammdaten.NAMEZUS || "";
      const namevs = store.masterData.data.Stammdaten.NAMEVS || "";
      const nachname = store.masterData.data.Stammdaten.NAME || "";
      const leerzeichenVorName = titel ? " " : "";
      const leerzeichenNameZus = namezus ? " " : "";

      return (
        titel +
        leerzeichenVorName +
        vorname +
        leerzeichenNameZus +
        " " +
        namevs +
        " " +
        nachname
      );
    },
  },
  computed: {
    setStatusClass() {
      // Erhalte den Wert "KontaktPensus" von $servertalk
      const aenderungKontaktPensusValue = this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.KontaktPensus"
      );
      return aenderungKontaktPensusValue;
    },
    setStatusClassNAME() {
      // Erhalte den Wert "NAME" von $servertalk
      return this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.KontaktPensus.NAME"
      );
    },
    setStatusClassEMAIL() {
      // Erhalte den Wert "EMAIL" von $servertalk
      return this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.KontaktPensus.EMAIL"
      );
    },
    setStatusClassPTELEFON() {
      // Erhalte den Wert "PTELEFON" von $servertalk
      return this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.KontaktPensus.PTELEFON"
      );
    },
    setStatusClassANLIEGEN() {
      // Erhalte den Wert "ANLIEGEN" von $servertalk
      return this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.KontaktPensus.ANLIEGEN"
      );
    },
    setStatusClassNACHRICHT() {
      // Erhalte den Wert "NACHRICHT" von $servertalk
      return this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.KontaktPensus.NACHRICHT"
      );
    },
  },
};
</script>
<template>
  <div class="main-wrapper flex-grow" :class="store.accessRestriction">
    <HauptMenue />

    <div class="hero-image-wrapper mb-16">
      <img
        class="max-h-[200px] md:max-h-[400px] lg:max-h-[600px] object-top overflow-hidden object-cover w-full mt-[100px] lg:mt-0"
        src="/assets/img/kontakt-header.webp" />
      <div class="hero-description-block max-w-7xl mx-auto relative">
        <div
          class="hero-inside bg-primary px-5 py-5 lg:py-10 lg:p-12 relative lg:absolute text-white max-w-full lg:max-w-lg lg:bottom-12">
          <h1 class="text-xl lg:text-4xl text-left font-bold text-white tracking-wider mb-2">
            So erreichen Sie uns
            <br />
            <span class="font-normal text-lg lg:text-2xl"></span>
          </h1>
          <h2>Stellen Sie uns Ihre Fragen und wir helfen Ihnen weiter.</h2>
        </div>
      </div>
    </div>
    <div class="mb-8 max-w-5xl mx-auto px-5 lg:px-0 text-tertiary">
      <h3 class="text-lg lg:text-2xl mb-8 lg:mb-16 leading-normal font-light">
        Haben Sie Fragen, Anliegen oder benötigen Sie weitere Informationen zu
        unseren Dienstleistungen? Bitte zögern Sie nicht, uns über dieses
        Kontaktformular zu kontaktieren. Wir stehen Ihnen gerne zur Verfügung,
        um Ihre betrieblichen Vorsorgepläne bestmöglich zu unterstützen und zu
        optimieren. Wir freuen uns auf Ihre Nachricht.
      </h3>
      <!--modal content-->
      <Alertbox />
      <div class="status-label mb-8" v-if="setStatusClass === 'modified'">
        <span class="bearbeitung bg-yellow-400 text-tertiary inline-block py-1 px-2 text-sm uppercase mb-2">
          Entwurf
        </span>
        <span class="infotext text-tertiary text-sm block">
          Dies ist ein Entwurf. Bitte senden Sie dieses Kontaktformular ab,
          indem Sie auf
          <strong>Anfrage absenden</strong>
          klicken.
        </span>
      </div>

      <form :class="setStatusClass">
        <div class="grid lg:grid-cols-2 gap-2 lg:gap-x-16 lg:gap-y-0">
          <div class="relative">
            <label for="nachname" class="text-sm mb-2 block">
              Name
              <sup>*</sup>
            </label>
            <input maxlength="255" type="text" id="nachname" required="" disabled inputmode="text" v-model="fullName"
              class="w-full p-3 border border-secondary/50 rounded-md mb-8 focus:outline-0 focus:border-tertiary/50 peer invalid:text-red-500 focus:invalid:border-red-500"
              :class="{
                'border-yellow-400 !focus:border-yellow-400 bg-yellow-50':
                  setStatusClassNAME,
                'focus:border-tertiary/50': !setStatusClassNAME,
              }" />
          </div>
          <div class="relative">
            <label for="email" class="text-sm mb-2 block">
              E-Mail Adresse
              <sup>*</sup>
            </label>
            <input maxlength="255" type="email" id="email" required="" disabled inputmode="email"
              class="w-full p-3 border border-secondary/50 rounded-md mb-8 focus:outline-0 focus:border-tertiary/50 peer invalid:text-red-500 focus:invalid:border-red-500"
              :class="setStatusClassEMAIL" v-model="store.masterData.data.Stammdaten.EMAIL" />
          </div>
          <div class="relative">
            <label for="telefonnummer" class="text-sm mb-2 block">
              Telefonnummer
            </label>
            <input maxlength="255" type="tel" id="telefonnummer" inputmode="numeric"
              class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500 peer"
              :class="setStatusClassPTELEFON" v-model="store.masterData.draft.sService.Entwuerfe.KontaktPensus.PTELEFON"
              :pattern="getPhoneNumberPattern().source" />
            <span class="invisible peer-invalid:visible text-red-500 text-sm absolute bottom-2 left-0">
              Bitte tragen Sie hier Ihre Telefonnummer ein.
            </span>
          </div>
          <div>
            <label for="anliegen" class="text-sm mb-2 block">
              Ihr Anliegen
              <sup>*</sup>
            </label>
            <CustomSelect @input="setAnliegen" :class="setStatusClassANLIEGEN" required :options="sAnliegenOptions"
              :default="store.masterData.draft.sService.Entwuerfe.KontaktPensus.ANLIEGEN" :oldDefault="store.masterData.olddraft.sService.Entwuerfe.KontaktPensus.ANLIEGEN
                " :tabindex="2" />
          </div>
        </div>
        <div>
          <label for="nachricht" class="text-sm mb-2 block">
            Ihre Nachricht an uns
            <sup>*</sup>
          </label>
          <textarea maxlength="5000"
            class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 focus:border-tertiary/50 peer invalid:text-red-500 focus:invalid:border-red-500"
            :class="setStatusClassNACHRICHT" rows="5" required=""
            v-model="store.masterData.draft.sService.Entwuerfe.KontaktPensus.NACHRICHT"></textarea>
        </div>
        <div>
          <label class="text-sm mb-2 block">zusätzliche Dokumente</label>
          <DokumenteHochladen :formId="store.masterData.draft.sService.Entwuerfe.KontaktPensus.uuid" :defaultData="store.masterData.draft.sService.Entwuerfe.KontaktPensus.uploadsDokumente
            " maxfiles="2" minfiles="0" infoText="aus dem Kontaktformular" docArt="Docs" @status="docDocStatus"
            @isOk="docDocOk" @newfiles="changeFiles" @change="changeFiles" />
        </div>

        <div class="button-wrapper flex justify-center gap-4 [&>button]:mx-0 mb-16 mt-6">
          <button :disabled="store.masterData.isReadOnly || setStatusClass !== 'modified' || validPTELEFON() == false
            " @finished="sendDataFinished" type="submit" @click="sendDataFinished"
            class="bg-primary text-white py-4 w-64 rounded-md text-base lg:text-xl mx-auto block hover:bg-tertiary transition disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-primary">
            Anfrage absenden
          </button>
          <ButtonResetServiceData :disabled="setStatusClass !== 'modified'" @reset="resetData" />
        </div>
      </form>
      <DatenschutzHinweis />
    </div>
    <FooterMenu />
  </div>
</template>
