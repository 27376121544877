<script setup>
import HauptMenue from "../../components/HauptMenue.vue";
import FooterMenu from "../../components/FooterMenu.vue";
import DatenschutzHinweis from "../../components/DatenschutzHinweis.vue";
import ButtonSendDataSelfService from "../../components/ButtonSendDataSelfService.vue";
import ButtonResetServiceData from "../../components/ButtonResetServiceData.vue";
import CustomSelect from "../../components/CustomSelect.vue";
import Alertbox from "../../components/Alertbox.vue";
import ButtonBack from "../../components/ButtonBack.vue";
import { store } from "../../components/store.vue";
import { bankDataByIBAN } from "bankdata-germany";
import { isValidIBAN } from "ibantools-germany";
//import { friendlyFormatIBAN, electronicFormatIBAN } from "ibantools";
import { getNamePatternEmpty } from "../../utility";
/** View-Komponente zum Erfassen von Änderungsaufträgen zur FirmenBankdaten
 * @displayName Selfservice Änderung FirmenBankdaten
 */
</script>
<script>
export default {
  name: "AenderungFirmenBankdaten",

  components: {
    // myRouter: router,
    HauptMenue,
    FooterMenu,
    DatenschutzHinweis,
    ButtonSendDataSelfService,
    ButtonResetServiceData,
    CustomSelect,
    Alertbox,
    ButtonBack,
    bankDataByIBAN,
    isValidIBAN,
  },
  data() {
    return {
      store,
      validIBAN: false, // Hier wird der Validitätsstatus der IBAN gespeichert
      bankData: {
        bankName: "",
        bic: "",
      }, // Hier werden die Bankdaten gespeichert
      ibanPrefix: "", // Hier werden die ersten zwei Stellen der IBAN für das Feld Sitz des Geldinstituts gespeichert
      //vorgefuellteIBAN: "", // Hier wird die IBAN aus dem Store gespeichert
      saved: false,
      bankDataDisabled: false,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    window._paq.push(["setDocumentTitle", this.name]);
    window._paq.push(["trackPageView"]);
    /*
    const validiban = isValidIBAN(
      store.subData.draft.sService.Entwuerfe.AenderungFirmenBankdaten.GEB_IBAN.replaceAll(
        " ",
        ""
      )
    );
    const iban = bankDataByIBAN(
      store.subData.draft.sService.Entwuerfe.AenderungFirmenBankdaten.GEB_IBAN
    );
    const friendlyiban = friendlyFormatIBAN(
      store.subData.draft.sService.Entwuerfe.AenderungFirmenBankdaten.GEB_IBAN
    );
    const electronicformat = electronicFormatIBAN(
      store.subData.draft.sService.Entwuerfe.AenderungFirmenBankdaten.GEB_IBAN
    );
    this.$servertalk.doLog(iban);
    this.$servertalk.doLog(validiban);
    this.$servertalk.doLog(friendlyiban);
    this.$servertalk.doLog(electronicformat);
    */
    this.validiereIBAN(); // Führt die Validierung und Aktualisierung der Bankdaten aus
  },
  unmounted() {
    if (this.saved) {
      // Nach Beauftragung Service in Draft komplett löschen
      delete store.subData.olddraft.sService.Entwuerfe.AenderungFirmenBankdaten;
      delete store.subData.draft.sService.Entwuerfe.AenderungFirmenBankdaten;
      this.saved = false;
    }
  },
  beforeCreate: function () {
    // this.$servertalk.doLog("Create");
    this.$servertalk.doLog("Inhalt store:");
    this.$servertalk.doLog(store);
    let action = this.$servertalk.initSelfService(
      "AenderungFirmenBankdaten",
      '{"GEB_IBAN":"","GEB_BIC":"","GEB_BNAME":"","GEB_LAND":"","GEB_ABWNAME":"","GEB_EINZUG":"","uuid":"","version":"1.1"}',
      "1.1",
      store.subData
    );
    if (action % 2 == 1) {
      // olddraft wurde initialisiert
      let olddraft =
        store.subData.olddraft.sService.Entwuerfe.AenderungFirmenBankdaten;
      olddraft.GEB_IBAN = store.subData.data.Geschaeftsbank.GEB_IBAN;
      olddraft.GEB_BIC = store.subData.data.Geschaeftsbank.GEB_BIC;
      olddraft.GEB_BNAME = store.subData.data.Geschaeftsbank.GEB_BNAME;
      olddraft.GEB_LAND = store.subData.data.Geschaeftsbank.GEB_LAND;
      olddraft.GEB_ABWNAME = store.subData.data.Geschaeftsbank.GEB_ABWNAME;
      olddraft.GEB_EINZUG = store.subData.data.Geschaeftsbank.GEB_EINZUG;
      olddraft.uuid = this.$servertalk.getUUID();
    }
    if (action >= 2) {
      // draft wurde initialisiert
      this.$servertalk.copyToDraftSelfService(
        "AenderungFirmenBankdaten",
        store.subData
      );
    }
  },
  methods: {
    validiereIBAN() {
      store.subData.draft.sService.Entwuerfe.AenderungFirmenBankdaten.GEB_IBAN =
        store.subData.draft.sService.Entwuerfe.AenderungFirmenBankdaten.GEB_IBAN.toUpperCase();
      // Führt die Validierung und Abfrage der Bankdaten durch
      this.validIBAN = isValidIBAN(
        store.subData.draft.sService.Entwuerfe.AenderungFirmenBankdaten.GEB_IBAN.replaceAll(
          " ",
          ""
        )
      );
      const fetchedBankData = bankDataByIBAN(
        store.subData.draft.sService.Entwuerfe.AenderungFirmenBankdaten.GEB_IBAN
      );
      // Überprüfe, ob die IBAN mit "DE" beginnt
      if (
        store.subData.draft.sService.Entwuerfe.AenderungFirmenBankdaten.GEB_IBAN.startsWith(
          "DE"
        )
      ) {
        // Wenn ja, aktualisiere die Bankdaten aus dem abgerufenen Objekt
        if (fetchedBankData) {
          store.subData.draft.sService.Entwuerfe.AenderungFirmenBankdaten.GEB_BNAME =
            fetchedBankData.bankName;
          store.subData.draft.sService.Entwuerfe.AenderungFirmenBankdaten.GEB_BIC =
            fetchedBankData.bic;
          //this.bankData.blz = fetchedBankData.blz;
          this.bankDataDisabled = true; // IBAN beginnt mit "DE", daher sind die Inputfelder gesperrt
        }
      } else {
        // Wenn nicht, lösche die Felder bankName und bic
        store.subData.draft.sService.Entwuerfe.AenderungFirmenBankdaten.GEB_BNAME =
          "";
        store.subData.draft.sService.Entwuerfe.AenderungFirmenBankdaten.GEB_BIC =
          "";
        this.bankDataDisabled = false; // IBAN beginnt nicht mit "DE", deshalb Inputfelder freischalten für Eingabe
      }
      if (
        store.subData.draft.sService.Entwuerfe.AenderungFirmenBankdaten.GEB_IBAN
          .length < 2
      ) {
        this.bankDataDisabled = true; // Keine IBAN erfasst: Banknamen und BIC gegen Eingaben sperren.
        store.subData.draft.sService.Entwuerfe.AenderungFirmenBankdaten.GEB_LAND =
          "";
      } else {
        // Extrahiere die ersten zwei Zeichen der IBAN und speicher sie in der Datenvariable ibanPrefix
        store.subData.draft.sService.Entwuerfe.AenderungFirmenBankdaten.GEB_LAND =
          store.subData.draft.sService.Entwuerfe.AenderungFirmenBankdaten.GEB_IBAN.slice(
            0,
            2
          );
      }
    },
    sendDataFinished() {
      let messageCode = this.$servertalk.sendDataSelfService(
        "AenderungFirmenBankdaten",
        store.subData
      );
      store.message =
        messageCode == 0
          ? {
              text: "Ihre Änderungen im Bereich\n Änderung FirmenbAenderungFirmenBankdaten \n wurden erfolgreich beauftragt.",
              status: "ok",
            }
          : {
              text:
                "Beim Speichern des Auftrags im Bereich\n Änderung FirmenbAenderungFirmenBankdaten \n trat ein Fehler auf (Error-Code " +
                messageCode +
                ").",
              status: "error",
            };
      if (messageCode == 0) this.saved = true;
      this.$matomo?.trackEvent("SendService", "AenderungFirmenBankdaten");
      this.$router.push("/auftrag-an-pensus"); // Nach dem Beauftragen weiterleiten zur Auftragsübersicht
    },
    resetData() {
      // Button zum Zurücksetzen der Änderungen gedrückt
      let messageCode = this.$servertalk.copyToDraftSelfService(
        "AenderungFirmenBankdaten",
        store.subData
      );
      this.validiereIBAN();
      store.message =
        messageCode == 0
          ? {
              text: "Ihre Änderungen im Bereich\n Änderung FirmenbAenderungFirmenBankdaten \n wurden zurückgesetzt.",
              status: "ok",
            }
          : {
              text:
                "Beim Zurücksetzen der Daten im Bereich\n Änderung FirmenbAenderungFirmenBankdaten \n trat ein Fehler auf (Error-Code " +
                messageCode +
                ").",
              status: "error",
            };
    },
  },
  computed: {
    validGEB_ABWNAME() {
      const pattern = getNamePatternEmpty();
      return pattern.test(store.subData.draft.sService.Entwuerfe.AenderungFirmenBankdaten.GEB_ABWNAME);
    },
    setSchwebendClass() {
      // Erhalte den Wert "Privatadresse" von $servertalk
      const aenderungPrivatadresseValue = this.$servertalk.gettAttr(
        "subData.data.Geschaeftsbank"
      );
      //const aenderungPrivatadresseValue = this.$servertalk.gettAttr("subData.draft.sService.Entwuerfe.AenderungKontaktdaten");

      return aenderungPrivatadresseValue;
    },
    setStatusClass() {
      // Erhalte den Wert "AenderungFirmenBankdaten" von $servertalk
      const aenderungFirmenbAenderungFirmenBankdatenValue =
        this.$servertalk.gettAttr(
          "subData.draft.sService.Entwuerfe.AenderungFirmenBankdaten"
        );
      return aenderungFirmenbAenderungFirmenBankdatenValue;
    },
    setStatusClassGEB_IBAN() {
      // Erhalte den Wert "GEB_IBAN" von $servertalk
      return this.$servertalk.gettAttr(
        "subData.draft.sService.Entwuerfe.AenderungFirmenBankdaten.GEB_IBAN"
      );
    },
    setStatusClassGEB_BIC() {
      // Erhalte den Wert "GEB_BIC" von $servertalk
      return this.$servertalk.gettAttr(
        "subData.draft.sService.Entwuerfe.AenderungFirmenBankdaten.GEB_BIC"
      );
    },
    setStatusClassGEB_BNAME() {
      // Erhalte den Wert "GEB_BNAME" von $servertalk
      return this.$servertalk.gettAttr(
        "subData.draft.sService.Entwuerfe.AenderungFirmenBankdaten.GEB_BNAME"
      );
    },
    setStatusClassGEB_ABWNAME() {
      // Erhalte den Wert "GEB_ABWNAME" von $servertalk
      return this.$servertalk.gettAttr(
        "subData.draft.sService.Entwuerfe.AenderungFirmenBankdaten.GEB_ABWNAME"
      );
    },
    setStatusClassGEB_LAND() {
      // Erhalte den Wert "GEB_ABWNAME" von $servertalk
      return this.$servertalk.gettAttr(
        "subData.draft.sService.Entwuerfe.AenderungFirmenBankdaten.GEB_LAND"
      );
    },
  },
};
</script>
<template>
  <div class="main-wrapper" :class="store.accessRestriction">
    <HauptMenue />
    <Transition>
      <div class="mt-36 md:mt-48 lg:mt-56 max-w-7xl mx-auto">
        <div class="max-w-5xl mx-auto mb-8 px-5 lg:px-0">
          <h1
            class="text-primary/75 font-bold text-xl sm:text-2xl md:text-3xl lg:text-4xl text-center mb-12 uppercase">
            Änderung der FirmenBankdaten für
            <br />
            <span>"{{ store.subData.data.Firmenstammdaten.FNAME }} {{ $servertalk.getName('GESFORM',store.subData.data.Firmenstammdaten?.FGESFORM) }}/{{
          store.subData.data.Firmenstammdaten.FKENNR
        }}/{{ store.subData.data.Firmenstammdaten.FABRGR }}"</span>
            melden
          </h1>
          <div class="intro-content [&amp;>p]:mb-8 max-w-3xl mx-auto">
            <p>
              Bitte ändern Sie hier Ihre IBAN und eventuell den Namen des
              abweichenden Kontoinhabers. Haben Sie Ihr Bankkonto
              <strong>nicht</strong>
              in Deutschland? Dann geben sie bitte zusätzlich den Banknamen und
              die BIC ein.
            </p>
          </div>
          <!--modal content-->
          <Alertbox />
          <div class="status-label mb-8" v-if="setStatusClass === 'modified'">
            <span
              class="bearbeitung bg-yellow-400 text-tertiary inline-block py-1 px-2 text-sm uppercase mb-2">
              Entwurf
            </span>
            <span class="infotext text-tertiary text-sm block">
              Dies ist ein Entwurf. Bitte bestätigen Sie diesen
              Änderungsauftrag, indem Sie auf
              <strong>Beauftragen</strong>
              klicken.
            </span>
          </div>
          <form :class="setStatusClass">
            <div class="grid xl:grid-cols-1 sm:gap-5">
              <div>
                <label for="aendBankname" class="text-sm mb-2 block">
                  Bankname
                  <sup>*</sup>
                </label>
                <input
                  maxlength="100"
                  type="text"
                  id="aendBankname"
                  :class="setStatusClassGEB_BNAME"
                  required
                  class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500"
                  :disabled="bankDataDisabled"
                  v-model="
                    store.subData.draft.sService.Entwuerfe
                      .AenderungFirmenBankdaten.GEB_BNAME
                  " />
              </div>
            </div>
            <div class="grid xl:grid-cols-2 sm:gap-5">
              <div class="relative">
                <label for="aendIban" class="text-sm mb-2 block">
                  IBAN
                  <sup>*</sup>
                </label>
                <input
                  maxlength="100"
                  type="text"
                  id="aendIban"
                  :class="setStatusClassGEB_IBAN"
                  class="w-full p-3 border rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 border-secondary/50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500"
                  @input="validiereIBAN"
                  :pattern="validIBAN ? '.*' : 'invalid'"
                  v-model="
                    store.subData.draft.sService.Entwuerfe
                      .AenderungFirmenBankdaten.GEB_IBAN
                  " />
                <span
                  class="text-red-500 text-sm absolute left-0 bottom-2"
                  v-if="!validIBAN">
                  Bitte geben Sie eine gültige IBAN ein.
                </span>
              </div>
              <div>
                <label for="aendBic" class="text-sm mb-2 block">
                  BIC
                  <sup>*</sup>
                </label>
                <input
                  maxlength="100"
                  type="text"
                  id="aendBic"
                  required
                  class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500"
                  :class="setStatusClassGEB_BIC"
                  :disabled="bankDataDisabled"
                  v-model="
                    store.subData.draft.sService.Entwuerfe
                      .AenderungFirmenBankdaten.GEB_BIC
                  " />
              </div>
            </div>
            <div class="grid xl:grid-cols-1 sm:gap-5">
              <div class="relative">
                <label for="aendAbwname" class="text-sm mb-2 block">
                  Name des abweichenden Kontoinhabers
                </label>
                <input
                  maxlength="100"
                  type="text"
                  id="aendAbwname"
                  :class="setStatusClassGEB_ABWNAME"
                  class="peer w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500"
                  v-model="
                    store.subData.draft.sService.Entwuerfe
                      .AenderungFirmenBankdaten.GEB_ABWNAME
                  "
                  :pattern="getNamePatternEmpty().source" />
                <span
                  class="invisible peer-invalid:visible text-red-500 text-sm absolute bottom-2 left-0 min-w-max">
                  Der angegebene Name muss gültig sein.
                </span>
              </div>
            </div>
            <div class="grid xl:grid-cols-1 sm:gap-5">
              <div>
                <label for="aendLand" class="text-sm mb-2 block">
                  Sitz des Geldinstituts
                </label>
                <input
                  maxlength="100"
                  disabled
                  type="text"
                  id="aendLand"
                  :class="{
                    'border-yellow-400 !focus:border-yellow-400 bg-yellow-50':
                      setStatusClassGEB_LAND,
                    'focus:border-tertiary/50': !setStatusClassGEB_LAND,
                  }"
                  class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500"
                  v-model="
                    store.subData.draft.sService.Entwuerfe
                      .AenderungFirmenBankdaten.GEB_LAND
                  " />
              </div>
            </div>

            <div class="flex gap-2">
            
              <input
                type="checkbox"
                id="EinzugOk"
                v-model="
                  store.subData.draft.sService.Entwuerfe
                    .AenderungFirmenBankdaten.GEB_EINZUG
                "
                true-value="1"
                false-value="0"
                class="border border-secondary/50 rounded-md invalid:border-red-500 focus:outline-0 w-5 h-5 text-tertiary appearance-none bg-white cursor-pointer" />
                <label for="EinzugOk" class="text-sm mb-2 block">
                Einzugsermächtigung erteilt
              </label>
            </div>

            <div
              class="button-wrapper flex justify-center gap-4 [&>button]:mx-0">
              <ButtonSendDataSelfService
                :disabled="
                  setSchwebendClass === 'schwebend' ||
                  setStatusClass !== 'modified' ||
                  !validIBAN ||
                  !validGEB_ABWNAME ||
                  (store.subData.isReadOnly || (store.selectedFirma?.VERMITTLER==1))
                "
                @finished="sendDataFinished"
                v-if="setSchwebendClass != 'schwebend'" />
              <div v-else>
                Ihre Bankdaten werden aktuell verarbeitet
                <br />
                und können daher nicht geändert werden.
              </div>
              <ButtonResetServiceData
                :disabled="setStatusClass !== 'modified' ||
                  (store.subData.isReadOnly || (store.selectedFirma?.VERMITTLER==1))"
                @reset="resetData" />
            </div>
          </form>
          <DatenschutzHinweis />
          <ButtonBack />
        </div>
      </div>
    </Transition>

    <FooterMenu />
  </div>
</template>
