<script setup>
import { onMounted } from "vue";
//import { ref } from "vue";
//import { Tabs } from "flowbite";
import { Dropdown } from "flowbite";
import { store } from "../components/store.vue";
import DataDetailsAnsprechpartnerTab from "../components/DataDetailsAnsprechpartnerTab.vue";
import HauptMenue from "../components/HauptMenue.vue";
import FooterMenu from "../components/FooterMenu.vue";
/** Anzeige der persönlichen Daten des Ansprechpartners
 * @displayName Seite zur Anzeige der persönlichen Daten des Ansprechpartners
 */

onMounted(() => {
  // create an array of objects with the id, trigger element (eg. button), and the content element

  // options with default values

  // set the dropdown menu element
  const $droptargetEl = document.getElementById("dropdownHover");

  // set the element that trigger the dropdown menu on click
  const $droptriggerEl = document.getElementById("dropdownHoverButton");

  // options with default values
  const dropoptions = {
    placement: "bottom",
    activeClasses: "!border-primary",
    triggerType: "hover",
    offsetSkidding: 0,
    offsetDistance: 18,
    delay: 300,
  };

  const dropdown = new Dropdown($droptargetEl, $droptriggerEl, dropoptions);

  // check if dropdown is visible/open
  dropdown.hide();
});
</script>
<script>
export default {
  name: "PersoenlicheDatenAnsprechpartner",

  components: {
    HauptMenue,
  },
  data() {
    return {
      store,
    };
  },
  beforeCreate: function () {
    this.$servertalk.doLog("# Reading From Local, Create ");
    if (!store.auth) {
      //if (!this.$servertalk.restoreStore())
      this.$router.push("/");
    }
  },
  mount: function () {
    this.$servertalk.doLog("# Reading From Local, Mount");
    if (!store.auth) {
      if (!this.$servertalk.restoreStore()) this.$router.push("/");
    }
  },
  mounted() {
    window._paq.push(["setDocumentTitle", this.name]);
    window._paq.push(["trackPageView"]);
  },
};
</script>
<template>
  <div class="main-wrapper" :class="store.accessRestriction">
    <HauptMenue />
    <div class="mt-36 md:mt-48 lg:mt-56 mb-4 max-w-7xl mx-auto px-5 lg:px-0">
      <h1
        class="text-primary/75 font-bold text-2xl md:text-3xl lg:text-4xl text-center mb-12 uppercase"
      >
        Persönliche Daten
      </h1>
      <div class="max-w-5xl mx-auto mb-32">
        <DataDetailsAnsprechpartnerTab />
      </div>
    </div>
  </div>
  <FooterMenu />
</template>
