<script>
import { store } from "./store.vue";

/**
 * Anzeige der Übersicht aller Selfservices als Boxen, bei denen schwebende oder noch nicht von Pensus abgerufene Änderungen vorliegen.
 * @displayName Offene Aufträge für Selfservices
 */
export default {
  name: "AuftraegeSelfService",
  components: {},
  props: ["nurOffene", "inBearbeitung", "unversandt"],
  emits: ["update:inBearbeitung", "update:unversandt"],
  data() {
    return {
      store
    };
  },
  mounted() {
    this.chckUnbearbeitet();
  },
  computed: {
    ServicesMain(){
     return this.getServices(); 
    },
    ServicesSub(){
     return this.getServices(true); 
    } 
  },
  methods: {
    getServices(subData = false) {
      //let filtered = JSON.stringify(store.selfService); // Objekt kopieren
      let filtered = {};
      //filtered = JSON.parse(filtered);
      const keys = Object.keys(store.selfService);
      keys.forEach((key) => {
        let e = store.selfService[key];
        if (
          (!this.nurOffene ||
            this.unversandt[key] ||
            this.inBearbeitung[key]) && // Übergabeparameter, ob nur offene oder alle Services anzuzeigen sind.
          // nötige Ausschlusskriterien für Services Masterdaten
          !subData && // Die Funktion wird einmal für MasterData und einmal für SubData aufgerufen - Hier MasterData
          e.image.length > 0 && //es muss ein Bild da sein
          (e.conditionHide.indexOf("goLive") < 0 || !store.goLive) && //golive darf nicht geforder sein ODER wir sind NICHT goLive
          (e.conditionHide.indexOf("notAnwaerter") < 0 ||
            this.$servertalk.checkAnwaerter()) && //nur für Anwäerter oder er ist Anwärter
          (e.conditionHide.indexOf("notRentner") < 0 ||
            this.$servertalk.checkRentner()) && //nur für Rentner
          (e.conditionHide.indexOf("notVerbotFirma") < 0 ||
            this.$servertalk.checkVerbotFirma()) && //nur erlaubte Firmen
          (e.conditionHide.indexOf("Steuernummer") < 0 ||
            store.satzart !== "person" ||
            store.masterData.data.Stammdaten?.STEUERIDNR.length <= 0) && //keine Steuernummer oder er hat keine
          e.conditionHide.indexOf(store.satzart) < 0 && // Wenn gesetzt, nicht für person / ansprechpartner
          e.conditionHide.indexOf("firmaNotSelected") < 0
        ) {
          filtered[key] = e;
        }
        if (
          (!this.nurOffene ||
            this.unversandt[key] ||
            this.inBearbeitung[key]) && // Übergabeparameter, ob nur offene oder alle Services anzuzeigen sind.
          // nötige Ausschlusskriterien für Services Subdaten
          subData && // Die Funktion wird einmal für MasterData und einmal für SubData aufgerufen - Hier SubData
          e.image.length > 0 && //es muss ein Bild da sein
          (e.conditionHide.indexOf("goLive") < 0 || !store.goLive) && //golive darf nicht gefordert sein ODER wir sind NICHT goLive
          e.conditionHide.indexOf("firmaNotSelected") >= 0 &&
          store.subData.data?.Firmenstammdaten?.FNAME
        ) {
          filtered[key] = e;
        }
      });

      this.$servertalk.doLog('filtered:');
      this.$servertalk.doLog(filtered);
      return filtered;
    },
    getInBearbeitung(index) {
      return this.inBearbeitung[index];
    },
    getUnversandt(index) {
      return this.unversandt[index];
    },
    chckUnbearbeitet() {
      let inB = this.inBearbeitung;
      let unV = this.unversandt;
      for (const [key] of Object.entries(inB)) {
        inB[key] =
          this.$servertalk.gettAttr(
            "subData.draft.sService.Entwuerfe." + key
          ) == "modified" ||
          this.$servertalk.gettAttr(
            "masterData.draft.sService.Entwuerfe." + key
          ) == "modified";
          unV[key] =
          this.$servertalk.checkForUnsentSelfService(key) ||
          this.$servertalk.checkForUnsentSelfService(key, store.subData);
      }
      this.$emit("update:inBearbeitung",inB);
      this.$emit("update:unversandt",unV)
    },
  },
};
</script>
<template>
  <div
    class="grid gap-5 md:grid-cols-2 xl:grid-cols-3 [&>.item>a>.item-text]:uppercase [&>.item>a>.item-text]:font-bold [&>.item>a>.item-text]:text-center [&>.item>a>.item-text]:px-2 [&>.item>a>.item-text]:text-white [&>.item>a>.item-text]:transition-all [&>.item>a>.item-text]:duration-500 [&>.item>a>.item-text]:py-2 [&>.item>a>.item-image]:border-t [&>.item>a>.item-image]:border-primary">
    <div
      v-for="(service, index) in ServicesMain"
      :key="index+'-1'"
      class="item group relative">
      <router-link active-class="font-bold" :to="service.url" class="block" :class="{ 'pointer-events-none cursor-default opacity-50': store.masterData.isReadOnly}">
        <div class="item-image relative [&>img]:min-h-[240px] [&>img]:object-cover">
          <img :src="service.image" />
          <div
            v-if="getInBearbeitung(index)"
            class="status-label absolute top-0 right-0">
            <span
              class="bearbeitung bg-yellow-500 text-tertiary block py-1 px-2 text-sm uppercase">
              Entwurf
            </span>
          </div>
          <div
            v-if="getUnversandt(index)"
            class="status-label absolute top-0 left-0">
            <span
              class="unversandt bg-green-500 text-white block py-1 px-2 text-sm uppercase">
              In Auftrag
            </span>
          </div>
        </div>
        <div
          class="item-text bg-tertiary/80 group-hover:bg-tertiary absolute bottom-0 w-full">
          {{ service.title }}
        </div>
      </router-link>
    </div>
    <div
      v-for="(service, index) in ServicesSub"
      :key="index+'-2'"
      class="item group relative">
      <router-link active-class="font-bold" :to="service.url" class="block" :class="{ 'pointer-events-none cursor-default opacity-50': store.subData.isReadOnly || store.selectedFirma?.VERMITTLER==1}">
        <div class="item-image relative [&>img]:min-h-[240px] [&>img]:object-cover">
          <img :src="service.image" />
          <div
            v-if="getInBearbeitung(index, 'sub')"
            class="status-label absolute top-0 right-0">
            <span
              class="bearbeitung bg-yellow-500 text-tertiary block py-1 px-2 text-sm uppercase">
              Entwurf
            </span>
          </div>
          <div
            v-if="getUnversandt(index, 'sub')"
            class="status-label absolute top-0 left-0">
            <span
              class="unversandt bg-green-500 text-white block py-1 px-2 text-sm uppercase">
              In Auftrag
            </span>
          </div>
        </div>
        <div
          class="item-text bg-primary/80 group-hover:bg-primary absolute bottom-0 w-full">
          {{ service.title }}
          <span
            class="text-white font-bold text-xs text-center block"
            v-if="store.subData.data?.Firmenstammdaten?.FNAME">
            {{ store.subData.data.Firmenstammdaten.FNAME }}
            {{
              $servertalk.getName(
                "GESFORM",
                store.subData.data.Firmenstammdaten?.FGESFORM
              )
            }}
            / {{ store.subData.data.Firmenstammdaten.FKENNR }} /
            {{ store.subData.data.Firmenstammdaten.FABRGR }}
          </span>
        </div>
      </router-link>
    </div>
  </div>
</template>
