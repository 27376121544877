<script setup>
import CustomSelect from "./CustomSelect.vue";
//import AppAccordion from "../components/VersorgungAccordion";
</script>
<script>
import { store } from "./store.vue";
/** Komponente zur Anzeige einer Übersicht der Versorgungsdaten im Akkordeon
 * @displayName Anzeige Versorgungsdaten
 */
export default {
  name: "VersorgungsDaten",
  props: {
    /**
     * Pensionsmanagement-Nummer
     */
    PMNR: {
      type: Number,
      required: true,
      default: null,
    },
    components: {
      //AppAccordion,
    },
  },
  data() {
    return {
      store,
      myPMNR:
        this.PMNR === -1
          ? store.masterData.data.Versorgung.PMNR
          : store.masterData.data.Versorgung.PMNR[this.PMNR],
      statusOptions: this.$servertalk.getSelectOpts("PSTATUS", true),
      statusTyp: {
        text: this.$servertalk.getName(
          "PSTATUS",
          this.PMNR === -1
            ? store.masterData.data.Versorgung.PMNR.PSTATUSN
            : store.masterData.data.Versorgung.PMNR[this.PMNR].PSTATUSN
        ),
        key:
          this.PMNR === -1
            ? store.masterData.data.Versorgung.PMNR.PSTATUSN
            : store.masterData.data.Versorgung.PMNR[this.PMNR].PSTATUSN,
      },
      vstatusOptions: this.$servertalk.getSelectOpts("VSTATUS"),
      vstatusTyp: {
        text: this.$servertalk.getName(
          "VSTATUS",
          this.PMNR === -1
            ? store.masterData.data.Versorgung.PMNR.FANR
            : store.masterData.data.Versorgung.PMNR[this.PMNR].FANR
        ),
        key:
          this.PMNR === -1
            ? store.masterData.data.Versorgung.PMNR.FANR
            : store.masterData.data.Versorgung.PMNR[this.PMNR].FANR,
      },
      /* bundeslandOptions: this.$servertalk.getSelectOpts("BUNDESLAND"),
      bundeslandTyp: {
        text: this.$servertalk.getName(
          "BUNDESLAND",
          this.PMNR === -1
            ? store.masterData.data.Versorgung.PMNR.ABRKREIS
            : store.masterData.data.Versorgung.PMNR[this.PMNR].ABRKREIS
        ),
        key:
          this.PMNR === -1
            ? store.masterData.data.Versorgung.PMNR.ABRKREIS
            : store.masterData.data.Versorgung.PMNR[this.PMNR].ABRKREIS,
      }, */
      anpassungOptions: this.$servertalk.getSelectOpts("ANPGRUPPE"),
      anpassungsGruppe: {
        text: this.$servertalk.getName(
          "ANPGRUPPE",
          this.PMNR === -1
            ? store.masterData.data.Versorgung.PMNR.ANPGRU
            : store.masterData.data.Versorgung.PMNR[this.PMNR].ANPGRU
        ),
        key:
          this.PMNR === -1
            ? store.masterData.data.Versorgung.PMNR.ANPGRU
            : store.masterData.data.Versorgung.PMNR[this.PMNR].ANPGRU,
      },
      statusAnwaerter: this.$servertalk.getStatusAnwaerter(),
      statusRentner: this.$servertalk.getStatusRente(),
    };
  },

  methods: {},
  computed: {
    traegerunternehmen: {
      get() {
        return `${this.myPMNR.FENAME} ${this.myPMNR.GESFORM} `;
      },
    },
    kennummer: {
      get() {
        return `${this.myPMNR.KENNR} / ${this.myPMNR.ABRGR} `;
      },
    },
  },
};
</script>
<template>
  <app-accordion>
    <template v-slot:versorgungtitle>
      <div>
        <span class="block lg:inline">
          PM-Nr. {{ myPMNR["@attributes"].PMNR }}
        </span>
        <span class="text-tertiary lg:pl-4" v-if="myPMNR.FANR == 3">
          Unterstützungskasse
        </span>
        <span class="text-tertiary lg:pl-4" v-else>{{ myPMNR.FENAME }}</span>
      </div>
    </template>

    <template v-slot:versorgungcontent>
      <form class="bg-gray-50 p-4 sm:p-8">
        <label for="status" class="text-sm mb-2 block">Personenstatus</label>
        <CustomSelect
          @input="statusTyp"
          class="schwebend"
          :options="statusOptions"
          :default="myPMNR.PSTATUSN"
          id="status"
        />
        <div v-if="myPMNR.FANR != 3">
          <label for="abrechnung-lang" class="text-sm mb-2 block">
            <span v-if="statusAnwaerter.includes(myPMNR.PSTATUSN)">
              Arbeitgeber / Versorgungsträger
            </span>
            <span v-if="statusRentner.includes(myPMNR.PSTATUSN)">
              ehemaliger Arbeitgeber / Versorgungsträger
            </span>
          </label>
          <input
            maxlength="255"
            type="text"
            id="abrechnung-lang"
            class="w-full p-3 border border-secondary/50 rounded-md mb-8"
            required
            v-model="myPMNR.FENAME"
            disabled
          />
        </div>
        <div v-if="myPMNR.FANR == 3">
          <label for="traegerunternehmen" class="text-sm mb-2 block">
            Trägerunternehmen
          </label>
          <input
            maxlength="255"
            type="text"
            id="traegerunternehmen"
            class="w-full p-3 border border-secondary/50 rounded-md mb-8"
            required
            v-model="myPMNR.FENAME"
            disabled
          />
        </div>
        <div v-if="myPMNR.FANR != 3 && myPMNR.STNR">
          <label for="stammnummer" class="text-sm mb-2 block">Stamm-Nr.</label>
          <input
            maxlength="255"
            type="text"
            id="stammnummer"
            class="w-full p-3 border border-secondary/50 rounded-md mb-8"
            required
            v-model="myPMNR.STNR"
            disabled
          />
        </div>
        <div v-if="myPMNR.FANR == 3">
          <label for="kennummer" class="text-sm mb-2 block">
            Kenn.-Nr. / Abrechnungsgruppe
          </label>
          <input
            maxlength="255"
            type="text"
            id="kennummer"
            class="w-full p-3 border border-secondary/50 rounded-md mb-8"
            required
            v-model="kennummer"
            disabled
          />
        </div>
        <div
          v-if="
            myPMNR.FANR != 3 &&
            myPMNR.ANPGRU != 0 &&
            myPMNR.ANPGRU != 7 &&
            myPMNR.ANPGRU != 8 &&
            myPMNR.ANPGRU != 9 &&
            myPMNR.ANPGRU != 10
          "
        >
          <div v-if="statusRentner.includes(myPMNR.PSTATUSN) && myPMNR.LANPASS">
            <label for="lanpass" class="text-sm mb-2 block">
              Datum der letzten Rentenanpassung
            </label>
            <input
              maxlength="255"
              type="date"
              id="lanpass"
              class="w-full p-3 border border-secondary/50 rounded-md mb-8"
              required
              v-model="myPMNR.LANPASS"
              disabled
            />
          </div>
          <div v-if="statusRentner.includes(myPMNR.PSTATUSN) && myPMNR.NANPASS">
            <label for="nanpass" class="text-sm mb-2 block">
              Datum der nächsten Rentenanpassung
            </label>
            <input
              maxlength="255"
              type="date"
              id="nanpass"
              class="w-full p-3 border border-secondary/50 rounded-md mb-8"
              required
              v-model="myPMNR.NANPASS"
              disabled
            />
          </div>

          <div
            v-if="
              statusRentner.includes(myPMNR.PSTATUSN) &&
              anpassungsGruppe.text !== 'unbekannt'
            "
          >
            <label for="anpassung" class="text-sm mb-2 block">
              Anpassungsrhythmus
            </label>
            <CustomSelect
              @input="statusTyp"
              class="schwebend"
              :options="anpassungOptions"
              :default="myPMNR.ANPGRU"
              id="anpassung"
            />
          </div>
        </div>
      </form>
    </template>
  </app-accordion>
</template>
