<script setup>
import HauptMenue from "../../components/HauptMenue.vue";
import FooterMenu from "../../components/FooterMenu.vue";
import DatenschutzHinweis from "../../components/DatenschutzHinweis.vue";
import ButtonSendDataSelfService from "../../components/ButtonSendDataSelfService.vue";
import ButtonResetServiceData from "../../components/ButtonResetServiceData.vue";
import ButtonBack from "../../components/ButtonBack.vue";
import CustomSelect from "../../components/CustomSelect.vue";
import DateInput from "../../components/DateInput.vue";
import { store } from "../../components/store.vue";
import DokumenteHochladen from "../../components/DokumenteHochladenService.vue";
import Alertbox from "../../components/Alertbox.vue";
import { bankDataByIBAN } from "bankdata-germany";
import { isValidIBAN } from "ibantools-germany";
import { isSteuerIdValid } from "validate-steuerid";
//import { friendlyFormatIBAN, electronicFormatIBAN } from "ibantools";
import {
  getNamePattern,
  //getPostalCodePattern,
  //getPhoneNumberPattern,
  getStreetAndNumberPattern,
} from "../../utility";
/** View-Komponente zum Erfassen eines Antrags für Betriebsrente
 * @displayName Selfservice Antrag Betriebsrente
 */
</script>
<script>
let uuid = 0;
export default {
  name: "SelfServiceAntragBetriebsrente",

  components: {
    // myRouter: router,
    HauptMenue,
    FooterMenu,
    DatenschutzHinweis,
    ButtonSendDataSelfService,
    ButtonResetServiceData,
    CustomSelect,
    DateInput,
    ButtonBack,
    DokumenteHochladen,
    Alertbox,
    bankDataByIBAN,
    isValidIBAN,
  },

  data() {
    return {
      openIndex: null,
      store,
      validSteuerid: false,
      steueridInput: "",
      sPMNROptions: this.$servertalk.getPMNROpts("anwaerter"),
      sPMNR: {
        text:
          this.$servertalk.getPMNROpts("anwaerter").length == 0
            ? ""
            : this.$servertalk.getPMNROpts("anwaerter")[0]["text"],
        key:
          this.$servertalk.getPMNROpts("anwaerter").length == 0
            ? "0"
            : this.$servertalk.getPMNROpts("anwaerter")[0]["value"],
      },
      sGeschlOptions: this.$servertalk.getSelectOpts("GESCHLECHT"),
      sGeschlecht: {
        text: this.$servertalk.getName(
          "GESCHLECHT",
          store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.GESCHL
        ),
        key: store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.GESCHL,
      },
      sBeitragspflicht: {
        text: this.$servertalk.getName(
          "KK_STATUS",
          store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.BEITRAGSPFLICHTIG
        ),
        key: store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente
          .BEITRAGSPFLICHTIG,
      },
      sKiloOptions: this.$servertalk.getSelectOpts("KILO"),
      sKilo: {
        text: this.$servertalk.getName(
          "KILO",
          store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.KILO
        ),
        key: store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.KILO,
      },
      sPensartOptions: this.$servertalk.getSelectOpts("PENSARTCUST"),
      sPensart: {
        text: this.$servertalk.getName(
          "PENSARTCUST",
          store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.PENSART
        ),
        key: store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.PENSART,
      },
      sKrankenkasse: {
        text: this.$servertalk.getName(
          "KRANKENKASSE",
          store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.KRANKENKASSE
        ),
        key: store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.KRANKENKASSE,
      },
      sLandOptions: this.$servertalk.getSelectOpts("NATIONALITAET"),
      sLand: {
        text: this.$servertalk.getName(
          "NATIONALITAET",
          store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.PLKZ
        ),
        key: store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.PLKZ,
      },
      //sDokuOptions: this.$servertalk.getSelectOpts("KILO"),
      validIBAN: false, // Hier wird der Validitätsstatus der IBAN gespeichert
      bankData: {
        bankName: "",
        bic: "",
      }, // Hier werden die Bankdaten gespeichert
      //ibanPrefix: "", // Hier werden die ersten zwei Stellen der IBAN für das Feld Sitz des Geldinstituts gespeichert
      //vorgefuellteIBAN: "", // Hier wird die IBAN aus dem Store gespeichert
      saved: false,
      //dummys
      bankDataDisabled: true,
    };
  },
  mounted() {
    this.$servertalk.doLog("start mounted");
    window.scrollTo(0, 0);
    window._paq.push(["setDocumentTitle", this.name]);
    window._paq.push(["trackPageView"]);
    const validSteuerID = isSteuerIdValid(store.masterData.data.Stammdaten.STEUERIDNR);
    this.$servertalk.doLog("Steuer:", validSteuerID);
    if (this.steueridInput === "") {
      this.steueridInput =
        store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.STEUERIDNR; // Hier nehme Ich an, dass die SteuerID im Store gespeichert ist
      this.validiereSteuerid(); // Führt die Validierung und Aktualisierung der SteuerID aus
    }
    let opts = this.$servertalk.getPMNROpts("anwaerter");

    if (this.$servertalk.getPMNROpts("anwaerter").length == 1) {
      store.masterData.olddraft.sService.Entwuerfe.AntragBetriebsrente.PMNR =
        opts[0].value;
      store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.PMNR = opts[0].value;
    }
    this.validIBAN = isValidIBAN(
      store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.PRB_IBAN
    );
    this.$servertalk.doLog("mid mounted");
    const iban = bankDataByIBAN(
      store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.PRB_IBAN
    );
    /*
    const friendlyiban = friendlyFormatIBAN(
      store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.PRB_IBAN
    );
    const electronicformat = electronicFormatIBAN(
      store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.PRB_IBAN
    );
    */
    this.validiereIBAN(); // Führt die Validierung und Aktualisierung der Bankdaten aus
    this.$servertalk.doLog("end mounted");
  },
  unmounted() {
    if (this.saved) {
      // Nach Beauftragung Service in Draft komplett löschen
      delete store.masterData.olddraft.sService.Entwuerfe.AntragBetriebsrente;
      delete store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente;
      this.saved = false;
    }
  },
  beforeCreate: function () {
    //this.uuid = uuid.toString();
    //uuid += 1;
    this.$servertalk.doLog("Create");
    this.$servertalk.doLog("Inhalt store:");
    this.$servertalk.doLog(store);
    let action = this.$servertalk.initSelfService(
      "AntragBetriebsrente",
      '{"PMNR":"0","ARBEITGEBER":"","NAME":"","VORNAME":"", "TITEL":"","NAMEVS":"","NAMEZUS":"","GEBDAT":"","GESCHL":"1","PCONAME":"","PSTRASSE":"","PLKZ":"","PPLZ":"","PORT":"","KRANKENKASSE":"-1","RENTENVERSICHERUNGSNUMMER":"","BEITRAGSPFLICHTIG":"-1","uploadskk":[],"KILO":"0","KINDER":[],"PRB_ABWNAME":"","PRB_BIC":"","PRB_IBAN":"", "PRB_BNAME":"","PRB_LAND":"","STEUERIDNR":"","PENSART":"0","RENTENBEGINN":"","BEMERKUNGEN":"","uuid":"","uploadsDokumente":[],"DokBetrRent":"","version":"1.0"}',
      "1.0"
    );
    let _versorgung = false;
    let _kkData = false;
    let _kinderData = false;

    //es kann auch keine KK geben

    if (typeof store.masterData.data.Versorgung === "object") {
      if (store.masterData.data.Versorgung.PMNR.length > 1) {
        _versorgung = store.masterData.data.Versorgung.PMNR[0];
      } else {
        _versorgung = store.masterData.data.Versorgung.PMNR;
      }
    }

    _kkData = _versorgung?.Krankenkasse ?? false;
    _kinderData = _versorgung?.Kinder ?? false;

    this.versorgung = _versorgung;

    //console.log("Versorgung", _versorgung);
    //console.log("Krankenkasse", _kkData);
    //console.log("Kinder", _kinderData);

    if (action % 2 == 1) {
      // olddraft wurde initialisiert
      let olddraft = store.masterData.olddraft.sService.Entwuerfe.AntragBetriebsrente;

      //kk-Daten sind für alle PMNR gleich!
      olddraft.KRANKENKASSE = _kkData.KKNR;

      //start ist die PMNR 0
      let _PMNR = "0";
      if (typeof store.masterData.data.Versorgung === "object") {
        if (store.masterData.data.Versorgung.PMNR.length > 1) {
          _PMNR = store.masterData.data.Versorgung.PMNR[0]["@attributes"].PMNR;
        } else {
          _PMNR = store.masterData.data.Versorgung.PMNR["@attributes"].PMNR;
        }
      }

      olddraft.PMNR = _PMNR;
      olddraft.NAME = store.masterData.data.Stammdaten.NAME;
      olddraft.VORNAME = store.masterData.data.Stammdaten.VORNAME;
      olddraft.TITEL = store.masterData.data.Stammdaten.TITEL;
      olddraft.NAMEVS = store.masterData.data.Stammdaten.NAMEVS;
      olddraft.NAMEZUS = store.masterData.data.Stammdaten.NAMEZUS;
      olddraft.GEBDAT = store.masterData.data.Stammdaten.GEBDAT;
      olddraft.GESCHL = store.masterData.data.Stammdaten.GESCHL; // Dropdown
      //olddraft.KILO = "0"; // Dropdown
      //olddraft.KINDER = store.masterData.data.Stammdaten.KINDER; // Dropdown
      olddraft.PCONAME = store.masterData.data.Privatadresse.PCONAME;
      olddraft.PSTRASSE = store.masterData.data.Privatadresse.PSTRASSE;
      olddraft.PLKZ = store.masterData.data.Privatadresse.PLKZ;
      olddraft.PPLZ = store.masterData.data.Privatadresse.PPLZ;
      olddraft.PORT = store.masterData.data.Privatadresse.PORT;

      olddraft.RENTENVERSICHERUNGSNUMMER = store.masterData.data.Stammdaten.SVNR;
      //olddraft.BEITRAGSPFLICHTIG = "-1"; // Dropdown (eigenes)
      olddraft.BEITRAGSPFLICHTIG = _kkData.KKSTATUS; // Dropdown (eigenes)
      olddraft.PRB_ABWNAME = store.masterData.data.Privatbank.PRB_ABWNAME;
      olddraft.PRB_BIC = store.masterData.data.Privatbank.PRB_BIC;
      olddraft.PRB_BNAME = store.masterData.data.Privatbank.PRB_BNAME;
      olddraft.PRB_LAND = store.masterData.data.Privatbank.PRB_LAND;
      olddraft.PRB_IBAN = store.masterData.data.Privatbank.PRB_IBAN;
      olddraft.STEUERIDNR = store.masterData.data.Stammdaten.STEUERIDNR;
      olddraft.PENSART = "0"; // Dropdown (eigenes)
      olddraft.DokBetrRent = _versorgung.DokBetrRent;
      olddraft.uuid = this.$servertalk.getUUID();

      if (_kinderData) {
        olddraft.KILO = "1";
        _kinderData?.KLFDNR.forEach((kind) => {
          olddraft.KINDER.push({
            VORNAME: kind.KVORNAME,
            NAME: kind.KNAME,
            GEBDAT: kind.KGEBDAT,
            uploads: [], //füllen, da der Service ansonsten nicht angenommen wird
            uploadFolgt: "1",
            vonPensus: true,
            uuid: this.$servertalk.getUUID(),
          });
        });
      }
    }
    if (action >= 2) {
      // draft wurde initialisiert
      this.$servertalk.copyToDraftSelfService("AntragBetriebsrente");
    }
  },
  methods: {
    validiereSteuerid() {
      this.validSteuerid = isSteuerIdValid(
        store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.STEUERIDNR
      );
    },
    validPSTRASSE() {
      const pattern = getStreetAndNumberPattern();
      return store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.PSTRASSE.match(
        pattern
      );
    },
    validRENTENBEGINN() {
      return !isNaN(
        Date.parse(
          store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.RENTENBEGINN
        )
      );
    },
    validiereIBAN() {
      store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.PRB_IBAN =
        store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.PRB_IBAN.toUpperCase();
      // Führt die Validierung und Abfrage der Bankdaten durch
      this.validIBAN = isValidIBAN(
        store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.PRB_IBAN
      );
      const fetchedBankData = bankDataByIBAN(
        store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.PRB_IBAN
      );
      // Überprüfe, ob die IBAN mit "DE" beginnt
      if (
        store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.PRB_IBAN.startsWith(
          "DE"
        )
      ) {
        // Wenn ja, aktualisiere die Bankdaten aus dem abgerufenen Objekt
        if (fetchedBankData) {
          store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.PRB_BNAME =
            fetchedBankData.bankName;
          store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.PRB_BIC =
            fetchedBankData.bic;
          //this.bankData.blz = fetchedBankData.blz;
          this.bankDataDisabled = true; // IBAN beginnt mit "DE", daher sind die Inputfelder aktiviert
        }
      } else {
        // Wenn nicht, lösche die Felder bankName und bic
        store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.PRB_BNAME = "";
        store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.PRB_BIC = "";
        this.bankDataDisabled = false;
      }
      if (
        store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.PRB_IBAN.length < 2
      ) {
        this.bankDataDisabled = true; // Keine IBAN erfasst: Banknamen und BIC gegen Eingaben sperren.
        store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.PRB_LAND = "";
      } else {
        // Extrahiere die ersten zwei Zeichen der IBAN und speicher sie in der Datenvariable ibanPrefix
        store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.PRB_LAND =
          store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.PRB_IBAN.slice(
            0,
            2
          );
      }
      if (this.$servertalk.checkPrivatbankErfasst())
        this.bankDataDisabled = true; // Wurden bereits Bankdaten erfasst, sind hier sämtliche Felder gesperrt
    },
    toggleAccordion(index) {
      if (this.isOpen(index)) {
        this.openIndex = null;
      } else {
        this.openIndex = index;
      }
    },
    isOpen(index) {
      return this.openIndex === index;
    },

    addKind(event) {
      if (event) {
        event.preventDefault(); // Verhindert das Standardverhalten des Buttons
      }

      if (store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.KINDER) {
        const newKind = {
          VORNAME: "",
          NAME: "",
          GEBDAT: "",
          uploads: [], //für die Geburtsurkunde
          uploadFolgt: "0",
          uuid: this.$servertalk.getUUID(),
        };
        store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.KINDER.push(newKind);
        this.$servertalk.doLog("Kind angelegt");
        this.$servertalk.doLog(
          store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.KINDER
        );
        this.openIndex =
          store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.KINDER.length - 1;
      }
    },
    removeKind(index) {
      if (
        Object.keys(store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.KINDER)
          .length == 1
      ) {
        store.message = {
          text: "Wenn Sie keine Kinder erfassen möchten, wählen Sie den entsprechenden Eintrag im Dropdown-Menü.",
          status: "error",
        };
        const newKind = {
          VORNAME: "",
          NAME: "",
          GEBDAT: "",
          uploads: [], //für die Geburtsurkunde
          uploadFolgt: "0",
          uuid: this.$servertalk.getUUID(),
        };
        store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.KINDER.push(newKind);
      } //else {
      //nur files tragen das Element "hmac"
      //damit kann ich Files vom Server löschen
      this.$servertalk.doLog(
        "xxxx",
        store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.KINDER,
        index
      );
      let files = this.$servertalk.treeToArray(
        store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.KINDER[index]
          .uploads,
        "hmac"
      );
      files.forEach((hmac) => {
        this.$servertalk.doLog("Kind gelöscht " + hmac);
        this.$servertalk.delDoc(hmac);
      });
      store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.KINDER.splice(
        index,
        1
      );
      store.message = {
        text: "Die Daten zum Kind wurden gelöscht.",
        status: "ok",
      };
      //}
    },
    setPMNR(val) {
      if (val && typeof val === "object" && typeof val.value !== "undefined") {
        store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.PMNR =
          val.value + ""; // Dropdown stets als String speichern wegen modified-Abgleich
        //let temp = store.indextable.TAB.find(tab => tab['@attributes']['NAME'] == tabname)['KEY'];
        if (typeof store.masterData.data.Versorgung === "object") {
          if (store.masterData.data.Versorgung.PMNR.length > 1) {
            store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.KRANKENKASSE =
              store.masterData.data.Versorgung.PMNR.find(
                (tab) => tab["@attributes"]["PMNR"] == val.value
              )["Krankenkasse"].KKNR;
            store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.DokBetrRent =
              store.masterData.data.Versorgung.PMNR.find(
                (tab) => tab["@attributes"]["PMNR"] == val.value
              ).DokBetrRent;
          } else {
            store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.KRANKENKASSE =
              store.masterData.data.Versorgung.PMNR.Krankenkasse.KKNR;
            store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.DokBetrRent =
              store.masterData.data.Versorgung.PMNR.DokBetrRent;
          }
        } else {
          store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.DokBetrRent = "";
          store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.KRANKENKASSE =
            "-1";
        }
        if (
          store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.KRANKENKASSE == ""
        ) {
          store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.KRANKENKASSE =
            "-1";
        }
        store.masterData.olddraft.sService.Entwuerfe.AntragBetriebsrente.KRANKENKASSE =
          store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.KRANKENKASSE;
        store.masterData.olddraft.sService.Entwuerfe.AntragBetriebsrente.DokBetrRent =
          store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.DokBetrRent;
      }
    },
    setGeschlecht(val) {
      if (val && typeof val === "object" && typeof val.value !== "undefined") {
        store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.GESCHL =
          val.value + ""; // Dropdown stets als String speichern wegen modified-Abgleich
      }
    },
    setBeitragspflicht(val) {
      if (val && typeof val === "object" && typeof val.value !== "undefined") {
        store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.BEITRAGSPFLICHTIG =
          val.value + ""; // Dropdown stets als String speichern wegen modified-Abgleich
      }
    },
    setPensionsart(val) {
      if (val && typeof val === "object" && typeof val.value !== "undefined") {
        store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.PENSART =
          val.value + ""; // Dropdown stets als String speichern wegen modified-Abgleich
      }
    },
    setKrankenkasse(val) {
      if (val && typeof val === "object" && typeof val.value !== "undefined") {
        store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.KRANKENKASSE =
          val.value + ""; // Dropdown stets als String speichern wegen modified-Abgleich
      }
    },
    setLand(val) {
      if (val && typeof val === "object" && typeof val.value !== "undefined") {
        store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.PLKZ =
          val.value + ""; // Dropdown stets als String speichern wegen modified-Abgleich
      }
    },
    setKilo(val) {
      if (val && typeof val === "object" && typeof val.value !== "undefined") {
        store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.KILO =
          val.value + ""; // Dropdown stets als String speichern wegen modified-Abgleich
        if (val.value + "" === "1")
          // kinderhabend
          this.addKind(null);
      }
    },
    sendDataFinished() {
      let messageCode = this.$servertalk.sendDataSelfService(
        "AntragBetriebsrente"
      );
      store.message =
        messageCode == 0
          ? {
            text: "Ihre Daten im Bereich\n Antrag Betriebsrente \n wurden erfolgreich erfasst.",
            status: "ok",
          }
          : {
            text:
              "Beim Speichern des Auftrags im Bereich\n Antrag Betriebsrente \n trat ein Fehler auf (Error-Code " +
              messageCode +
              ").",
            status: "error",
          };
      if (messageCode == 0) this.saved = true;
      this.$matomo?.trackEvent("SendService", "AntragBetriebsrente");
      this.$router.push("/auftrag-an-pensus");
    },
    resetData() {
      let messageCode = this.$servertalk.copyToDraftSelfService(
        "AntragBetriebsrente"
      );
      this.validiereIBAN();
      store.message =
        messageCode == 0
          ? {
            text: "Ihre Daten im Bereich\n Antrag Betriebsrente \n wurden zurückgesetzt.",
            status: "ok",
          }
          : {
            text:
              "Beim Zurücksetzen der Daten im Bereich\n Antrag Betriebsrente \n trat ein Fehler auf (Error-Code " +
              messageCode +
              ").",
            status: "error",
          };
    },
    infoDocStatus(val) {
      this.$servertalk.doLog("infoDocStatus: ");
      this.$servertalk.doLog(val);
    },
    infoDocOk(val) {
      this.$servertalk.doLog("infoDocOk: " + val.uploadStatus);
    },
    docDocStatus(val) {
      this.$servertalk.doLog("docDocStatus: " + val.uploadStatus);
    },
    docKindOk(val, i) {
      if (val == false)
        // Dokument wurde gelöscht: Auch Referenz im Store entfernen
        store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.KINDER[i].uploads =
          [];
    },
    docExtraOk(val, i) {
      if (val == false)
        // Dokument wurde gelöscht: Auch Referenz im Store entfernen
        store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.uploadsDokumente =
          [];
    },
    changeFiles(val, i) {
      this.$servertalk.doLog("changeFiles");
      store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.KINDER[i].uploads =
        val;
      //store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.KINDER[i].uploadFolgt = '0';
    },
    changeExtraFiles(val, i) {
      this.$servertalk.doLog("changeExtraFiles");
      store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.uploadsDokumente = val;
      //store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.KINDER[i].uploadFolgt = '0';
    },
    kindUploaded(kind) {
      return `${kind.uploads.length}` > 0;
    },
  },
  computed: {
    sDekBetrRent() {
      //this.store.masterData.data.Versorgung.PMNR[0].DokBetrRent
      let infotext = this.store.masterData.data.Versorgung.PMNR?.DokBetrRent;
      let vari = this.$servertalk.getVersorgung(); // store.masterData.data.Versorgung.PMNR stets als Array bereitstellen
      let myPMNR = store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.PMNR;
      if (!infotext) {
        infotext = vari.find(
          (tab) => tab["@attributes"]["PMNR"] == myPMNR
        )?.DokBetrRent;
      }

      return infotext.split("\n").join("<br>");
    },
    sKrankenkasseOptions() {
      let opts = this.$servertalk.getSelectOpts("KRANKENKASSE");
      let KKSTATUS =
        store.masterData.olddraft.sService.Entwuerfe.AntragBetriebsrente.BEITRAGSPFLICHTIG;
      //finde die private Kasse
      let privNr = opts.findIndex((el) => el.value == "999");

      if (KKSTATUS == "-1" || KKSTATUS == "") {
        opts = [{ text: "unbekannt", value: "-1" }].concat(opts);
      }
      if (KKSTATUS != "2") {
        opts = opts.slice(0, privNr - 1).concat(opts.slice(privNr + 1));
      }
      if (KKSTATUS == "2") {
        opts = [opts[privNr]];
      }
      return opts;
    },
    sBeitragspflichtOptions() {
      let opts = this.$servertalk.getSelectOpts("KK_STATUS");

      if (
        store.masterData.olddraft.sService.Entwuerfe.AntragBetriebsrente
          .BEITRAGSPFLICHTIG == "-1" ||
        store.masterData.olddraft.sService.Entwuerfe.AntragBetriebsrente
          .BEITRAGSPFLICHTIG == ""
      ) {
        opts = [{ text: "unbekannt", value: "-1" }].concat(opts);
      }
      return opts;
    },
    setSchwebendClass() {
      // Erhalte den Wert "Privatadresse" von $servertalk
      const aenderung = this.$servertalk.gettAttr("masterData.data.Versorgung");
      //const aenderungPrivatadresseValue = this.$servertalk.gettAttr("subData.draft.sService.Entwuerfe.AenderungKontaktdaten");

      return aenderung;
    },
    checkPrivatadresseErfasst() {
      return this.$servertalk.checkPrivatadresseErfasst;
    },
    checkPrivatbankErfasst() {
      return this.$servertalk.checkPrivatbankErfasst();
    },
    validPCONAME() {
      const pattern = getNamePattern();
      return (
        store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.PCONAME.match(
          pattern
        ) || store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.PCONAME === ""
      );
    },
    validPRB_ABWNAME() {
      const pattern = getNamePattern();
      return (
        store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.PRB_ABWNAME.match(
          pattern
        ) ||
        store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.PRB_ABWNAME === ""
      );
    },
    validKINDER() {
      let valid = true;
      const pattern = getNamePattern();
      let kinder = store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.KINDER;
      const keys = Object.keys(kinder);
      keys.forEach((key, index) => {
        if (
          !kinder[key]["NAME"].match(pattern) ||
          kinder[key]["NAME"] === "" ||
          !kinder[key]["VORNAME"].match(pattern) ||
          kinder[key]["VORNAME"] === "" ||
          isNaN(Date.parse(kinder[key]["GEBDAT"])) ||
          (kinder[key]["uploads"].length == 0 &&
            kinder[key]["uploadFolgt"] !== "1")
        ) {
          valid = false;
        }
      });
      return valid;
    },
    checkKilo() {
      if (store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.KILO !== "1")
        store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.KINDER = [];
      return store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.KILO === "1"; // Kinder habend
    },
    checkBeitragspflicht() {
      //ahn debug

      if (
        store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.BEITRAGSPFLICHTIG ===
        "2"
      ) {
        // "privat"
        this.setKrankenkasse({ value: "999" }); // KK Privat / Ausland
        this.setKilo({ value: "0" }); // unbekannt
        return true;
      }
      return false;
    },
    setStatusClass() {
      // Erhalte den Wert "AntragBetriebsrente" von $servertalk
      const AntragBetriebsrenteValue = this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.AntragBetriebsrente"
      );
      return AntragBetriebsrenteValue;
    },
    //dummys, da es sonst Fehlermeldungen gibt und der Vue nach dem Build nicht läuft
    setStatusClassPRB_BNAME() {
      //return "dummy";
      return this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.AntragBetriebsrente.PRB_BNAME"
      );
    },
    setStatusClassPMNR() {
      // Erhalte den Wert "PMNR" von $servertalk
      return this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.AntragBetriebsrente.PMNR"
      );
    },
    setStatusClassARBEITGEBER() {
      // Erhalte den Wert "ARBEITGEBER" von $servertalk
      return this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.AntragBetriebsrente.ARBEITGEBER"
      );
    },
    setStatusClassNACHNAME() {
      // Erhalte den Wert "NACHNAME" von $servertalk
      return this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.AntragBetriebsrente.NAME"
      );
    },
    setStatusClassVORNAME() {
      // Erhalte den Wert "VORNAME" von $servertalk
      return this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.AntragBetriebsrente.VORNAME"
      );
    },
    setStatusClassTITEL() {
      // Erhalte den Wert "TITEL" von $servertalk
      return this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.AntragBetriebsrente.TITEL"
      );
    },
    setStatusClassNAMEVS() {
      // Erhalte den Wert "NAMEVS" von $servertalk
      return this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.AntragBetriebsrente.NAMEVS"
      );
    },
    setStatusClassNAMEZUS() {
      // Erhalte den Wert "NAMEZUS" von $servertalk
      return this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.AntragBetriebsrente.NAMEZUS"
      );
    },
    setStatusClassGEBDAT() {
      // Erhalte den Wert "GEBDAT" von $servertalk
      return this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.AntragBetriebsrente.GEBDAT"
      );
    },
    setStatusClassGESCHL() {
      // Erhalte den Wert "GESCHL" von $servertalk
      return this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.AntragBetriebsrente.GESCHL"
      );
    },
    setStatusClassPCONAME() {
      // Erhalte den Wert "PCONAME" von $servertalk
      return this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.AntragBetriebsrente.PCONAME"
      );
    },
    setStatusClassPSTRASSE() {
      // Erhalte den Wert "PSTRASSE" von $servertalk
      return this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.AntragBetriebsrente.PSTRASSE"
      );
    },
    setStatusClassPLKZ() {
      // Erhalte den Wert "PLKZ" von $servertalk
      return this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.AntragBetriebsrente.PLKZ"
      );
    },
    setStatusClassPPLZ() {
      // Erhalte den Wert "PPLZ" von $servertalk
      return this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.AntragBetriebsrente.PPLZ"
      );
    },
    setStatusClassPORT() {
      // Erhalte den Wert "PORT" von $servertalk
      return this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.AntragBetriebsrente.PORT"
      );
    },
    setStatusClassKRANKENKASSE() {
      // Erhalte den Wert "KRANKENKASSE" von $servertalk
      return this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.AntragBetriebsrente.KRANKENKASSE"
      );
    },
    setStatusClassRENTENVERSICHERUNGSNUMMER() {
      // Erhalte den Wert "RENTENVERSICHERUNGSNUMMER" von $servertalk
      return this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.AntragBetriebsrente.RENTENVERSICHERUNGSNUMMER"
      );
    },
    setStatusClassBEITRAGSPFLICHTIG() {
      // Erhalte den Wert "BEITRAGSPFLICHTIG" von $servertalk
      return this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.AntragBetriebsrente.BEITRAGSPFLICHTIG"
      );
    },
    setStatusClassPRB_ABWNAME() {
      // Erhalte den Wert "PRB_ABWNAME" von $servertalk
      return this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.AntragBetriebsrente.PRB_ABWNAME"
      );
    },
    setStatusClassPRB_BIC() {
      // Erhalte den Wert "PRB_BIC" von $servertalk
      return this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.AntragBetriebsrente.PRB_BIC"
      );
    },
    setStatusClassPRB_IBAN() {
      // Erhalte den Wert "PRB_IBAN" von $servertalk
      return this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.AntragBetriebsrente.PRB_IBAN"
      );
    },
    setStatusClassSTEUERIDNR() {
      // Erhalte den Wert "STEUERIDNR" von $servertalk
      return this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.AntragBetriebsrente.STEUERIDNR"
      );
    },
    setStatusClassPENSART() {
      // Erhalte den Wert "PENSART" von $servertalk
      return this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.AntragBetriebsrente.PENSART"
      );
    },
    setStatusClassRENTENBEGINN() {
      // Erhalte den Wert "RENTENBEGINN" von $servertalk
      return this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.AntragBetriebsrente.RENTENBEGINN"
      );
    },
    setStatusClassBEMERKUNGEN() {
      // Erhalte den Wert "BEMERKUNGEN" von $servertalk
      return this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.AntragBetriebsrente.BEMERKUNGEN"
      );
    },
    setStatusClassKILO() {
      // Erhalte den Wert "KILO" von $servertalk
      return this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.AntragBetriebsrente.KILO"
      );
    },
    /* getDokBetrRent(pmnr) {
      // Finde den Eintrag basierend auf 'pmnr'
      const entry = this.store.masterData.data.Versorgung.PMNR.find(
        (item) => item.pmnr === pmnr
      );
      // Gib den entsprechenden Wert zurück, falls ein Eintrag gefunden wurde
      return entry ? entry.DokBetrRent : "";
    },
    bRenteMaxDate() {
      let date = new Date();
      return date
        .setDate(date.getDate() + 150)
        .toISOString()
        .slice(0, 10);
    },
    bRenteDefDate() {
      let date = new Date();
      return date
        .setDate(date.getDate() + 7)
        .toISOString()
        .slice(0, 10);
    }, */
  },
};
</script>
<template>
  <div class="main-wrapper" :class="store.accessRestriction">
    <HauptMenue />

    <Transition>
      <div class="mt-36 md:mt-48 lg:mt-56 max-w-7xl mx-auto">
        <div class="max-w-5xl mx-auto mb-8 px-5 lg:px-0">
          <h1
            class="text-primary/75 font-bold text-xl sm:text-2xl md:text-3xl lg:text-4xl sm:text-center mb-8 sm:mb-16 uppercase">
            Ich möchte einen Betriebsrentenantrag einreichen
          </h1>

          <Alertbox />
          <div class="status-label mb-8" v-if="setStatusClass === 'modified'">
            <span class="bearbeitung bg-yellow-400 text-tertiary inline-block py-1 px-2 text-sm uppercase mb-2">
              Entwurf
            </span>
            <span class="infotext text-tertiary text-sm block">
              Dies ist ein Entwurf. Bitte bestätigen Sie diesen
              Änderungsauftrag, indem Sie auf
              <strong>Beauftragen</strong>
              klicken.
            </span>
          </div>
          <form id="custom-form" :class="setStatusClass">
            <div class="">
              <div v-if="sPMNROptions.length > 1">
                <label for="aendPMNR" class="text-sm mb-2 block">
                  PM-NR. für diesen Betriebsrentenantrag.
                  <sup>*</sup>
                </label>
                <CustomSelect id="aendPMNR" @input="setPMNR" class="select" :class="setStatusClassPMNR" required
                  :options="sPMNROptions" :default="store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.PMNR
                    " :oldDefault="store.masterData.olddraft.sService.Entwuerfe.AntragBetriebsrente.PMNR
                      " :tabindex="1" />
              </div>
              <div v-else>
                <label for="fixPMNR" class="text-sm mb-2 block">PM-NR.</label>
                <input id="fixPMNR"
                  class="w-full p-3 border rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 border-secondary/50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500"
                  type="text" v-model="store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.PMNR
                    " disabled />
              </div>
            </div>

            <div class="grid xl:grid-cols-2 sm:gap-5">
              <div>
                <label for="aendTitel" class="text-sm mb-2 block">Titel</label>
                <input maxlength="100" type="text" id="aendNamensvorsatz" :class="setStatusClassTITEL"
                  class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500"
                  v-model="store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.TITEL
                    " disabled />
              </div>
              <div>
                <label for="aendNamensvorsatz" class="text-sm mb-2 block">
                  Vorsatz Name
                </label>
                <input maxlength="100" type="text" id="aendNamensvorsatz" :class="setStatusClassNAMEVS"
                  class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500"
                  v-model="store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.NAMEVS
                    " disabled />
              </div>
            </div>

            <div class="grid xl:grid-cols-2 sm:gap-5">
              <div>
                <label for="aendVorname" class="text-sm mb-2 block">
                  Vorname
                  <sup>*</sup>
                </label>
                <input maxlength="100" type="text" id="aendVorname"
                  class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500"
                  :class="setStatusClassVORNAME" required v-model="store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.VORNAME
                    " disabled />
              </div>
              <div>
                <label for="aendName" class="text-sm mb-2 block">
                  Nachname
                  <sup>*</sup>
                </label>
                <input maxlength="100" type="text" id="aendName"
                  class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500"
                  :class="setStatusClassNACHNAME" required v-model="store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.NAME
                    " disabled />
              </div>
            </div>

            <div class="grid xl:grid-cols-2 sm:gap-5">
              <div>
                <label for="aendNamenszusatz" class="text-sm mb-2 block">
                  Namenszusatz
                </label>
                <input maxlength="100" type="text" id="aendNamenszusatz"
                  class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500"
                  :class="setStatusClassNAMEZUS" v-model="store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.NAMEZUS
                    " disabled />
              </div>
              <div>
                <label for="aendGeburtsdatum" class="text-sm mb-2 block">
                  Geburtsdatum
                  <sup>*</sup>
                </label>
                <DateInput :statusClass="setStatusClassGEBDAT" name="aendGeburtsdatum" placeholder="gültiges Datum"
                  :required="true" :disabled="true" dmax="-6570" dmin="-43800" v-model="store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.GEBDAT
                    " />
              </div>
            </div>

            <div class="grid xl:grid-cols-2 sm:gap-5">
              <div>
                <label for="aendGeschlecht" class="text-sm mb-2 block">
                  Geschlecht
                  <sup>*</sup>
                </label>

                <CustomSelect @input="setGeschlecht" class="select" :class="setStatusClassGESCHL" required
                  :options="sGeschlOptions" :default="store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.GESCHL
                    " :oldDefault="store.masterData.olddraft.sService.Entwuerfe.AntragBetriebsrente.GESCHL
                      " :tabindex="9" disabled />
              </div>
              <div class="relative">
                <label for="coName" class="text-sm mb-2 block">c/o Name</label>
                <input maxlength="100" type="text" id="coName"
                  class="peer w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500"
                  :class="setStatusClassPCONAME" v-model="store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.PCONAM
                    " :disabled="checkPrivatadresseErfasst" :pattern="getNamePattern().source" />

                <span class="invisible peer-invalid:visible text-red-500 text-sm absolute bottom-2 left-0 min-w-max">
                  Der als c/o Name angegebene Wert muss gültig sein.
                </span>
              </div>
            </div>

            <div class="grid xl:grid-cols-1 sm:gap-5">
              <div class="relative">
                <label for="aendStrasse" class="text-sm mb-2 block">
                  Straße / Hausnummer
                  <sup>*</sup>
                </label>
                <input maxlength="100" type="text" id="aendStrasse"
                  class="peer w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500"
                  :class="setStatusClassPSTRASSE" v-model="store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.PSTRASSE
                    " required :disabled="checkPrivatadresseErfasst" :pattern="getStreetAndNumberPattern().source" />
                <span class="invisible peer-invalid:visible text-red-500 text-sm absolute bottom-2 left-0 min-w-max">
                  Straße und Hausnummer sind Pflichtfelder.
                </span>
              </div>
            </div>

            <div class="grid xl:grid-cols-6 sm:gap-5">
              <div class="lg:col-span-1">
                <label for="aendPlz" class="text-sm mb-2 block">
                  PLZ
                  <sup>*</sup>
                </label>
                <input maxlength="100" type="text" id="aendPlz"
                  class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500"
                  :class="setStatusClassPPLZ" v-model="store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.PPLZ
                    " required :disabled="checkPrivatadresseErfasst" />
              </div>
              <div class="lg:col-span-5">
                <label for="aendOrt" class="text-sm mb-2 block">
                  Ort
                  <sup>*</sup>
                </label>
                <input maxlength="100" type="text" id="aendOrt"
                  class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500"
                  :class="setStatusClassPORT" v-model="store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.PORT
                    " required :disabled="checkPrivatadresseErfasst" />
              </div>
            </div>

            <div class="grid xl:grid-cols-1 sm:gap-5">
              <div>
                <label for="aendLand" class="text-sm mb-2 block">
                  Land
                  <sup>*</sup>
                </label>
                <CustomSelect @input="setLand" class="select" :class="setStatusClassPLKZ" required
                  :options="sLandOptions" :default="store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.PLKZ
                    " :oldDefault="store.masterData.olddraft.sService.Entwuerfe.AntragBetriebsrente.PLKZ
                      " :tabindex="14" disabled />
              </div>
            </div>
            <div class="grid xl:grid-cols-1 sm:gap-5">
              <div>
                <label for="aendRVNummer" class="text-sm mb-2 block">
                  Sozial- /Rentenversicherungsnummer
                </label>
                <input maxlength="100" type="text" id="aendRVNummer"
                  class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500"
                  :class="setStatusClassRENTENVERSICHERUNGSNUMMER" v-model="store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente
                    .RENTENVERSICHERUNGSNUMMER
                    " :disabled="store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente
                      .RENTENVERSICHERUNGSNUMMER &&
                      store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente
                        .RENTENVERSICHERUNGSNUMMER.length > 0
                      " />
              </div>
            </div>

            <div class="grid xl:grid-cols-2 sm:gap-5">
              <div>
                <label for="aendBeitragspflicht" class="text-sm mb-2 block">
                  Beitragspflicht
                  <sup>*</sup>
                </label>
                <CustomSelect @input="setBeitragspflicht" class="select" :class="setStatusClassBEITRAGSPFLICHTIG"
                  :keyEmpty="-1" required :options="sBeitragspflichtOptions" :default="store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente
                    .BEITRAGSPFLICHTIG
                    " :oldDefault="store.masterData.olddraft.sService.Entwuerfe.AntragBetriebsrente
                      .BEITRAGSPFLICHTIG
                      " :tabindex="17" :disabled="!(
                      store.masterData.olddraft.sService.Entwuerfe.AntragBetriebsrente
                        .BEITRAGSPFLICHTIG == '-1' ||
                      store.masterData.olddraft.sService.Entwuerfe.AntragBetriebsrente
                        .BEITRAGSPFLICHTIG == ''
                    )
                      " />
              </div>

              <div v-if="checkBeitragspflicht">
                <label class="text-sm mb-2 block">
                  Krankenversicherungsbescheinigung *
                </label>
                <DokumenteHochladen :formId="store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.uuid
                  " :defaultData="store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.uploadskk
                    " maxfiles="1" minfiles="1" infoText="Krankenversicherungsbescheinigung" docArt="KKBesch"
                  @status="docDocStatus" @isOk="docExtraOk" @newfiles="changeExtraFiles" @change="changeExtraFiles" />
              </div>

              <div v-if="!checkBeitragspflicht">
                <label for="aendKrankenkasse" class="text-sm mb-2 block">
                  Krankenkasse
                  <sup>*</sup>
                </label>
                <CustomSelect @input="setKrankenkasse" class="select" :class="setStatusClassKRANKENKASSE" required
                  :options="sKrankenkasseOptions" :keyEmpty="'-1'" :default="store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente
                    .KRANKENKASSE
                    " :oldDefault="store.masterData.olddraft.sService.Entwuerfe.AntragBetriebsrente
                      .KRANKENKASSE
                      " :tabindex="15" :disabled="checkBeitragspflicht" />
              </div>
            </div>

            <div class="grid" v-if="!checkBeitragspflicht">
              <div>
                <label for="aendKilo" class="text-sm mb-2 block">
                  Kinder
                  <sup>*</sup>
                </label>
                <CustomSelect @input="setKilo" class="select" :class="setStatusClassKILO" :keyEmpty="store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente
                  .BEITRAGSPFLICHTIG == 2
                  ? -1
                  : 0
                  " required :options="sKiloOptions" :default="store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.KILO
                    " :oldDefault="store.masterData.olddraft.sService.Entwuerfe.AntragBetriebsrente.KILO
                      " :tabindex="18" />
              </div>
              <div class="grid" v-if="checkKilo">
                <div v-for="(kind, index) in store.masterData.draft.sService.Entwuerfe
                  .AntragBetriebsrente.KINDER" :key="index" class="relative">
                  <div @click="toggleAccordion(index)"
                    class="font-semibold text-lg lg:text-xl trigger py-4 pl-4 pr-8 sm:px-4 first-of-type:bg-gray-100 rounded-md cursor-pointer text-primary relative hover:bg-gray-200 text-left sm:text-center transition-[max-height] flex hyphens-manual items-center justify-between space-x-3 w-full mb-2 duration-500"
                    :aria-expanded="isOpen(index)" :aria-controls="`collapse${index}`" :class="{
                      '!mb-0 rounded-b-none bg-gray-200': isOpen(index),
                    }">
                    <h2 class="text-lg font-bold text-tertiary">
                      <span v-if="kind.VORNAME && kind.NAME">
                        {{ kind.VORNAME + " " + kind.NAME }}
                      </span>
                      <span v-else>Kind {{ index + 1 }}</span>
                    </h2>

                    <div
                      class="toggle relative before:h-1 after:transition-all after:duration-500 before:w-6 before:-right-4 before:bg-primary before:absolute -top-0.5 sm:before:right-0 after:h-1 after:w-6 after:bg-primary after:absolute after:-right-4 sm:after:right-0 after:-rotate-90 before:rounded after:rounded">
                    </div>
                  </div>
                  <div :id="`collapse${index}`"
                    class="toggle max-h-0 opacity-0 transition-all duration-500 overflow-hidden [&button]:bg-white px-8"
                    :class="{
                      'max-h-[2000px] !opacity-100 rounded-b-md mb-2 p-8 bg-gray-50':
                        isOpen(index),
                    }">
                    <div class="kind grid grid-cols-2 gap-8">
                      <div class="relative" :class="{ modified: kind.VORNAME }">
                        <label :for="'aendVorname' + index" class="text-sm mb-2 block">
                          Vorname
                          <sup>*</sup>
                        </label>
                        <input type="text" maxlength="100" :id="'aendVorname' + index" v-model="kind.VORNAME" required
                          class="peer w-full p-3 border border-secondary/50 rounded-md invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500"
                          :pattern="getNamePattern().source" :disabled="kind?.vonPensus" />
                        <span
                          class="invisible peer-invalid:visible text-red-500 text-sm absolute -bottom-6 left-0 min-w-max">
                          Geben Sie einen gültigen Vornamen ein.
                        </span>
                      </div>
                      <div class="relative" :class="{ modified: kind.NAME }">
                        <label :for="'aendName' + index" class="text-sm mb-2 block">
                          Name
                          <sup>*</sup>
                        </label>
                        <input type="text" maxlength="100" :id="'aendName' + index" v-model="kind.NAME" required
                          class="peer w-full p-3 border border-secondary/50 rounded-md invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500"
                          :pattern="getNamePattern().source" :disabled="kind?.vonPensus" />
                        <span
                          class="invisible peer-invalid:visible text-red-500 text-sm absolute -bottom-6 left-0 min-w-max">
                          Geben Sie einen gültigen Namen ein.
                        </span>
                      </div>
                      <div>
                        <label :for="'aendGebdat' + index" class="text-sm mb-2 block">
                          Geburtsdatum
                          <sup>*</sup>
                        </label>
                        <DateInput :name="'aendGebdat' + index" placeholder="gültiges Datum" :required="true"
                          :disabled="kind?.vonPensus" dmin="-36500" dmax="-0" v-model="kind.GEBDAT"
                          :class="{ modified: kind.GEBDAT }" />
                      </div>
                    </div>
                    <div v-if="kind.uploadFolgt == '0'">
                      <label class="text-sm mb-2 block mt-4">
                        Geburtsurkunde von
                        <span v-if="kind.VORNAME && kind.NAME">
                          {{ kind.VORNAME + " " + kind.NAME }}
                        </span>
                        <span v-else>Kind {{ index + 1 }}</span>
                      </label>
                      <DokumenteHochladen :formId="store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente
                        .uuid
                        " :defaultData="kind.uploads" maxfiles="1" :minfiles="kind.uploadFolgt == '1' ? 0 : 1"
                        infoText="aus dem Self Service Betriebsrente" :docArt="'Geburtsurkunde_' + index" :lfn="index"
                        @status="docDocStatus" @isOk="docKindOk" @newfiles="changeFiles" @change="changeFiles" />
                    </div>
                    <input type="hidden" :id="'uuid' + index" name="uuid" value="this.$servertalk.getUUID()" />
                    <div class="flex items-center gap-2 -mt-4" v-if="!kindUploaded(kind)"
                      :class="kind?.vonPensus ? 'hidden' : ''">
                      <input type="checkbox" :id="'aendUploadFolgt' + index" v-model="kind.uploadFolgt" true-value="1"
                        false-value="0"
                        class="border border-secondary/50 rounded-md invalid:border-red-500 focus:outline-0 w-5 h-5 text-tertiary appearance-none bg-white cursor-pointer"
                        :disabled="kindUploaded(kind)" />
                      <label :for="'aendUploadFolgt' + index" class="text-base cursor-pointer">
                        Das Dokument wird nachgereicht
                      </label>
                    </div>
                    <div class="inline-flex justify-between w-full">
                      <input :id="'buttonRemoveKind' + index" type="button" value="Kind löschen"
                        :disabled="kind?.vonPensus"
                        class="bg-primary text-white py-4 w-64 rounded-md mt-8 text-base lg:text-xl hover:bg-tertiary transition disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-primary cursor-pointer mx-auto"
                        @click="removeKind(index)" />
                    </div>
                  </div>
                </div>
                <input id="buttonAddKind" type="button" value="Kind hinzufügen"
                  class="bg-primary text-white py-4 w-64 rounded-md text-base lg:text-xl mx-auto block hover:bg-tertiary transition disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-primary cursor-pointer my-8"
                  @click="addKind()" />
              </div>
            </div>
            <div class="grid xl:grid-cols-1 sm:gap-5">
              <div>
                <label for="aendBankname" class="text-sm mb-2 block">
                  Bankname
                  <sup>*</sup>
                </label>
                <input maxlength="100" type="text" id="aendBankname" :class="setStatusClassPRB_BNAME" required
                  class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500"
                  :disabled="bankDataDisabled" v-model="store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.PRB_BNAME
                    " />
              </div>
            </div>

            <div class="grid xl:grid-cols-2 sm:gap-5">
              <div class="relative">
                <label for="aendIban" class="text-sm mb-2 block">
                  IBAN
                  <sup>*</sup>
                </label>
                <input maxlength="100" type="text" id="aendIban" :class="setStatusClassPRB_IBAN"
                  class="w-full p-3 border rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 border-secondary/50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500"
                  @input="validiereIBAN" :pattern="validIBAN ? '.*' : 'invalid'" v-model="store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.PRB_IBAN
                    " :disabled="checkPrivatbankErfasst" />
                <span class="text-red-500 text-sm absolute left-0 bottom-2" v-if="!validIBAN">
                  Bitte geben Sie eine gültige IBAN ein.
                </span>
              </div>
              <div>
                <label for="aendBic" class="text-sm mb-2 block">
                  BIC
                  <sup>*</sup>
                </label>
                <input maxlength="100" type="text" id="aendBic" required
                  class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500"
                  :class="setStatusClassPRB_BIC" :disabled="bankDataDisabled" v-model="store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.PRB_BIC
                    " />
              </div>
            </div>

            <div class="grid xl:grid-cols-1 sm:gap-5">
              <div class="relative">
                <label for="aendAbwname" class="text-sm mb-2 block">
                  Name des abweichenden Kontoinhabers
                </label>
                <input maxlength="100" type="text" id="aendAbwname" :class="setStatusClassPRB_ABWNAME"
                  class="peer w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500"
                  v-model="store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente
                    .PRB_ABWNAME
                    " :disabled="checkPrivatbankErfasst" :pattern="getNamePattern().source" />
                <span class="invisible peer-invalid:visible text-red-500 text-sm absolute bottom-2 left-0 min-w-max">
                  Der angegebene Name muss gültig sein.
                </span>
              </div>
            </div>
            <div class="">
              <div class="relative">
                <label for="aendSteuerIDNr" class="text-sm mb-2 block">
                  Steuer-ID
                </label>
                <input maxlength="255" type="text" id="aendSteuerIDNr"
                  class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500"
                  :class="setStatusClassSTEUERIDNR" v-model="store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente
                    .STEUERIDNR
                    " inputmode="numeric" @input="validiereSteuerid" :pattern="validSteuerid ? '.*' : 'invalid'"
                  :disabled="store.masterData.olddraft.sService.Entwuerfe.AntragBetriebsrente
                    .STEUERIDNR.length > 0
                    " />
                <span class="text-red-500 text-sm absolute left-0 bottom-2" v-if="!validSteuerid">
                  Bitte geben Sie eine gültige Steuer-ID ein.
                </span>
              </div>
            </div>

            <div>
              <label for="aendArbeitgeber" class="text-sm mb-2 block">
                Arbeitgeber
              </label>
              <input maxlength="100" type="text" id="aendArbeitgeber" :class="setStatusClassARBEITGEBER"
                class="w-full p-3 border rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 border-secondary/50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500"
                v-model="store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.ARBEITGEBER
                  " />
            </div>

            <div class="grid xl:grid-cols-2 sm:gap-5">
              <div>
                <label for="aendPensart" class="text-sm mb-2 block">
                  Rentenart
                  <sup>*</sup>
                </label>
                <CustomSelect @input="setPensionsart" class="select" :class="setStatusClassPENSART" required
                  :options="sPensartOptions" :default="store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.PENSART
                    " :oldDefault="store.masterData.olddraft.sService.Entwuerfe.AntragBetriebsrente
                      .PENSART
                      " :tabindex="23" />
              </div>

              <div>
                <label for="aendRentenbeginn" class="text-sm mb-2 block">
                  Rentenbeginn
                  <sup>*</sup>
                </label>
                <DateInput :statusClass="setStatusClassRENTENBEGINN" name="aendRentenbeginn1"
                  placeholder="gültiges Datum" :required="true" dmin="-1095" dmax="+182" v-model="store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente
                    .RENTENBEGINN
                    " />
              </div>
            </div>

            <div>
              <label for="aendBemerkungen" class="text-sm mb-2 block">
                Bemerkungen
              </label>
              <textarea
                class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 focus:border-tertiary/50 peer invalid:text-red-500 focus:invalid:border-red-500"
                :class="setStatusClassBEMERKUNGEN" rows="5" v-model="store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.BEMERKUNGEN
                  "></textarea>
            </div>
            <div>
              <label class="text-sm mb-2 block">Dokumente</label>
              <!-- -->
              <div class="text-sm text-primary p-4 bg-red-50 mb-2 rounded-md" v-if="DokBetrRent">
                <strong>Folgende Dokumente werden benötigt:</strong>
                <br />
                <span v-html="sDekBetrRent"></span>
              </div>
              <!-- -->
              <DokumenteHochladen :formId="store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente.uuid
                " :defaultData="store.masterData.draft.sService.Entwuerfe.AntragBetriebsrente
                  .uploadsDokumente
                  " maxfiles="5" minfiles="0" infoText="aus dem Self Service Betriebsrente" docArt="Docs"
                @status="docDocStatus" @isOk="docExtraOk" @newfiles="changeExtraFiles" @change="changeExtraFiles" />
            </div>

            <div class="button-wrapper flex justify-center gap-4 [&>button]:mx-0">
              <ButtonSendDataSelfService :disabled="setStatusClass !== 'modified' ||
                !validPCONAME ||
                !validPSTRASSE() ||
                !validRENTENBEGINN() ||
                !validPRB_ABWNAME ||
                !validKINDER
                " @finished="sendDataFinished" />
              <ButtonResetServiceData :disabled="setStatusClass !== 'modified'" @reset="resetData" />
            </div>
          </form>
          <DatenschutzHinweis />
          <ButtonBack />
        </div>
      </div>
    </Transition>

    <FooterMenu />
  </div>
</template>
