<script>
import draggable from "vuedraggable";
import { store } from "../components/store.vue";
/** Icons für den Schnellzugriff auf der Startseite
 * können dynamisch via Drag'n Drop angeordnet werden.
 * @displayName Schnellzugriff
 */
export default {
  components: {
    draggable,
  },
  methods: {
    getServices(store) {
      //let filtered = JSON.stringify(store.selfService); // Objekt kopieren
      let filtered = {};
      //filtered = JSON.parse(filtered);
      const keys = Object.keys(store.selfService);
      keys.forEach((key) => {
        let e = store.selfService[key];
        if (
          // nötige Ausschlusskriterien für Services
          e.icon.length > 0 && //es muss ein Bild da sein
          (e.conditionHide.indexOf("goLive") < 0 || !store.goLive) && //golive darf nicht geforder sein ODER wir sind NICHT goLive
          (e.conditionHide.indexOf("notAnwaerter") < 0 ||
            this.$servertalk.checkAnwaerter()) && //nur für Anwäerter oder er ist Anwärter
          (e.conditionHide.indexOf("notRentner") < 0 ||
            this.$servertalk.checkRentner()) && //nur für Rentner
          (e.conditionHide.indexOf("notVerbotFirma") < 0 ||
            this.$servertalk.checkVerbotFirma()) && //nur erlaubte Firmen
          e.conditionHide.indexOf(store.satzart) < 0 && // Wenn gesetzt, nicht für person / ansprechpartner
          (e.conditionHide.indexOf("Steuernummer") < 0 ||
            store.satzart !== "person" ||
            store.masterData.data.Stammdaten?.STEUERIDNR.length <= 0) && //keine Steuernummer oder er hat keine
          (e.conditionHide.indexOf("firmaNotSelected") < 0 ||
            store.subData.data?.Firmenstammdaten?.FNAME)
        ) {
          filtered[key] = e;
        }
      });
      return filtered;
    },
    handleList1Change() {
      if (this.list1.length > 4) {
        // Wenn Liste 1 mehr als 4 Elemente enthält, Element entfernen
        this.list1.pop();
      }
      this.$servertalk.doLog(this.list1);
      // Wenn das Element aus Liste 2 in Liste 1 verschoben wurde
    },
    handleList2Change() {},
    /* detectMove: function (evt) {
      this.$servertalk.doLog(evt);
    }, */
    cloneAction(item) {
      this.$servertalk.doLog("cloned", item);
    },
    handleDragEndItem() {
      if (this.originalList === this.futureList) {
        this.movingItem = this[this.futureList][this.originalIndex];
        this.futureItem = this[this.futureList][this.futureIndex];

        if (this.movingItem && this.futureItem) {
          let _list = Object.assign([], this[this.futureList]);
          _list[this.futureIndex] = this.movingItem;
          _list[this.originalIndex] = this.futureItem;
          this[this.futureList] = _list;
        }
      } else {
        this.movingItem = this[this.originalList][this.originalIndex];
        this.futureItem = this[this.futureList][this.futureIndex];

        if (this.movingItem && this.futureItem) {
          let _listFrom = Object.assign([], this[this.originalList]);
          let _listTo = Object.assign([], this[this.futureList]);
          _listTo[this.futureIndex] = this.movingItem;
          _listFrom[this.originalIndex] = this.futureItem;
          this[this.originalList] = _listFrom;
          this[this.futureList] = _listTo;
        }
      }
      document
        .querySelectorAll(".drag-item")
        .forEach(
          (el) =>
            (el.style.cssText = "background:none;border:1px solid transparent;")
        );
      store.masterData.draft.options.SchnellZugriff.list1 = this["list1"];
      store.masterData.draft.options.SchnellZugriff.list2 = this["list2"];
    },
    handleMoveItem(event) {
      document
        .querySelectorAll(".drag-item")
        .forEach(
          (el) =>
            (el.style.cssText = "background:none;border:1px solid transparent;")
        );
      const { index, futureIndex } = event.draggedContext;
      this.originalIndex = index;
      this.futureIndex = futureIndex;
      this.originalList = event.from.getAttribute("data-list");
      this.futureList = event.to.getAttribute("data-list");
      if (this[this.futureList][this.futureIndex]) {
        event.to.children[this.futureIndex].style.cssText =
          "background:rgba(248,250,251,0.5);border:1px solid rgba(255,0,0,1);opacity:0.5;";
      }
      return false; // disable sort
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "liste1",
        disabled: store.masterData.isReadOnly,
        ghostClass: "ghost",
      };
    },
    /* isPerson() {
      return store.satzart === "person";
    }, */
  },
  mounted() {
    //if (typeof store.draft !== "object") store.draft = {};
    if (typeof store.masterData.draft.options !== "object")
      store.masterData.draft.options = {};
    if (typeof store.masterData.draft.options.SchnellZugriff !== "object")
      store.masterData.draft.options.SchnellZugriff = {};
    if (
      typeof store.masterData.draft.options.SchnellZugriff.version !== "string"
    ) {
      store.masterData.draft.options.SchnellZugriff.version = "0.0";
    }
    if (
      store.masterData.draft.options.SchnellZugriff.version <
      store.minVersionSchnellzugriff
    ) {
      store.masterData.draft.options.SchnellZugriff.version =
        store.minVersionSchnellzugriff;
      if (
        typeof store.masterData.draft.options.SchnellZugriff.list1 === "object"
      ) {
        store.masterData.draft.options.SchnellZugriff.list1 = false;
        this.$servertalk.doLog("Löschen Schnellzugriff wegen Versionsänderung");
      }
    }
    if (
      typeof store.masterData.draft.options.SchnellZugriff.list1 === "object"
    ) {
      // Es existiert ein angepasster Schnellzugriff > Berücksichtigen, ob sich Zugriffsberechtigungen verändert haben.
      let list = store.masterData.draft.options.SchnellZugriff.list1;
      const keys = Object.keys(list);
      keys.forEach((key, index) => {
        let name = list[key].name;
        if (
          typeof name !== "string" ||
          ((store.selfService[name].conditionHide.indexOf("goLive") < 0 ||
            !store.goLive) && //golive darf nicht gefordert sein ODER wir sind NICHT goLive
            (store.selfService[name].conditionHide.indexOf("notAnwaerter") <
              0 ||
              this.$servertalk.checkAnwaerter()) && //nur für Anwäerter oder er ist Anwärter
            (store.selfService[name].conditionHide.indexOf("notVerbotFirma") <
              0 ||
              this.$servertalk.checkVerbotFirma()) && //nur erlaubte Firmen
            (store.selfService[name].conditionHide.indexOf("Steuernummer") <
              0 ||
              store.masterData.data.Stammdaten.STEUERIDNR.length <= 0)) //keine Steuernummer oder er hat keine
        ) {
          this.list1.push(list[key]);
        }
      });
      //this.list1 = store.masterData.draft.options.SchnellZugriff.list1;
      if (
        typeof store.masterData.draft.options.SchnellZugriff.list2 === "object"
      ) {
        let list = store.masterData.draft.options.SchnellZugriff.list2;
        const keys = Object.keys(list);
        keys.forEach((key, index) => {
          let name = list[key].name;
          if (
            typeof name !== "string" ||
            ((store.selfService[name].conditionHide.indexOf("goLive") < 0 ||
              !store.goLive) && //golive darf nicht gefordert sein ODER wir sind NICHT goLive
              (store.selfService[name].conditionHide.indexOf("notAnwaerter") <
                0 ||
                this.$servertalk.checkAnwaerter()) && //nur für Anwäerter oder er ist Anwärter
              (store.selfService[name].conditionHide.indexOf("notVerbotFirma") <
                0 ||
                this.$servertalk.checkVerbotFirma()) && //nur erlaubte Firmen
              (store.selfService[name].conditionHide.indexOf("Steuernummer") <
                0 ||
                store.masterData.data.Stammdaten.STEUERIDNR.length <= 0)) //keine Steuernummer oder er hat keine
          ) {
            this.list2.push(list[key]);
          }
        });
      }
      //this.list2 = store.masterData.draft.options.SchnellZugriff.list2;
    } else {
      let idx = 1;
      let filtered = this.getServices(store);
      const keys = Object.keys(filtered);
      keys.forEach((key, index) => {
        let listcurr = idx <= 4 ? this.list1 : this.list2;
        listcurr.push({
          id: idx++,
          name: key,
          icon: filtered[key].icon,
          text: filtered[key].title,
          url: filtered[key].url,
          isFirma: filtered[key].conditionHide.includes("firmaNotSelected")
            ? true
            : false,
        });
      });
    }
  },
  data() {
    return {
      drag: false,
      showList2: false,
      store,
      liste1: [],
      list1: [],
      list2: [],
    };
  },
  watch: {
    "this.list1": {
      handler(newData, oldData) {
        this.$servertalk.doLog("liste1");
        this.$servertalk.doLog(liste1);
        store.masterData.draft.options.SchnellZugriff.list1 = this.list1;
      },
      deep: true,
    },
    "this.list2": {
      handler(newData, oldData) {
        this.$servertalk.doLog("liste2");
        this.$servertalk.doLog(liste2);
        store.masterData.draft.options.SchnellZugriff.list2 = this.list2;
      },
      deep: true,
    },
  },
};
</script>
<template>
  <div
    class="schnellzugriff-section bg-slate-50 py-8 lg:pt-16 px-5 xl:px-0 border-b border-primary">
    <div class="max-w-7xl mx-auto">
      <h2
        class="text-2xl lg:text-4xl font-bold text-tertiary mb-4 text-center lg:text-left">
        Mein Schnellzugriff
      </h2>
      <p class="text-base lg:text-xl" v-if="this.list2.length > 0">
        Um Ihren persönlichen Schnellzugriff zusammenzustellen klicken Sie auf
        das
        <strong>Plus-Symbol</strong>
        und ziehen die kleinen Icons auf die großen
        <span class="inline lg:hidden">in den oberen 2 Reihen.</span>
        <span class="hidden lg:inline">in der oberen Reihe.</span>
        Ihre Auswahl bleibt für Sie auch beim nächsten einloggen erhalten.
      </p>
      <div class="listenwrapper mt-8 lg:mt-16">
        <draggable
          v-model="list1"
          :group="{ liste1, pull: true, put: true }"
          v-bind="dragOptions"
          @change="handleList1Change"
          class="grid grid-cols-2 lg:inline-flex w-full gap-8 lg:gap-x-8 pb-8 quick"
          item-key="id"
          :move="handleMoveItem"
          @end="handleDragEndItem"
          data-list="list1"
          :component-data="{ name: 'flip-list', type: 'transition' }"
          :animation="500">
          <template #item="{ element }">
            <router-link
              :to="'/' + element.url"
              v-if="
                (!element.isFirma && !store.masterData.isReadOnly) ||
                (element.isFirma &&
                  !store.subData.isReadOnly &&
                  store.selectedFirma?.VERMITTLER != 1)
              "
              class="hover:scale-105 transition-all duration-500 group drag-item w-full">
              <div
                class="grid place-content-center w-24 rounded-full h-24 bg-white shadow-lg mb-4 mx-auto group-hover:shadow-xl transition-all duration-500">
                <span
                  class="icon fill-primary [&>svg]:w-12 [&>svg]:h-12"
                  v-html="element.icon"></span>
              </div>
              <span
                class="text font-bold text-sm lg:text-lg text-tertiary block text-center">
                {{ element.text }}
              </span>
            </router-link>
            <a v-else class="w-full opacity-50">
              <div
                class="grid place-content-center w-24 rounded-full h-24 bg-white shadow-lg mb-4 mx-auto group-hover:shadow-xl transition-all duration-500">
                <span
                  class="icon fill-primary [&>svg]:w-12 [&>svg]:h-12"
                  v-html="element.icon"></span>
              </div>
              <span
                class="text font-bold text-sm lg:text-lg text-tertiary block text-center">
                {{ element.text }}
              </span>
            </a>
          </template>
        </draggable>

        <draggable
          v-if="this.list2.length > 0"
          v-model="list2"
          :group="{ name: 'liste1', pull: true, put: true }"
          v-bind="dragOptions"
          @change="handleList2Change"
          class="grid grid-cols-2 lg:grid-cols-4 gap-8 pb-8 max-h-0 overflow-hidden opacity-0 transition-all duration-1000"
          :class="{ 'max-h-[999px] overflow-visible opacity-100': showList2 }"
          item-key="id"
          :clone="cloneAction()"
          :move="handleMoveItem"
          @end="handleDragEndItem"
          data-list="list2">
          <template #item="{ element }">
            <router-link
              :to="'/' + element.url"
              v-if="
                (!element.isFirma && !store.masterData.isReadOnly) ||
                (element.isFirma &&
                  !store.subData.isReadOnly &&
                  store.selectedFirma?.VERMITTLER != 1)
              "
              class="hover:scale-105 transition-all duration-500 group drag-item w-full origin-center">
              <div
                class="grid place-items-center rounded-full w-20 h-20 bg-white shadow-lg mb-4 mx-auto group-hover:shadow-xl transition-all duration-500">
                <span
                  class="icon fill-primary [&>svg]:w-8 [&>svg]:h-8"
                  v-html="element.icon"></span>
              </div>
              <span
                class="text text font-bold text-base lg:text-base text-tertiary block text-center">
                {{ element.text }}
              </span>
            </router-link>
            <a v-else class="w-full opacity-50">
              <div
                class="grid place-content-center w-24 rounded-full h-24 bg-white shadow-lg mb-4 mx-auto group-hover:shadow-xl transition-all duration-500">
                <span
                  class="icon fill-primary [&>svg]:w-12 [&>svg]:h-12"
                  v-html="element.icon"></span>
              </div>
              <span
                class="text font-bold text-sm lg:text-lg text-tertiary block text-center">
                {{ element.text }}
              </span>
            </a>
          </template>
        </draggable>
        <div
          v-if="this.list2.length > 0"
          class="list-toggle flex justify-center items-center cursor-pointer mb-8 w-16 h-16 border-2 border-primary rounded-full relative mx-auto bg-white/50 group/list-toggle transition-all duration-500 delay-500"
          :class="{
            'hover:w-16 mt-8': showList2,
            'lg:hover:w-[500px] -mt-8': !showList2,
          }"
          @click="showList2 = !showList2">
          <span
            class="before:absolute before:left-[14px] before:top-7 before:rounded-md before:h-1 before:bg-primary before:w-8 before:block after:absolute after:left-[14px] after:top-7 after:rounded-md after:h-1 after:bg-primary after:w-8 after:block after:transition-all after:duration-1000"
            :class="{
              'after:-rotate-180': showList2,
              'after:-rotate-90': !showList2,
            }"></span>
          <span
            class="hidden lg:block overflow-hidden text-base lg:text-lg opacity-0 h-0 transition-all duration-500 w-max group-hover/list-toggle:opacity-100 group-hover/list-toggle:overflow-visible group-hover/list-toggle:h-auto pl-4 group-hover/list-toggle:transition-all group-hover/list-toggle:duration-500 group-hover/list-toggle:delay-1000"
            :class="{ '!text-transparent': showList2 }">
            Wählen Sie
            <strong>hier</strong>
            Ihre relevanten Themen
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.button {
  margin-top: 35px;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.list-group {
  min-height: 20px;
}
</style>
