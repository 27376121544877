<template>
  <div id="password" class="relative z-0 mb-12 w-full group bg-gray-100">
    <input
      maxlength="255"
      :type="type"
      name="floating_password"
      id="floating_password"
      class="w-full p-2 border border-secondary/50 rounded-md focus:border-tertiary/50 peer outline-0"
      placeholder=" "
      required
      v-model="this.passwd"
      @change="validatePassword"
      @keyup="validatePassword"
    />

    <button
      data-tooltip-style="light"
      type="button"
      data-tooltip-target="tooltipContentOld"
      data-tooltip-placement="bottom"
      id="tooltipButtonOld"
      class="tooltipButtonOld mx-auto absolute top-2 right-4"
      @click="
        showPassword();
        toggle = !toggle;
      "
      :disabled="store.masterData.isReadOnly"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        v-if="toggle"
        class="h-6 w-6 stroke-secondary hover:stroke-primary transition-all"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        stroke-width="2"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
        />
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        v-if="!toggle"
        class="h-6 w-6 stroke-secondary hover:stroke-primary transition-all"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        stroke-width="2"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
        />
      </svg>
    </button>
    <div
      id="tooltipContentOld"
      data-tooltip-placement="bottom"
      role="tooltip"
      class="tooltipContentOld absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-gray-900 bg-gray-100 border-primary rounded-lg shadow-sm opacity-0 tooltip text-center w-40"
    >
      {{ btnText }}
      <div id="arrow" data-popper-arrow="true"></div>
    </div>
  </div>
</template>

<script>
import { store } from "./store.vue";
import { Tooltip } from "flowbite";
import { onMounted } from "vue";
/** Komponente zur Eingabe des alten Passwortes im Rahmen einer Passwort-Änderung
 * @displayName Eingabefeld bisheriges Passwort
 */
export default {
  setup() {
    onMounted(() => {
      const $targetElold = document.querySelector(".tooltipContentOld");
      const $triggerElold = document.querySelector(".tooltipButtonOld");

      const options = {
        placement: "bottom",
        triggerType: "hover",
      };
      const tooltipold = new Tooltip($targetElold, $triggerElold, options);
      tooltipold.hide();
    });
  },
  emits: ["passwordChecked"],
  data() {
    return {
      type: "password",
      btnText: "Passwort anzeigen",
      toggle: "true",
      passwd: "",
      store
    };
  },
  mounted() {
    //if ('' !== this.passwd) {
    this.validatePassword();
    //}
  },
  //     pass:this.Password,
  methods: {
    showPassword() {
      if (this.type === "password") {
        this.type = "text";
        this.btnText = "Passwort verstecken";
      } else {
        this.type = "password";
        this.btnText = "Passwort anzeigen";
      }
    },
    validatePassword() {
      if ("" == this.passwd.trim()) {
        this.meldung = "Bitte geben Sie das Passwort an.";
      } else {
        /**
         * Das eingegebene Passwort ist nicht leer
         * @property {string} passwd Erfasstes Passwort
         */
        this.$emit("passwordChecked", this.passwd.trim());
      }
      //window.setTimeout(this.validatePassword,3000);
    },
  },
};
</script>
