<template>
  <div class="main-wrapper" :class="store.accessRestriction">
    <HauptMenue />
    <div class="mt-36 md:mt-48 lg:mt-56 max-w-7xl mx-auto">
      <h1 class="text-primary/75 font-bold text-2xl md:text-3xl lg:text-4xl text-center mb-12 uppercase">
        Postfach
      </h1>

      <div class="max-w-5xl mx-auto mb-24 px-5 lg:px-0">
        <div v-if="store.satzart == 'person' || store.satzart == 'ansprechpartner'" class="mb-8">
          <h2 class="text-primary uppercase font-bold mb-2 text-lg">
            Ihr persönliches Postfach
          </h2>
          <div class="relative rounded-xl overflow-auto" v-if="messages && messages.length > 0">
            <div class="shadow-sm overflow-hidden my-8 bg-gray-50 rounded-md">
              <table class="border-collapse table-auto w-full text-sm transition">
                <thead class="hidden md:table-header-group">
                  <tr>
                    <th class="border-b font-medium p-4 pl-8 pb-3 text-left">
                      Betreff
                    </th>

                    <th class="border-b font-medium p-4 pr-8 pb-3 text-left">
                      Datum
                    </th>
                    <th class="border-b font-medium p-4 pr-8 pb-3 text-left"></th>
                    <th class="border-b font-medium p-4 pr-8 pb-3 text-left"></th>
                  </tr>
                </thead>
                <tbody class="bg-white">
                  <tr v-for="message in getMessages()" :key="message['@attributes'].MSGIDENT"
                    class="cursor-pointer grid md:table-row border-b py-2 relative pl-2 md:pl-0 pr-16 md:pr-0 hover:bg-gray-100"
                    :class="{
                      'disabled:font-bold md:disabled:font-normal':
                        message.MSGGELESEN === '1',
                      'bg-green-50 hover:bg-green-100 border-b-green-500 font-bold':
                        message.MSGGELESEN === '0',
                    }">
                    <td class="md:border-b md:border-slate-100 px-0 md:p-4 md:pl-8 order-2 col-span-2"
                      @click="goToNachricht(message['@attributes'].MSGIDENT)">
                      {{ message.MSGBETREFF }}
                    </td>

                    <td class="md:border-b md:border-slate-100 pt-0 px-0 md:p-4 md:pr-8 md:w-32 order-1" :class="{
                      '': message.MSGGELESEN === '1',
                      'font-bold': message.MSGGELESEN === '0',
                    }" @click="goToNachricht(message['@attributes'].MSGIDENT)">
                      <input v-if="message.MSGDATE && message.MSGDATE.length > 0" type="date" v-model="message.MSGDATE"
                        disabled
                        class="disabled:bg-transparent text-xs md:text-sm rounded-md md:rounded-none md:bg-transparent appearance-none" />
                    </td>

                    <td class="w-8 md:px-4 md:border-b border-slate-100 order-3 span-1 h-0">
                      <button v-if="message.MSGDOKID && message.MSGDOKID.length > 0" @click="
                        getFile(
                          message.MSGDOKID,
                          message['@attributes'].MSGIDENT
                        )
                        ">
                        <svg fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg" aria-hidden="true"
                          class="w-6 h-6 md:w-7 md:h-7 text-tertiary hover:text-primary transition-all absolute md:static right-8 bottom-3">
                          <path stroke-linecap="round" stroke-linejoin="round"
                            d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z">
                          </path>
                        </svg>
                      </button>
                    </td>
                    <td class="w-8 px-4 md:border-b border-slate-100 order-3 h-0">
                      <button v-if="!message.PROTECTED || message.PROTECTED == '0'"
                        @click="deleteMessage(message['@attributes'].MSGIDENT)" :disabled="store.masterData.isReadOnly">
                        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"
                          class="fill-tertiary hover:fill-primary transition-all absolute md:static right-2 bottom-4">
                          <path
                            d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.7 23.7 0 0 0 -21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0 -16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z" />
                        </svg>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div v-else>
            Es befinden sich derzeit keine persönlichen Nachrichten in Ihrem
            Postfach.
          </div>
        </div>
        <div v-if="
          store.subData.data?.Firmenstammdaten?.FNAME &&
          store.satzart !== 'person'
        ">
          <h2 class="text-primary uppercase font-bold mb-2 text-lg">
            Postfach der Firma "{{ store.subData.data.Firmenstammdaten.FNAME }}
            {{
              $servertalk.getName(
                "GESFORM",
                store.subData.data.Firmenstammdaten?.FGESFORM
              )
            }}/{{ store.subData.data.Firmenstammdaten.FKENNR }}/{{
              store.subData.data.Firmenstammdaten.FABRGR
            }}"
          </h2>
          <div class="relative rounded-xl overflow-auto" v-if="famessages && famessages.length > 0">
            <div class="shadow-sm overflow-hidden mb-8 bg-gray-50 rounded-md">
              <table class="border-collapse table-auto w-full text-sm transition">
                <thead class="hidden md:table-header-group">
                  <tr>
                    <th class="border-b font-medium p-4 pl-8 pb-3 text-left">
                      Betreff
                    </th>

                    <th class="border-b font-medium p-4 pr-8 pb-3 text-left">
                      Datum
                    </th>
                    <th class="border-b font-medium p-4 pr-8 pb-3 text-left"></th>
                    <th class="border-b font-medium p-4 pr-8 pb-3 text-left"></th>
                  </tr>
                </thead>
                <tbody class="bg-white">
                  <tr v-for="famessage in getFirmaMessages()" :key="famessage['@attributes'].MSGIDENT"
                    class="cursor-pointer grid md:table-row border-b py-2 relative pl-2 md:pl-0 pr-16 md:pr-0 hover:bg-gray-100"
                    :class="{
                      'disabled:font-bold md:disabled:font-normal':
                        famessage.MSGGELESEN === '1',
                      'bg-green-50 hover:bg-green-100 border-b-green-500 font-bold':
                        famessage.MSGGELESEN === '0',
                    }">
                    <td class="md:border-b md:border-slate-100 px-0 md:p-4 md:pl-8 order-2 col-span-2" @click="
                      goToFirmaNachricht(famessage['@attributes'].MSGIDENT)
                      ">
                      {{ famessage.MSGBETREFF }}
                    </td>

                    <td class="md:border-b md:border-slate-100 pt-0 px-0 md:p-4 md:pr-8 md:w-32 order-1" :class="{
                      '': famessage.MSGGELESEN === '1',
                      'font-bold': famessage.MSGGELESEN === '0',
                    }" @click="
                      goToFirmaNachricht(famessage['@attributes'].MSGIDENT)
                      ">
                      <input v-if="famessage.MSGDATE && famessage.MSGDATE.length > 0" type="date"
                        v-model="famessage.MSGDATE" disabled
                        class="disabled:bg-transparent text-xs md:text-sm rounded-md md:rounded-none md:bg-transparent appearance-none" />
                    </td>

                    <td class="w-8 md:px-4 md:border-b border-slate-100 order-3 span-1 h-0">
                      <button v-if="
                        famessage.MSGDOKID && famessage.MSGDOKID.length > 0
                      " @click="
                        getFirmaFile(
                          famessage.MSGDOKID,
                          famessage['@attributes'].MSGIDENT
                        )
                        ">
                        <svg fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg" aria-hidden="true"
                          class="w-6 h-6 md:w-7 md:h-7 text-tertiary hover:text-primary transition-all absolute md:static right-8 bottom-3">
                          <path stroke-linecap="round" stroke-linejoin="round"
                            d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z">
                          </path>
                        </svg>
                      </button>
                    </td>
                    <td class="w-8 px-4 md:border-b border-slate-100 order-3 h-0">
                      <button v-if="
                        !famessage.PROTECTED || famessage.PROTECTED == '0'
                      " @click="
                        deleteFirmaMessage(famessage['@attributes'].MSGIDENT)
                        " :disabled="store.subData.isReadOnly || (store.selectedFirma?.VERMITTLER == 1)">
                        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"
                          class="fill-tertiary hover:fill-primary transition-all absolute md:static right-2 bottom-4">
                          <path
                            d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.7 23.7 0 0 0 -21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0 -16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z" />
                        </svg>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div v-else>
            Es befinden sich derzeit keine Nachrichten für die Firma in Ihrem
            Postfach.
          </div>
        </div>
      </div>
    </div>
    <FooterMenu />
  </div>
</template>

<script>
import HauptMenue from "../components/HauptMenue.vue";
import FooterMenu from "../components/FooterMenu.vue";
import { store } from "../components/store.vue";
/** View-Komponente zur Anzeige der Übersicht aller Postfach-Einträge
 * @displayName Seite Postfach-Übersicht
 */
export default {
  name: "Postfach",

  components: {
    HauptMenue,
    FooterMenu,
    store,
  },
  props: {
    MSGIDENT: Object,
  },
  data() {
    return {
      store,
      messages:
        typeof store.masterData.data.Message?.MSGIDENT == "undefined" ||
          Array.isArray(store.masterData.data.Message?.MSGIDENT)
          ? store.masterData.data.Message.MSGIDENT
          : [store.masterData.data.Message.MSGIDENT],
      famessages:
        typeof store.subData?.data?.Message?.MSGIDENT == "undefined" ||
          Array.isArray(store.subData?.data?.Message?.MSGIDENT)
          ? store.subData?.data?.Message?.MSGIDENT
          : [store.subData?.data?.Message?.MSGIDENT],
    };
  },

  mounted() {
    window.scrollTo(0, 0);
    window._paq.push(["setDocumentTitle", this.name]);
    window._paq.push(["trackPageView"]);
  },
  computed: {
    sortedMessages() {
      // Implementiere die Sortierung hier, z.B. nach Datum
    },
  },
  methods: {
    getMessages() {
      let unfiltered = JSON.stringify(this.messages); // Objekt kopieren
      unfiltered = JSON.parse(unfiltered);
      let filtered = JSON.parse("{}");
      const keys = Object.keys(unfiltered);
      keys.forEach((key, index) => {
        if (unfiltered[key]["MSGDELETE"] !== "1") {
          filtered[key] = unfiltered[key];
        }
      });
      return filtered;
    },
    getFirmaMessages() {
      let unfiltered = JSON.stringify(this.famessages); // Objekt kopieren
      unfiltered = JSON.parse(unfiltered);
      let filtered = JSON.parse("{}");
      const keys = Object.keys(unfiltered);
      keys.forEach((key, index) => {
        if (unfiltered[key]["MSGDELETE"] !== "1") {
          filtered[key] = unfiltered[key];
        }
      });
      return filtered;
    },
    getFile(docid, msgIdent) {
      // Nachricht indexieren mit der gegebenen MSGIDENT
      const index = this.messages.findIndex(
        (message) =>
          message &&
          message["@attributes"] &&
          message["@attributes"].MSGIDENT === msgIdent
      );
      if (
        index !== -1 &&
        this.messages[index] &&
        !store.masterData.isReadOnly
      ) {
        this.messages[index].MSGGELESEN = "1"; // Nachricht als gelesen markieren, sofern nicht isReadOnly
      }
      this.$servertalk.doLog("db-File " + docid);
      if (docid > "0") {
        let doc = store.masterData.docs.filter(function (p) {
          return p.document.DOKUNR == docid;
        });
        this.$servertalk.doLog(doc);
        if (doc.length > 0) {
          this.$servertalk.getDBFile(doc[0]);
        }
      }
    },
    getFirmaFile(docid, msgIdent) {
      // Nachricht indexieren mit der gegebenen MSGIDENT
      const index = this.famessages.findIndex(
        (famessage) =>
          famessage &&
          famessage["@attributes"] &&
          famessage["@attributes"].MSGIDENT === msgIdent
      );
      if (index !== -1 && this.famessages[index] && !(store.subData.isReadOnly || (store.selectedFirma?.VERMITTLER == 1))) {
        this.famessages[index].MSGGELESEN = "1"; // Nachricht als gelesen markieren, sofern nicht isReadOnly oder Vermittler
      }
      this.$servertalk.doLog("db-File " + docid);
      if (docid > "0") {
        let doc = store.subData.docs.filter(function (p) {
          return p.document.DOKUNR == docid;
        });
        this.$servertalk.doLog(doc);
        if (doc.length > 0) {
          this.$servertalk.getDBFile(doc[0], store.subData);
        }
      }
    },
    goToNachricht(msgIdent) {
      // Nachricht indexieren mit der gegebenen MSGIDENT
      const index = this.messages.findIndex(
        (message) =>
          message &&
          message["@attributes"] &&
          message["@attributes"].MSGIDENT === msgIdent
      );
      if (
        index !== -1 &&
        this.messages[index] &&
        !store.masterData.isReadOnly
      ) {
        this.messages[index].MSGGELESEN = "1"; // Nachricht als gelesen markieren, sofern nicht isReadOnly
      }
      if (
        this.messages[index].MSGNACHRICHT &&
        this.messages[index].MSGNACHRICHT.length > 0
      ) {
        // Nur auf Detailseite bei vorhandenem Nachrichtentext
        store.currMsgIdent = msgIdent;
        this.$router.push({ name: "nachricht-ansehen" });
      } else {
        this.getFile(this.messages[index].MSGDOKID, msgIdent);
      }
    },
    goToFirmaNachricht(msgIdent) {
      // Nachricht indexieren mit der gegebenen MSGIDENT
      const index = this.famessages.findIndex(
        (famessage) =>
          famessage &&
          famessage["@attributes"] &&
          famessage["@attributes"].MSGIDENT === msgIdent
      );
      if (
        index !== -1 &&
        this.famessages[index] &&
        !store.subData.isReadOnly) {
        this.famessages[index].MSGGELESEN = "1"; // Nachricht als gelesen markieren, sofern nicht isReadOnly
      }
      if (
        this.famessages[index].MSGNACHRICHT &&
        this.famessages[index].MSGNACHRICHT.length > 0
      ) {
        // Nur auf Detailseite bei vorhandenem Nachrichtentext
        store.currMsgIdent = msgIdent;
        this.$router.push({ name: "nachricht-ansehen-firma" });
      } else {
        this.getFirmaFile(this.famessages[index].MSGDOKID, msgIdent);
      }
    },
    deleteMessage(msgIdent) {
      // Nachricht indexieren mit der gegebenen MSGIDENT
      const index = this.messages.findIndex(
        (message) =>
          message &&
          message["@attributes"] &&
          message["@attributes"].MSGIDENT === msgIdent
      );

      // Nachricht checken
      if (index !== -1 && this.messages[index]) {
        // Bestätigungsdialog
        const confirmMessage = this.messages[index].MSGDOKID
          ? "Möchten Sie diese Nachricht wirklich löschen? Ihre Dokumente befinden sich weiterhin unter dem Menüpunkt Versorgungsdokumente."
          : "Möchten Sie diese Nachricht wirklich löschen?";

        const confirmDelete = window.confirm(confirmMessage);

        if (confirmDelete) {
          // Nachricht als gelöscht markieren
          this.messages[index].MSGDELETE = "1";
        }
      } else {
        console.error("Nachricht nicht gefunden.");
      }
      this.$servertalk.doLog("storeinhalt:", store);
    },
    deleteFirmaMessage(msgIdent) {
      // Nachricht indexieren mit der gegebenen MSGIDENT
      const index = this.famessages.findIndex(
        (famessage) =>
          famessage &&
          famessage["@attributes"] &&
          famessage["@attributes"].MSGIDENT === msgIdent
      );

      // Nachricht checken
      if (index !== -1 && this.famessages[index]) {
        // Bestätigungsdialog
        const confirmMessage = this.famessages[index].MSGDOKID
          ? "Möchten Sie diese Nachricht wirklich löschen? Ihre Dokumente befinden sich weiterhin unter dem Menüpunkt Versorgungsdokumente."
          : "Möchten Sie diese Nachricht wirklich löschen?";

        const confirmDelete = window.confirm(confirmMessage);

        if (confirmDelete) {
          // Nachricht als gelöscht markieren
          this.famessages[index].MSGDELETE = "1";
        }
      } else {
        console.error("Nachricht nicht gefunden.");
      }
      this.$servertalk.doLog("storeinhalt:", store);
    },
  },
};
</script>
