<script setup>
import HauptMenue from "../../components/HauptMenue.vue";
import FooterMenu from "../../components/FooterMenu.vue";
import DatenschutzHinweis from "../../components/DatenschutzHinweis.vue";
import ButtonSendDataSelfService from "../../components/ButtonSendDataSelfService.vue";
import ButtonResetServiceData from "../../components/ButtonResetServiceData.vue";
import CustomSelect from "../../components/CustomSelect.vue";
import DateInput from "../../components/DateInput.vue";
import DokumenteHochladen from "../../components/DokumenteHochladenService.vue";
import Alertbox from "../../components/Alertbox.vue";
import ButtonBack from "../../components/ButtonBack.vue";
import { store } from "../../components/store.vue";
import {
  getNamePattern,
  getPhoneNumberPattern
} from "../../utility";
import { onMounted } from "vue";
import { Tooltip } from "flowbite";

/** View-Komponente zum Erfassen von Änderungsaufträgen zu Name und Persoenlichedaten
 * @displayName Selfservice Änderung Name und Persoenlichedaten
 */
onMounted(() => {
  // set the tooltip content element
  const $targetEltitel = document.getElementById('tooltipContenttitel');
  const $targetElgebdat = document.getElementById('tooltipContentgebdat');
  // set the element that trigger the tooltip using hover or click
  const $triggerEltitel = document.getElementById("tooltipButtontitel");
  const $triggerElgebdat = document.getElementById("tooltipButtongebdat");

  // options with default values
  const options = {
    placement: "top",
    triggerType: "hover",
  };

  const tooltiptitel = new Tooltip($targetEltitel, $triggerEltitel, options);
  const tooltipgebdat = new Tooltip($targetElgebdat, $triggerElgebdat, options);
  // instance options with default values
  tooltiptitel.hide();
  tooltipgebdat.hide();
});
</script>
<script>
export default {
  name: "SelfServiceAenderungAnsprechpartnerdaten",

  components: {
    // myRouter: router,
    HauptMenue,
    FooterMenu,
    DatenschutzHinweis,
    ButtonSendDataSelfService,
    ButtonResetServiceData,
    CustomSelect,
    DateInput,
    Alertbox,
    ButtonBack,
  },
  data() {
    return {
      store,
      saved: false,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    window._paq.push(["setDocumentTitle", this.name]);
    window._paq.push(["trackPageView"]);
  },
  unmounted() {
    if (this.saved) {
      // Nach Beauftragung Service in Draft komplett löschen
      delete store.masterData.olddraft.sService.Entwuerfe.AenderungAnsprechpartnerdaten;
      delete store.masterData.draft.sService.Entwuerfe.AenderungAnsprechpartnerdaten;
      this.saved = false;
    }
  },
  beforeCreate: function () {
    this.$servertalk.doLog("Create");
    this.$servertalk.doLog("Inhalt store:");
    this.$servertalk.doLog(store);
    let action = this.$servertalk.initSelfService(
      "AenderungAnsprechpartnerdaten",
      '{"APNAME":"","APVORNAME":"","APTITEL":"","APTELEFON":"","APGEBDAT":"","uuid":"","uploadsDokumente":[],"version":"1.0"}',
      "1.0"
    );
    if (action % 2 == 1) {
      // olddraft wurde initialisiert
      let olddraft =
        store.masterData.olddraft.sService.Entwuerfe.AenderungAnsprechpartnerdaten;
      olddraft.APNAME = store.masterData.data.Stammdaten.NAME;
      olddraft.APVORNAME = store.masterData.data.Stammdaten.VORNAME;
      olddraft.APTELEFON = store.masterData.data.Stammdaten.TELEFON;
      olddraft.APTITEL = store.masterData.data.Stammdaten.TITEL;
      olddraft.APGEBDAT = store.masterData.data.Stammdaten.GEBDAT;
      olddraft.uuid = this.$servertalk.getUUID();
    }
    if (action >= 2) {
      // draft wurde initialisiert
      this.$servertalk.copyToDraftSelfService("AenderungAnsprechpartnerdaten");
    }
  },
  methods: {
    infoDocStatus(val) {
      this.$servertalk.doLog("infoDocStatus: ");
      this.$servertalk.doLog(val);
    },
    infoDocOk(val) {
      this.$servertalk.doLog("infoDocOk: " + val.uploadStatus);
    },
    docDocStatus(val) {
      this.$servertalk.doLog("docDocStatus: " + val.uploadStatus);
    },
    docDocOk(val) {
      this.$servertalk.doLog("docDocOk: " + val.uploadStatus);
    },
    changeFiles(val, i) {
      this.$servertalk.doLog("changeFiles");
      this.$servertalk.doLog(val);
      store.masterData.draft.sService.Entwuerfe.AenderungAnsprechpartnerdaten.uploadsDokumente =
        val;
    },
    validVORNAME() {
      const pattern = getNamePattern();
      return store.masterData.draft.sService.Entwuerfe.AenderungAnsprechpartnerdaten.APVORNAME.match(
        pattern
      );
    },
    validNAME() {
      const pattern = getNamePattern();
      return store.masterData.draft.sService.Entwuerfe.AenderungAnsprechpartnerdaten.APNAME.match(
        pattern
      );
    },
    validAPTELEFON() {
      const pattern = getPhoneNumberPattern();
      return (
        store.masterData.draft.sService.Entwuerfe.AenderungAnsprechpartnerdaten.APTELEFON.match(
          pattern
        ) ||
        store.masterData.draft.sService.Entwuerfe.AenderungAnsprechpartnerdaten.APTELEFON === ""
      );
    },
    /* Funktioniert so nicht
    validAPGEBDAT() {
      let gebdat = store.masterData.draft.sService.Entwuerfe.AenderungAnsprechpartnerdaten.APGEBDAT;
      let valid = !isNaN(Date.parse(gebdat));
      if (valid) {
        let currDate = new Date();
        let Difference_In_Time = currDate.getTime() - Date.parse(gebdat);
        let Difference_In_Days = Math.round (Difference_In_Time / (1000 * 3600 * 24));
        valid = 6570 <= Difference_In_Days <= 43800; 
      }
      return valid;
    },
    */
    sendDataFinished() {
      let messageCode = this.$servertalk.sendDataSelfService(
        "AenderungAnsprechpartnerdaten"
      );
      store.message =
        messageCode == 0
          ? {
            text: "Ihre Änderungen im Bereich\n Änderung Ansprechpartnderdaten \n wurden erfolgreich beauftragt.",
            status: "ok",
          }
          : {
            text:
              "Beim Speichern des Auftrags im Bereich\n Änderung Ansprechpartnderdaten \n trat ein Fehler auf (Error-Code " +
              messageCode +
              ").",
            status: "error",
          };
      if (messageCode == 0) this.saved = true;
      this.$router.push("/auftrag-an-pensus");
    },
    resetData() {
      //this.$refs.SelectFamStand.reset(); // Zurücksetzen Select
      let messageCode = this.$servertalk.copyToDraftSelfService(
        "AenderungAnsprechpartnerdaten"
      );
      /*
      this.sFamStand = {
        text: this.$servertalk.getName(
          "FAMSTAND",
          store.masterData.draft.sService.Entwuerfe.AenderungAnsprechpartnerdaten.FAMSTD
        ),
        key: store.masterData.draft.sService.Entwuerfe.AenderungAnsprechpartnerdaten.FAMSTD
      };*/
      store.message =
        messageCode == 0
          ? {
            text: "Ihre Änderungen im Bereich\n Änderung Ansprechpartnerdaten \n wurden zurückgesetzt.",
            status: "ok",
          }
          : {
            text:
              "Beim Zurücksetzen der Daten im Bereich\n Änderung Ansprechpartnerdaten \n trat ein Fehler auf (Error-Code " +
              messageCode +
              ").",
            status: "error",
          };
    },
  },
  computed: {
    setSchwebendClass() {
      // Erhalte den Wert "Privatadresse" von $servertalk
      const aenderung = this.$servertalk.gettAttr(
        "data.Stammdaten"
      );
      //const aenderungPrivatadresseValue = this.$servertalk.gettAttr("subData.draft.sService.Entwuerfe.AenderungKontaktdaten");

      return aenderung;
    },
    setStatusClass() {
      // Erhalte den Wert "AenderungAnsprechpartnerdaten" von $servertalk
      const AenderungAnsprechpartnerdatenValue = this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.AenderungAnsprechpartnerdaten"
      );
      return AenderungAnsprechpartnerdatenValue;
    },
    setStatusClassAPTITEL() {
      // Erhalte den Wert "TITEL" von $servertalk
      return this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.AenderungAnsprechpartnerdaten.APTITEL"
      );
    },

    setStatusClassAPVORNAME() {
      // Erhalte den Wert "APVORNAME" von $servertalk
      return this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.AenderungAnsprechpartnerdaten.APVORNAME"
      );
    },
    setStatusClassAPNACHNAME() {
      // Erhalte den Wert "APNACHNAME" von $servertalk
      return this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.AenderungAnsprechpartnerdaten.APNAME"
      );
    },
    setStatusClassAPTELEFON() {
      // Erhalte den Wert "APTELEFON" von $servertalk
      return this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.AenderungAnsprechpartnerdaten.APTELEFON"
      );
    },
    setStatusClassAPGEBDAT() {
      // Erhalte den Wert "APGEBDAT" von $servertalk
      return this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.AenderungAnsprechpartnerdaten.APGEBDAT"
      );
    },
  },
};
</script>
<template>
  <div class="main-wrapper" :class="store.accessRestriction">
    <HauptMenue />
    <Transition>
      <div class="mt-36 md:mt-48 lg:mt-56 max-w-7xl mx-auto">
        <div class="max-w-5xl mx-auto mb-8 px-5 lg:px-0">
          <h1 class="text-primary/75 font-bold text-xl sm:text-2xl md:text-3xl lg:text-4xl text-center mb-12 uppercase">
            Ich möchte die Änderung meiner Ansprechpartner Daten melden
          </h1>
          <!--modal content-->
          <Alertbox />
          <div class="status-label mb-8" v-if="setStatusClass === 'modified'">
            <span class="bearbeitung bg-yellow-400 text-tertiary inline-block py-1 px-2 text-sm uppercase mb-2">
              Entwurf
            </span>
            <span class="infotext text-tertiary text-sm block">
              Dies ist ein Entwurf. Bitte bestätigen Sie diesen
              Änderungsauftrag, indem Sie auf
              <strong>Beauftragen</strong>
              klicken.
            </span>
          </div>
          <form :class="setStatusClass">
            <div class="grid xl:grid-cols-2 sm:gap-5">
              <div>
                <label for="aendTitel" class="text-sm mb-2 block relative">
                  Titel

                  <span class="absolute top-0.5 right-0 cursor-pointer hover:text-primary transition-all duration-300"
                    id="tooltipButtontitel">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="w-4 h-4">
                      <path fill-rule="evenodd"
                        d="M15 8A7 7 0 1 1 1 8a7 7 0 0 1 14 0Zm-6 3.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM7.293 5.293a1 1 0 1 1 .99 1.667c-.459.134-1.033.566-1.033 1.29v.25a.75.75 0 1 0 1.5 0v-.115a2.5 2.5 0 1 0-2.518-4.153.75.75 0 1 0 1.061 1.06Z"
                        clip-rule="evenodd" />
                    </svg>
                  </span>
                  <div id="tooltipContenttitel" role="tooltip" data-tooltip-placement="top"
                    class="tooltip invisible absolute z-10 inline-block rounded-md text-gray-900 bg-gray-100 px-3 py-2 text-sm font-medium opacity-0 shadow-sm transition-all duration-300">
                    z. B. Dr., Prof. Dr., ...
                    <div class="tooltip-arrow" data-popper-arrow id="arrow"></div>
                  </div>
                </label>
                <input maxlength="100" type="text" id="aendTitel" :class="setStatusClassAPTITEL"
                  class="w-full p-3 border rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 border-secondary/50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500"
                  v-model="store.masterData.draft.sService.Entwuerfe.AenderungAnsprechpartnerdaten
                    .APTITEL
                    " />
              </div>
            </div>
            <div class="grid xl:grid-cols-2 sm:gap-5">
              <div class="relative">
                <label for="aendVorname" class="text-sm mb-2 block">
                  Vorname
                  <sup>*</sup>
                </label>
                <input maxlength="100" type="text" id="aendVorname" :class="setStatusClassAPVORNAME"
                  class="peer w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500"
                  v-model="store.masterData.draft.sService.Entwuerfe.AenderungAnsprechpartnerdaten
                    .APVORNAME
                    " :pattern="getNamePattern().source" />
                <span class="invisible peer-invalid:visible text-red-500 text-sm absolute bottom-2 left-0 min-w-max">
                  Der als Vorname angegebene Wert muss gültig sein.
                </span>
              </div>
              <div class="relative">
                <label for="aendName" class="text-sm mb-2 block">
                  Nachname
                  <sup>*</sup>
                </label>
                <input maxlength="100" type="text" id="aendName" :class="setStatusClassAPNACHNAME"
                  class="peer w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500"
                  v-model="store.masterData.draft.sService.Entwuerfe.AenderungAnsprechpartnerdaten
                    .APNAME
                    " :pattern="getNamePattern().source" />
                <span class="invisible peer-invalid:visible text-red-500 text-sm absolute bottom-2 left-0 min-w-max">
                  Der als Name angegebene Wert muss gültig sein.
                </span>
              </div>
            </div>
            <div class="grid xl:grid-cols-2 sm:gap-5">
              <div class="relative">
                <label for="telefonnummer" class="text-sm mb-2 block">
                  Telefonnummer
                </label>
                <input type="tel" id="telefonnummer"
                  class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500 peer"
                  :class="setStatusClassAPTELEFON" v-model="store.masterData.draft.sService.Entwuerfe.AenderungAnsprechpartnerdaten.APTELEFON
                    " inputmode="numeric" :pattern="getPhoneNumberPattern().source" />
                <span class="invisible peer-invalid:visible text-red-500 text-sm absolute bottom-2 left-0">
                  Bitte tragen Sie hier Ihre Telefonnummer ein.
                </span>
              </div>
              <div>
                <label for="aendGeburtsdatumAP" class="text-sm mb-2 block relative">
                  Geburtsdatum
                  <sup>*</sup>

                  <span class="absolute top-0.5 right-0 cursor-pointer hover:text-primary transition-all duration-300"
                    id="tooltipButtongebdat">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="w-4 h-4">
                      <path fill-rule="evenodd"
                        d="M15 8A7 7 0 1 1 1 8a7 7 0 0 1 14 0Zm-6 3.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM7.293 5.293a1 1 0 1 1 .99 1.667c-.459.134-1.033.566-1.033 1.29v.25a.75.75 0 1 0 1.5 0v-.115a2.5 2.5 0 1 0-2.518-4.153.75.75 0 1 0 1.061 1.06Z"
                        clip-rule="evenodd" />
                    </svg>
                  </span>
                  <div id="tooltipContentgebdat" role="tooltip" data-tooltip-placement="top"
                    class="tooltip invisible absolute z-10 inline-block rounded-md text-gray-900 bg-gray-100 px-3 py-2 text-sm font-medium opacity-0 shadow-sm transition-all duration-300">
                    Das Geburtsdatum kann frei gewählt werden und dient nur dem Anmelden.
                    <div class="tooltip-arrow" data-popper-arrow id="arrow"></div>
                  </div>
                </label>
                <DateInput :statusClass="setStatusClassAPGEBDAT" name="aendGeburtsdatumAP" placeholder="gültiges Datum"
                  :required="true" dmax="-0000" dmin="-45581" v-model="store.masterData.draft.sService.Entwuerfe.AenderungAnsprechpartnerdaten.APGEBDAT
                    " />
              </div>
            </div>

            <div>
              <label class="text-sm mb-2 block">Dokumente</label>
              <DokumenteHochladen :formId="store.masterData.draft.sService.Entwuerfe.AenderungAnsprechpartnerdaten
                .uuid"
                :defaultData="store.masterData.draft.sService.Entwuerfe.AenderungAnsprechpartnerdaten.uploadsDokumente"
                maxfiles="10" minfiles="0" infoText="AenderungAnsprechpartnerData" docArt="Docs" @status="docDocStatus"
                @isOk="docDocOk" @newfiles="changeFiles" @change="changeFiles" />
            </div>

            <div class="button-wrapper flex justify-center gap-4 [&>button]:mx-0">
              <ButtonSendDataSelfService :disabled="setStatusClass !== 'modified' ||
                !validVORNAME() ||
                !validNAME() ||
                !validAPTELEFON()
                " @finished="sendDataFinished" />
              <ButtonResetServiceData :disabled="setStatusClass !== 'modified'" @reset="resetData" />
            </div>
          </form>
          <DatenschutzHinweis />
          <ButtonBack />
        </div>
      </div>
    </Transition>

    <FooterMenu />
  </div>
</template>
