<script setup>
import AppAccordion from "../components/FirmaAccordion.vue";
</script>

<script>
import { store } from "./store.vue";
/** Komponente zur Anzeige einer Übersicht der Firmendokumente im Akkordeon
 * @displayName Anzeige Firmendokumente
 */
export default {
  name: "FirmenDokumente",

  data() {
    return {
      store,
      docs: Array.isArray(store.subData.data.Dokumente.DOKUNR)
        ? store.subData.data.Dokumente.DOKUNR
        : [store.subData.data.Dokumente.DOKUNR],
    };
  },

  computed: {
    groupedDocuments: function () {
      //erzeuge Liste der vorhandenen TypNummern
      let grouped = {};

      this.docs.forEach((doc) => {
        const dokutypnr = doc.DOKUTYPNR;

        if (!grouped[dokutypnr]) {
          grouped[dokutypnr] = [];
        }
        grouped[dokutypnr].push(doc);
      });

      return grouped;
    },
    /* documentsWithoutDokutypnr: function () {
      const result = this.docs.filter((doc) => {
        const isMatch = doc.DOKUTYPNR == null || doc.DOKUTYPNR === "";

        return isMatch;
      });
      return result;
    }, */
  },

  methods: {
    getFirmaFile(doc) {
      const docid = doc["@attributes"].DOKUNR;
      this.$servertalk.doLog("db-File " + docid);
      if (docid > "0") {
        let doc = store.subData.docs.filter(function (p) {
          return p.document.DOKUNR == docid;
        });
        this.$servertalk.doLog(doc);
        if (doc.length > 0) {
          this.$servertalk.getDBFile(doc[0], store.subData);
        }
      }
    },
    docListFilter(dokutypnr) {
      const r = this.docs.filter((doc) => {
        const isMatch = doc.DOKUTYPNR == dokutypnr;
        return isMatch;
      });
      return r;
    },
    getDokutypnrTitle(dokutypnr) {
      //const indextable = store.indextable.TAB[6].KEY;

      const matchingKey = this.$servertalk.getName("DOKUTYP", dokutypnr);

      return matchingKey ? matchingKey : `Unbekannter DOKUTYPNR ${dokutypnr}`;
    },

    openDocument(p) {
      // Hier kannst du die Logik für das Öffnen des Dokuments implementieren
      this.$servertalk.doLog("Dokument öffnen:", p);
      // Zum Beispiel: Öffne ein Modal mit dem ausgewählten Dokument
    },
  },
};
</script>

<template>
  <div v-for="(documents, dokutypnr) in groupedDocuments" :key="dokutypnr">
    <app-accordion :key="dokutypnr">
      <template v-slot:versorgungtitle>
        <div>{{ getDokutypnrTitle(dokutypnr) }}</div>
      </template>

      <template v-slot:versorgungcontent>
        <div>
          <!-- Hier wird ein weiteres Akkordeon für jeden Dokutypnr erstellt -->
          <app-accordion :key="index + '2'">
            <template v-slot:versorgungtitle>
              <div
                v-for="(document, index) in docListFilter(dokutypnr)"
                :key="index + '2'"
                class="third-level w-full">
                <!-- Hier werden die Dokumente für jeden Dokutypnr angezeigt -->
                <!-- Du kannst die Anzeige nach Bedarf anpassen -->
                <div
                  @click="getFirmaFile(document)"
                  class="document-item flex w-full items-center gap-1">
                  <div class="text-lg">
                    {{ document.TEXT }}
                  </div>
                  <span class="text-sm text-tertiary">
                    ({{ document.TYP }})
                  </span>

                  <div class="absolute top-3 right-2 cursor-pointer">
                    <!-- SVG-Icon für das Öffnen des Dokuments -->
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-width="1.5"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      class="w-8 h-8">
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"></path>
                    </svg>
                  </div>
                  <!-- Weitere Inhalte und Aktionen hier einfügen -->
                </div>
              </div>
            </template>
          </app-accordion>
        </div>
      </template>
    </app-accordion>
  </div>
</template>

<style scoped>
.document-item {
  @apply py-4 px-4 bg-gray-50 cursor-pointer font-bold text-primary relative hover:bg-gray-100 transition border-t border-gray-200;
}
</style>
