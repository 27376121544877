<template>
  <div class="fixed-sidebar fixed right-0 bottom-0 z-[9999]">
    <div class="fixed-sidebar-wrapper">
      <div class="fixed-sidebar-content flex flex-col items-center">
        <div
          class="phone-icon h-16 text-white overflow-hidden bg-primary flex transition-all duration-500 items-center px-5 cursor-pointer w-screen sm:w-auto"
          @click="overlay = !overlay">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-10 h-10 inline-block shrink-0"
            role="img"
            alt="Info Button"
            aria-hidden="true">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
          </svg>

          <span
            class="px-3 max-[380px]:text-base text-lg max-[380px]:leading-tight"
            v-if="!overlay">
            Benötigen Sie Hilfe beim Serviceportal?
          </span>
          <span class="px-3 text-lg" v-else>Hilfe hier schließen!</span>
        </div>
      </div>
    </div>
  </div>

  <div class="flex min-[1921px]:h-[960px] masterGlobalWritable relative">
    <form
      class="px-8 pt-8 pb-32 md:p-20 prose max-w-full lg:max-w-4xl"
      :class="{
        nodate: !errorJWTnBirthDay,
        date: errorJWTnBirthDay,
      }">
      <img
        class="mb-16 max-w-[16rem] lg:max-w-sm max-[380px]:mb-8"
        src="/assets/img/pensus-logo.svg"
        alt="Pensus Logo"
        role="img" />
      <h1
        class="max-[380px]:text-xl text-2xl lg:text-4xl font-bold text-secondary max-[380px]:mb-2 mb-4 tracking-wider">
        Willkommen
        <br />
        im Pensus Serviceportal
      </h1>
      <h2
        class="max-[380px]:text-base text-xl lg:text-2xl text-tertiary max-[380px]:mb-4 mb-8 font-light">
        Verwalten Sie mit unserem Serviceportal Ihre betriebliche
        Altersversorgung ganz einfach und bequem digital.
      </h2>
      <div class="max-[380px]:w-72 w-[480px] max-w-full">
        <UserName @mailChecked="mailCheckFinished" />
        <UserPassword @passwordChecked="passwordCheckFinished" />
        <!--<UserBirthday v-if="errorJWTnBirthDay == true" @birthdateChecked="birthdateCheckFinished" />-->
        <CustomDateInput
          v-if="errorJWTnBirthDay == true"
          @birthdateChecked="birthdateCheckFinished" />
        <div class="text-xs py-2 text-primary flex justify-between">
          <router-link
            to="/PasswortVergessen"
            class="block text-primary underline">
            Passwort vergessen
          </router-link>
          <a
            href="https://www.pensus.de/registrierung-portal/"
            target="_blank"
            class="block text-primary underline">
            Registrieren
          </a>
        </div>
        <button
          @click="submitLogin"
          type="submit"
          v-bind:class="sendDisabled ? 'disabled' : ''"
          v-bind:disabled="sendDisabled"
          v-if="!store.isTrackerBlockActive"
          class="disabled:bg-secondary disabled:hover:scale-100 disabled:cursor-not-allowed bg-primary hover:bg-primary hover:scale-105 font-body font-bold text-md w-full py-3 text-center transition-all duration-500 w-100 my-6 tracking-widest text-white rounded-md">
          Anmelden
        </button>
        <div v-else>
          <p>
            Sie können sich
            <b>nicht</b>
            auf dem Portal anmelden, da die Kommunikation zum Portal
            <b>blockiert</b>
            wird. Eventuell verwenden Sie einen Adblocker auf Ihrem Browser.
          </p>
          <p>Bitte geben Sie die Kommunikation zu portal.pensus.de frei.</p>
          <button
            @click.prevent="$servertalk.startPing(true)"
            type="submit"
            class="disabled:bg-secondary disabled:hover:scale-100 disabled:cursor-not-allowed bg-primary hover:bg-primary hover:scale-105 font-body font-bold text-md w-full py-3 text-center transition-all duration-500 w-100 my-6 tracking-widest text-white rounded-md">
            Erneut versuchen
          </button>
        </div>
        <p class="text-red bg-gray-50 py-2 px-4 text-center" v-if="meldung">
          {{ meldung }}
        </p>
      </div>
      <div
        class="absolute left-0 md:bottom-5 flex gap-5 px-8 md:px-20 font-light text-tertiary [&>a]:underline [&>a:hover]:text-primary [&>a]:transition-all [&>a]:duration-500">
        <a href="https://www.pensus.de/impressum/" target="_blank">Impressum</a>
        <a href="https://www.pensus.de/datenschutz/" target="_blank">
          Datenschutz
        </a>
      </div>
    </form>
    <div class="max-[1920px]:min-h-[100svh] hidden lg:block">
      <img
        class="min-h-full object-cover"
        src="/assets/img/pensus-pensionsmanagement-wohlahabende-menschen-yacht-1.1920x1080.jpg"
        alt="Älteres Paar auf Segelboot"
        role="img" />
    </div>
    <div
      class="faq-overlay xl:h-[100svh] bg-white absolute h-0 xl:fixed right-0 w-full z-50 top-0 transition-all duration-1000 mb-32 lg:mb-0"
      :class="{
        'translate-y-full opacity-0 overflow-hidden': !overlay,
        'translate-y-0 opacity-100 h-auto lg:min-h-full xl:min-h-[0px]':
          overlay,
      }">
      <FAQContent />
    </div>
  </div>
</template>

<script>
import UserName from "./UserName.vue";
import UserPassword from "./UserPassword.vue";
import UserBirthday from "./UserBirthday.vue";
import { store } from "./store.vue";
import FAQContent from "./FAQContent.vue";
import CustomDateInput from "./CustomDateInput.vue";
/** Start-Bildschirm für Eingabe von E-Mail/Nutzerkennung, Passwort und Geburtsdatum
 * @displayName Login-Bereich
 */
export default {
  data() {
    return {
      store,
      meldung: "",
      errorEmail: true,
      errorPassword: true,
      errorBirthdate: true,
      lastErr: "",
      overlay: false,
      frustlevel: 0,
    };
  },
  components: {
    UserName,
    UserPassword,
    UserBirthday,
    FAQContent,
    CustomDateInput,
  },
  mounted: function () {
    //let tstore = '';
    this.$servertalk.doLog("LoginPrompt mounted");
    //tstore = localStorage.getItem("store");
    /*
    if (store.myUUID = ''){
      store.myUUID = this.$servertalk.getUUID();
    };
    */

    store.readonly = false;

    //if (tstore !== ''){
    //  this.store = JSON.parse(tstore);
    //  this.$servertalk.doLog(this.store);
    //if (this.$servertalk.restoreStore()) this.$router.push("/startseite");
    //this.$servertalk.doPing();

    //this.$servertalk.startPing();

    //}
  },
  computed: {
    sendDisabled: function () {
      let dDateErr = this.errorJWTnBirthDay && this.errorBirthdate;
      let errJWT = !this.$servertalk.checkJWT("l");

      let err = dDateErr || this.errorEmail || this.errorPassword || errJWT;
      //edDate:false,eJWT:true,err:true,eJWTnBirthDay:true,eBirthDay:false
      if (this.store.isDebug) {
        let _err =
          "edDate:" +
          dDateErr +
          ",eJWT:" +
          errJWT +
          ",err:" +
          err +
          ",eJWTnBirthDay:" +
          this.errorJWTnBirthDay +
          ",eBirthDay:" +
          this.errorBirthdate;
        if (_err != this.lastErr)
          this.$matomo?.trackEvent(err ? "nologin" : "oklogin", _err);
        this.lastErr = _err;
      }

      //this.sendDis = err;
      return err;
    },
    errorJWTnBirthDay: function () {
      return !this.$servertalk.checkJWT("n");
    },
  },
  watch: {
    frustlevel(newData, oldData) {
      if (oldData < 400 && newData >= 400) this.overlay = true;
    },
  },
  methods: {
    submitLogin(e) {
      this.$servertalk.doLog(
        this.$servertalk.calcIdent(store.user, store.passwd)
      );
      store.decrypthash = this.$servertalk.calcIdentCrypt(
        store.user,
        store.passwd
      );
      store.identhash = this.$servertalk.calcIdent(store.user, store.passwd);
      this.$servertalk.doLog(store.identhash);
      this.$servertalk.doLog(store.decrypthash);
      this.$servertalk.getDataFromDB(store.identhash, this.processResult);
      if (window?._paq) {
        window._paq.push(["setUserId", store.identhash.substring(0, 8)]);
      }
      this.$matomo?.trackEvent("login", "try login");
      this.$servertalk.doLog(store.masterData.data);
      this.$servertalk.doLog(store.auth);
      this.meldung = "Anmeldung wird versucht";

      e.preventDefault();
    },
    processResult(status) {
      if (status == 200 && store.auth) {
        //bin angemeldet
        this.$servertalk.loginSystem();

        this.frustlevel = 0;

        if (
          this.$servertalk.gettValue(
            "masterData.data.Stammdaten.IS_PWD_INIT"
          ) == "1" &&
          this.$servertalk.gettValue(
            "masterData.data.Stammdaten.IS_USER_INIT"
          ) == "1"
        ) {
          this.$router.push("/zugangsdaten-aendern/both");
        } else if (
          this.$servertalk.gettValue(
            "masterData.data.Stammdaten.IS_PWD_INIT"
          ) == "1"
        ) {
          this.$router.push("/zugangsdaten-aendern/passwd");
        } else if (
          this.$servertalk.gettValue(
            "masterData.data.Stammdaten.IS_USER_INIT"
          ) == "1"
        ) {
          this.$router.push("/zugangsdaten-aendern/EMail");
        } else this.$router.push("/startseite");
      } else {
        this.meldung = "Die Zugangsdaten sind nicht korrekt.";
        this.$matomo?.trackEvent("login", "failed");
        this.frustlevel = this.frustlevel + 80;
      }
    },
    mailCheckFinished(err) {
      if (err) this.frustlevel = this.frustlevel + 1;
      this.errorEmail = err;

      //this.errorJWTnBirthDay = !this.$servertalk.checkJWT("n");
      //this.sendDisabled();
    },
    passwordCheckFinished(err) {
      if (err) this.frustlevel = this.frustlevel + 1;

      this.errorPassword = err;

      //this.errorJWTnBirthDay = !this.$servertalk.checkJWT("n");
      //this.sendDisabled();
    },
    birthdateCheckFinished(err) {
      if (err) this.frustlevel = this.frustlevel + 1;

      this.errorBirthdate = err;

      //this.errorJWTnBirthDay = !this.$servertalk.checkJWT("n");
      //this.sendDisabled();
    },
  },
};
</script>
