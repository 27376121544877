<script>
import HauptMenue from "../../components/HauptMenue.vue";
import FooterMenu from "../../components/FooterMenu.vue";
import DatenschutzHinweis from "../../components/DatenschutzHinweis.vue";
import DateInput from "../../components/DateInput.vue";
import ButtonSendDataSelfService from "../../components/ButtonSendDataSelfService.vue";
import ButtonBack from "../../components/ButtonBack.vue";
import { store } from "../../components/store.vue";
/** View-Komponente zum Erfassen von Änderungsaufträgen zur Beitragshöhe
 * @displayName Selfservice Änderung Beitrag
 */
export default {
  name: "SelfServiceAenderungBeitrag",

  components: {
    // myRouter: router,
    HauptMenue,
    FooterMenu,
    DatenschutzHinweis,
    ButtonSendDataSelfService,
    DateInput,
    ButtonBack,
    //VersorgungSelect
  },
  data() {
    return {
      store,
      meldung: "",
      gueltigAb:
        store.masterData.data.sService.Entwuerfe.AenderungBeitrag.gueltigAb == ""
          ? new Date().toISOString().slice(0, 10)
          : store.masterData.data.sService.Entwuerfe.AenderungBeitrag.gueltigAb,
    };
  },
  mounted() {
    this.checkForUnsaved();
    window.scrollTo(0, 0);
    window._paq.push(["setDocumentTitle", this.name]);
    window._paq.push(["trackPageView"]);
  },
  beforeCreate: function () {
    this.$servertalk.doLog("Create");
    this.$servertalk.doLog("#");
    if (typeof store.masterData.data.sService === "undefined") {
      store.masterData.data.sService = {};
    }
    if (typeof store.masterData.data.sService.Entwuerfe === "undefined") {
      store.masterData.data.sService.Entwuerfe = {};
    }
    if (typeof store.masterData.data.sService.AenderungenBeitrag === "undefined") {
      store.masterData.data.sService.AenderungenBeitrag = {};
    }
    if (typeof store.masterData.data.sService.Entwuerfe.AenderungBeitrag === "undefined") {
      store.masterData.data.sService.Entwuerfe.AenderungBeitrag = JSON.parse(
        '{"gueltigAb":"","Versicherungsnummer":"","Kennr":"","Abrechnungsgruppe":"","Traegerunternehmen":"","NAME":"","VORNAME":"","BeitragAlt":"","BeitragNeu":"","Grund":"","EMAIL":"","TELEFON":"","PMNR":""}'
      );
      this.$servertalk.doLog("defined");
    }
    store.masterData.data.sService.Entwuerfe.AenderungBeitrag.PMNR =
      store.masterData.data.Versorgung.PMNR;
    store.masterData.data.sService.Entwuerfe.AenderungBeitrag.NAME =
      store.masterData.data.Stammdaten.NAME;
    store.masterData.data.sService.Entwuerfe.AenderungBeitrag.VORNAME =
      store.masterData.data.Stammdaten.VORNAME;
    store.masterData.data.sService.Entwuerfe.AenderungBeitrag.EMAIL =
      store.masterData.data.Stammdaten.EMAIL;
    store.masterData.data.sService.Entwuerfe.AenderungBeitrag.PTELEFON =
      store.masterData.data.Privatadresse.PTELEFON;

    //if (typeof store.masterData.data.sService.Firmenaustritt.gueltigAb ==='undefined'){store.masterData.data.sService.Firmenaustritt.gueltigAb=0}
  },
  methods: {
    sendDataFinished() {
      this.checkForUnsaved();
      //console.log("SendDummy");
      //console.log(store.masterData.data.Entwuerfe);
      //console.log(this.gueltigAb);
    },
    getGueltigAb(d) {
      store.masterData.data.sService.Entwuerfe.AenderungBeitrag.gueltigAb = d;
      this.$servertalk.doLog(d);
    },
    checkForUnsaved() {
      this.inBearbeitung = this.checkSingleChange(
        store.masterData.data.sService.Entwuerfe.AenderungBeitrag,
        store
      );
    },
    checkSingleChange: function (object, store) {
      let changed = false;
      if (typeof object != "undefined") {
        const keysStore = Object.keys(store.masterData.data);
        const keys = Object.keys(object);
        keys.forEach((key) => {
          let foundInStore = false;
          keysStore.forEach((keyStore) => {
            let objectStore = store.masterData.data[keyStore];
            if (typeof objectStore[key] !== "undefined") {
              foundInStore = true;
              if (
                object[key].toString() != objectStore[key].toString() ||
                object[key].toString().length !=
                  objectStore[key].toString().length
              ) {
                this.$servertalk.doLog("changed 1");
                this.$servertalk.doLog(
                  object[key].toString() + " <> " + objectStore[key].toString()
                );
                changed = true;
              }
            }
          });
          if (
            !foundInStore &&
            object[key] !== "undefined" &&
            object[key].length > 0
          ) {
            this.$servertalk.doLog("changed 1");
            this.$servertalk.doLog(object[key]);
            changed = true;
          }
        });
      }
      return changed;
    },
  },
};
</script>
<template>
  <div class="main-wrapper" :class="store.accessRestriction">
    <HauptMenue />
    <Transition>
      <div class="mt-36 md:mt-48 lg:mt-56 max-w-7xl mx-auto">
        <div class="max-w-5xl mx-auto mb-8 px-5 lg:px-0">
          <h1
            class="text-primary/75 font-bold text-xl sm:text-2xl md:text-3xl lg:text-4xl text-center mb-12 uppercase">
            Ich möchte eine Beitragsänderung melden
          </h1>
          <div class="status-label mb-8" v-if="inBearbeitung">
            <span
              class="bearbeitung bg-yellow-500 text-tertiary inline-block py-1 px-2 text-sm uppercase">
              In Bearbeitung
            </span>
            <span class="infotext text-tertiary text-sm ml-5">
              Dieses Dokument befindet sich in Bearbeitung, bitte bestätigen Sie
              Ihre Änderungen.
            </span>
          </div>
          <form>
            <label for="Versorgung" class="text-sm mb-2 block">Firma</label>
            <!--<VersorgungSelect
          name="Versorgung"

          /> -->
            <div class="grid xl:grid-cols-2 sm:gap-5">
              <div>
                <label for="beitrAendBeitragAlt" class="text-sm mb-2 block">
                  Bisheriger Beitrag
                </label>
                <input
                  maxlength="255"
                  type="text"
                  id="beitrAendBeitragAlt"
                  class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 focus:border-tertiary/50 peer invalid:text-red-500 focus:invalid:border-red-500"
                  disabled
                  v-model="
                    store.masterData.data.sService.Entwuerfe.AenderungBeitrag.BEITRAGALT
                  "
                  v-on:keyup="checkForUnsaved" />
              </div>
              <div>
                <label for="beitrAendBeitragNeu" class="text-sm mb-2 block">
                  Zukünftiger Beitrag
                </label>
                <input
                  maxlength="255"
                  type="text"
                  id="beitrAendBeitragNeu"
                  class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 focus:border-tertiary/50 peer invalid:text-red-500 focus:invalid:border-red-500"
                  v-model="
                    store.masterData.data.sService.Entwuerfe.AenderungBeitrag.BEITRAGNEU
                  "
                  v-on:keyup="checkForUnsaved" />
              </div>
            </div>
            <div class="grid xl:grid-cols-2 sm:gap-5">
              <div>
                <label for="beitrAendMail" class="text-sm mb-2 block">
                  E-Mail
                </label>
                <input
                  maxlength="255"
                  type="text"
                  id="beitrAendMail"
                  class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 focus:outline-0 focus:border-tertiary/50 peer invalid:text-red-500 focus:invalid:border-red-500"
                  disabled
                  v-model="store.masterData.data.sService.Entwuerfe.AenderungBeitrag.EMAIL"
                  v-on:keyup="checkForUnsaved" />
              </div>
              <div>
                <label for="beitrAendTelefon" class="text-sm mb-2 block">
                  Telefon
                </label>
                <input
                  maxlength="255"
                  type="text"
                  id="beitrAendTelefon"
                  class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 focus:outline-0 focus:border-tertiary/50 peer invalid:text-red-500 focus:invalid:border-red-500"
                  disabled
                  v-model="
                    store.masterData.data.sService.Entwuerfe.AenderungBeitrag.PTELEFON
                  "
                  v-on:keyup="checkForUnsaved" />
              </div>
            </div>
            <div class="grid xl:grid-cols-2 sm:gap-5">
              <div>
                <label for="beitrAendVorname" class="text-sm mb-2 block">
                  Vorname Versicherungsnehmer
                </label>
                <input
                  maxlength="255"
                  type="text"
                  id="beitrAendVorname"
                  class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 focus:outline-0 focus:border-tertiary/50 peer invalid:text-red-500 focus:invalid:border-red-500"
                  disabled
                  v-model="
                    store.masterData.data.sService.Entwuerfe.AenderungBeitrag.VORNAME
                  "
                  v-on:keyup="checkForUnsaved" />
              </div>
              <div>
                <label for="beitrAendName" class="text-sm mb-2 block">
                  Name Versicherungsnehmer
                </label>
                <input
                  maxlength="255"
                  type="text"
                  id="beitrAendName"
                  class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 focus:outline-0 focus:border-tertiary/50 peer invalid:text-red-500 focus:invalid:border-red-500"
                  disabled
                  v-model="store.masterData.data.sService.Entwuerfe.AenderungBeitrag.NAME"
                  v-on:keyup="checkForUnsaved" />
              </div>
            </div>
            <label for="gueltigAb" class="text-sm mb-2 block">
              Meine Beitragsänderung ist gültig ab
            </label>
            <DateInput
              name="gueltigAb"
              placeholder="gültiges Datum"
              @date="getGueltigAb"
              :default="gueltigAb"
              v-on:click="checkForUnsaved" />
            <label for="gueltigAb" class="text-sm mb-2 block">
              Grund für die Änderung der Beitragshöhe
              <sup>*</sup>
            </label>
            <textarea
              v-model="store.masterData.data.sService.Entwuerfe.AenderungBeitrag.Grund"
              placeholder="Hier eine kurze Erklärung angeben"
              class="w-full p-3 border border-secondary/50 rounded-md mb-8 focus:border-tertiary/50 focus:outline-0 transition-all duration-500"
              required
              v-on:keyup="checkForUnsaved"></textarea>

            <ButtonSendDataSelfService @finished="sendDataFinished" />
            <p>{{ meldung }}</p>
          </form>
          <DatenschutzHinweis />
          <ButtonBack />
        </div>
      </div>
    </Transition>

    <FooterMenu />
  </div>
</template>
