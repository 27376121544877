<script>
import HauptMenue from "../components/HauptMenue.vue";
import FooterMenu from "../components/FooterMenu.vue";
import DatenschutzHinweis from "../components/DatenschutzHinweis.vue";
import { store } from "../components/store.vue";
import { bankDataByIBAN } from "bankdata-germany";
import { isValidIBAN } from "ibantools-germany";
import { friendlyFormatIBAN, electronicFormatIBAN } from "ibantools";
/** View-Komponente zur Anzeige der Bankverbindung
 * @displayName Seite Bankverbindung
 */
export default {
  name: "MeineBankverbindung",
  components: {
    // myRouter: router,
    HauptMenue,
    FooterMenu,
    DatenschutzHinweis,
    bankDataByIBAN,
    isValidIBAN,
  },
  data() {
    return {
      store,
      statusCurrent: "999",
      meldung: "",
      ibanInput: "", // Hier wird die eingegebene IBAN gespeichert
      validIBAN: false, // Hier wird der Validitätsstatus der IBAN gespeichert
      bankData: {
        bankName: "",
        bic: "",
      }, // Hier werden die Bankdaten gespeichert
      ibanPrefix: "", // Hier werden die ersten zwei Stellen der IBAN für das Feld Sitz des Geldinstituts gespeichert
      vorgefuellteIBAN: "", // Hier wird die IBAN aus dem Store gespeichert
      bankDataDisabled: false, // Hier wird der Status der Inputfelder für Bankdaten gespeichert
    };
  },
  mounted() {
    window.scrollTo(0, 0);

    const validiban = isValidIBAN(store.masterData.data.Privatbank.PRB_IBAN.replaceAll(' ', ''));
    const iban = bankDataByIBAN(store.masterData.data.Privatbank.PRB_IBAN);
    const friendlyiban = friendlyFormatIBAN(store.masterData.data.Privatbank.PRB_IBAN);
    const electronicformat = electronicFormatIBAN(
      store.masterData.data.Privatbank.PRB_IBAN
    );
    this.$servertalk.doLog(iban);
    this.$servertalk.doLog(validiban);
    this.$servertalk.doLog(friendlyiban);
    this.$servertalk.doLog(electronicformat);
    if (this.ibanInput === "") {
      this.ibanInput = store.masterData.data.Privatbank.PRB_IBAN; // Hier nehme Ich an, dass die IBAN im Store gespeichert ist
      this.validiereIBAN(); // Führt die Validierung und Aktualisierung der Bankdaten aus
    }
    window._paq.push(["setDocumentTitle", this.name]);
    window._paq.push(["trackPageView"]);
  },
  methods: {
    setMeldung(msg) {
      this.meldung = msg;
    },
    validiereIBAN() {
      // Führt die Validierung und Abfrage der Bankdaten durch
      this.validIBAN = isValidIBAN(this.ibanInput.replaceAll(' ', ''));
      const fetchedBankData = bankDataByIBAN(this.ibanInput);
      // Überprüfe, ob die IBAN mit "DE" beginnt
      if (this.ibanInput.startsWith("DE")) {
        // Wenn ja, aktualisiere die Bankdaten aus dem abgerufenen Objekt
        if (fetchedBankData) {
          this.bankData.bankName = fetchedBankData.bankName;
          this.bankData.bic = fetchedBankData.bic;
          this.bankData.blz = fetchedBankData.blz;
          this.bankDataDisabled = true; // IBAN beginnt mit "DE", daher sind die Inputfelder aktiviert
        }
      } else {
        // Wenn nicht, lösche die Felder bankName und bic
        this.bankData.bankName = "";
        this.bankData.bic = "";
        this.bankDataDisabled = false;
      }
      // Extrahiere die ersten zwei Zeichen der IBAN und speicher sie in der Datenvariable ibanPrefix
      this.ibanPrefix = this.ibanInput.slice(0, 2);
    },
    sendData(e) {
      // Click-Function auf Button
      if (e) e.preventDefault();
      this.statusCurrent = "999";
      this.setMeldung("starte");
      //this.$servertalk.doLog('abc sendData');
      this.$servertalk.sendDataToDB(this.$event, this.setStatus);
    },
    setStatus(status) {
      // Callback serverTalk erhält Status nach asynchroner Verarbeitung
      this.statusCurrent = status;
      //this.$servertalk.doLog('abc setStatus ' + status);
    },
  },
  computed: {
    statusModified: function () {
      return this.statusCurrent;
    },
    setStatusClass() {
      // Erhalte den Wert "Privatbank" von $servertalk
      const privatbankValue = this.$servertalk.gettAttr("masterData.data.Privatbank");

      // Benutze den Wert als Klasse
      return privatbankValue;
    },
    setStatusClassPRB_ABWNAME() {
      // Erhalte den Wert "PRB_ABWNAME" von $servertalk
      return this.$servertalk.gettAttr("masterData.data.Privatbank.PRB_ABWNAME");
    },
  },
  watch: {
    statusModified: function (neuerStatus) {
      //this.$servertalk.doLog('abc statusModified ' + neuerStatus);
      this.statusCurrent = neuerStatus;
    },
    statusCurrent: function (neuerStatus) {
      //this.$servertalk.doLog('abc statusCurrent ' + neuerStatus);
      let meldg = "Die Daten wurden erfolgreich übertragen.";
      if (neuerStatus != 200 && neuerStatus != 999) {
        meldg =
          "Bei der Datenübertragung ist ein Fehler aufgetreten. Status " +
          neuerStatus;
      }
      this.setMeldung(meldg);
      setTimeout(
        function (setMeldung) {
          //this.$servertalk.doLog('time');
          setMeldung("");
        },
        5000,
        this.setMeldung
      );
    },
    vorgefuellteIBAN(newValue) {
      // Überwache Änderungen an der vorgefüllten IBAN im Store
      if (newValue !== this.vorgefuellteIBAN) {
        // Wenn sich die vorgefüllte IBAN ändert, lösche die Bankdaten
        this.bankData.bankName = "";
        this.bankData.bic = "";
      }
    },
  },
};
</script>
<template>
  <div
    class="main-wrapper min-h-screen schwebend"
    :class="store.accessRestriction"
  >
    <HauptMenue />
    <div class="mt-36 md:mt-48 lg:mt-56 max-w-7xl mx-auto">
      <div class="max-w-5xl mx-auto mb-24 px-5 lg:px-0">
        <h1
          class="text-primary/75 font-bold text-2xl md:text-3xl lg:text-4xl text-center mb-12 uppercase"
        >
          Meine Bankverbindung
        </h1>
        <form :class="setStatusClass">
          <label for="bankname" class="text-sm mb-2 block">Bankname</label>
          <input
            maxlength="255"
            type="text"
            id="bankname"
            class="w-full p-3 border border-secondary/50 rounded-md mb-8"
            v-model="store.masterData.data.Privatbank.PRB_BNAME"
            :disabled="bankDataDisabled"
            inputmode="numeric"
            required
          />
          <div class="grid lg:grid-cols-2 gap-x-8">
            <div class="relative">
              <label for="iban" class="text-sm mb-2 block">IBAN</label>
              <input
                maxlength="255"
                type="text"
                id="iban"
                class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:border-tertiary/50 peer invalid:text-red-500 invalid:focus:border-red-500 outline-0"
                required
                v-model="store.masterData.data.Privatbank.PRB_IBAN"
                inputmode="numeric"
                @input="validiereIBAN"
                :pattern="validIBAN ? '.*' : 'invalid'"
              />

              <span
                class="text-red-500 text-sm absolute left-0 bottom-2"
                v-if="ibanInput && !validIBAN"
              >
                Bitte geben Sie eine gültige IBAN ein.
              </span>
            </div>

            <div>
              <label for="bic" class="text-sm mb-2 block">BIC</label>
              <input
                maxlength="255"
                type="text"
                id="bic"
                class="w-full p-3 border border-secondary/50 rounded-md mb-8"
                required
                inputmode="numeric"
                v-model="store.masterData.data.Privatbank.PRB_BIC"
                :disabled="bankDataDisabled"
              />
            </div>
          </div>

          <label for="abwname" class="text-sm mb-2 block">
            Name des abweichenden Kontoinhabers
          </label>
          <input
            maxlength="255"
            type="text"
            id="abwname"
            class="w-full p-3 border border-secondary/50 rounded-md mb-8 outline-0 focus:border-tertiary/50"
            :class="setStatusClass"
            v-model="store.masterData.data.Privatbank.PRB_ABWNAME"
            inputmode="numeric"
          />
          <label for="abwname" class="text-sm mb-2 block">
            Sitz des Geldinstituts
          </label>
          <input
            maxlength="255"
            type="text"
            id="abwname"
            class="w-full p-3 border border-secondary/50 rounded-md mb-8"
            required
            v-model="store.masterData.data.Privatbank.PRB_LAND"
            disabled
          />
          <router-link
            to="/sservice-aenderung-bankverbindung"
            custom
            v-slot="{ navigate }"
            v-if="setStatusClass != 'schwebend'"
          >
            <button
              @click="navigate"
              role="link"
              type="submit"
              :disabled="store.masterData.isReadOnly"
              class="bg-primary text-white py-4 w-64 rounded-md text-lg lg:text-xl mx-auto block hover:bg-tertiary transition disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-primary"
              v-if="this.$servertalk.checkRentner"
            >
              Bankverbindung ändern
            </button>
          </router-link>
          <div v-else>Ihre Bankdaten werden aktuell verarbeitet und können daher nicht geändert werden.</div>
        </form>
      </div>
    </div>

    <FooterMenu />
  </div>
</template>
