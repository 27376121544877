<template>
  <div class="main">
    <div class="dropzone-container" @dragover="dragover" @dragleave="dragleave" @drop="drop">
      <input maxlength="255" type="file" multiple name="file" :id="this.formId + '-' + this.docArt" class="hidden"
        @change="onChange" ref="file" accept=".pdf,.jpg,.jpeg,.png" capture="environment" />

      <label v-if="!isFull" :for="this.formId + '-' + this.docArt"
        class="file-label w-full bg-gray-50 h-24 flex justify-center items-center border border-dashed rounded-md text-xl font-bold mb-8 cursor-pointer"
        :class="{
      'text-primary border-primary bg-primary/5': !filecountOk,
      'border-secondary/50 text-tertiary': filecountOk && !isDragging,
      'border-green-500 bg-green-50 text-green-500':
        isDragging && filecountOk,
    }">
        <div v-if="isDragging">Loslassen um Dokument(e) hochzuladen</div>
        <div v-else>
          <span class="hidden lg:inline">
            Dokument(e) hier hinziehen oder klicken
          </span>
          <span class="inline lg:hidden">Dokumente hier hochladen</span>
        </div>
      </label>

      <div class="preview-container mt-4" v-if="fdata.length" :key="rerenderKey">
        <div v-for="file in fdata" :key="file.name" class="preview-card">
          <div class="flex bg-green-50 rounded-md px-4 py-2 mb-2 justify-between">
            {{ file.name }} -
            {{ Math.round(file.size / 1024) + "kb" }}
            {{ file.uploadStatus }}
            <button
              class="w-6 h-6 ml-2 bg-tertiary text-white rounded-full inline-flex justify-center items-center hover:bg-primary transition duration-500"
              type="button" @click="remove(fdata.indexOf(file))" title="Dokument löschen">
              &times;
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { store } from "../components/store.vue";

/** Upload und Verschlüsselung von Dateien
 *  @displayName Drag and Drop Komponente
 */

export default {
  data() {
    return {
      isDragging: false,
      isFull: false,
      //locale Filedaten
      fdata: [],
      //Filedaten im Store
      storeData: [],
      //formIdFull: this.formId + "-" + this.docArt,
      rerenderKey: 0,
      filecountOk: true,
    };
  },
  beforeCreate: function () { },
  mounted() {
    this.$servertalk.doLog("#mounted");
    /*
    if (!(this.formId in store.uploadFiles.files)) {
      store.uploadFiles.files[this.formId] = {};
    }
    if (!(this.docArt in store.uploadFiles.files[this.formId])) {
      store.uploadFiles.files[this.formId][this.docArt] = [];
      this.fdata = [];
    }
    */
    this.$servertalk.doLog(this.formId + "-" + this.docArt);

    //ggf files aus dem Service holen
    //ist mein defaultData ein Sting, dann parsen
    let p = "";
    /*if (typeof this.defaultData == "string" && this.defaultData > "") {
      let keys = this.defaultData.split(".");
      this.$servertalk.doLog(keys);
      p = store;
      keys.forEach((name) => {
        if (Object.keys(p).length == 0) {
          p.name = {};
        }
        //this.$servertalk.doLog(name);
        //this.$servertalk.doLog(p);
        p = p[name];
      });
    };*/
    this.$servertalk.doLog("this.defaultData");
    this.$servertalk.doLog(this.defaultData);
    if (typeof this.defaultData == "object") {
      this.$servertalk.doLog("defaultData is Object");
      if (Array.isArray(this.defaultData)) {
        this.$servertalk.doLog("defaultData is Array");
        p = this.defaultData;
      } else {
        this.defaultData.uploads = [];
        p = this.defaultData.uploads;
      }
    }

    if (typeof p == "undefined" || p == "") {
      this.$servertalk.doLog(p);
      p = [];
      this.$servertalk.doLog("defaultData kaputt");
    }

    this.storeData = p;
    //Kopie in lokale Daten
    this.fdata = JSON.parse(JSON.stringify(p));

    /*if (typeof p !== "array") {
        //mache ein Array draus
        p.files = [];
      }*/
    this.$servertalk.doLog("-------------");
    this.$servertalk.doLog(p);
    this.$servertalk.doLog(store.masterData.draft);

    //store.uploadFiles.files[this.formId][this.docArt] = p;
    this.$servertalk.doLog(this.fdata);
    this.$servertalk.doLog(p.files);

    //this.fdata = JSON.parse(JSON.stringify(p.files));
    this.$servertalk.doLog("~~~~~~~~~~~~~");
    this.$servertalk.doLog(this.fdata);
  },
  props: {
    /**
     * UUID der Form. Dient der Zuordnung von Files und Form.
     */
    formId: {
      type: String,
      required: true,
    },
    /**
     * ARRAY mit DOCs. Vorbelegung
     */
    defaultData: {
      type: Array,
      required: false,
    },
    /**
     * Langtext zu den Dokumenten
     */
    infoText: {
      type: String,
      default: "Upload",
    },
    /**
     * Kurztexte zu den Dokumenten. Dieser Text unterscheidet Elemente innerhalb eines Formulares!
     */
    docArt: {
      type: String,
      default: "Document",
    },
    /**
     * maximale Anzahl von Dateien. Mehr werden verweigert.
     */
    maxfiles: {
      type: Number,
      default: 1,
    },
    /**
     * minimale Anzahl von Dateien. Weniger werden verweigert.
     */
    minfiles: {
      type: Number,
      default: 0,
    },
  },
  emits: ["change", "status", "isOk", "newfiles"],
  watch: {
    fdata: {
      handler(newData, oldData) {
        //sind alle Uploads durch?
        let isUpload = this.fdata.length > 0;
        this.fdata.forEach((file) => {
          isUpload = isUpload && file.uploadStatus == "hochgeladen";
        });
        this.$servertalk.doLog("-watch fdata isUpload: " + isUpload);
        // nur dann
        if (isUpload) {
          if (typeof this.defaultData == "string" && this.defaultData > "") {
            this.$servertalk.doLog(this.fdata);
            let keys = this.defaultData.split(".");
            let p = store;
            let i = keys.length;
            let lname = "";
            keys.forEach((name) => {
              //this.$servertalk.doLog(name);
              //this.$servertalk.doLog(p);
              if (i > 1) {
                p = p[name];
              }
              if (i == 1) {
                //p[name]=[];
                this.$servertalk.doLog(".............");
                this.$servertalk.doLog(p[name]);
                //hier wird der Datensatz in den STore geschrieben
                p[name] = JSON.parse(
                  JSON.stringify(this.fdata, (key, value) => {
                    if (key == "me" || key == "blob") {
                      return;
                    }
                    return value;
                  })
                );
                this.$servertalk.doLog(this.fdata);
                this.$servertalk.doLog(p[name]);
                lname = name;
                //p[name]=this.fdata;
              }
              //this.$servertalk.doLog(p);
              i--;
            });
            //this.$servertalk.doLog('.--------');
            //this.$servertalk.doLog(store.draft);
            if (
              this.fdata.length >= this.minfiles &&
              this.fdata.length <= this.maxfiles
            ) {
              this.filecountOk = true;
              /**
               * Datei-Upload war erfolgreich
               * ??? Situation? (s.u.)
               * @property {boolean} true
               */
              this.$emit("isOk", true);
              /**
               * Name der betreffenden Datei als Rückgabewert
               * @property {string} p[lname] Dateiname ???
               */
              this.$emit("newfiles", p[lname]);
            }
          } else {
            //Datatoint für direkte übergabe
            //hier wird der Datensatz in den Store geschrieben
            this.$servertalk.doLog("schreibe in store");
            this.storeData = JSON.parse(
              JSON.stringify(this.fdata, (key, value) => {
                if (key == "me" || key == "blob") {
                  return;
                }
                return value;
              })
            );
            this.$servertalk.doLog("+.............");
            this.$servertalk.doLog(this.storeData);
            this.$servertalk.doLog(store.masterData.draft);
            if (
              this.fdata.length >= this.minfiles &&
              this.fdata.length <= this.maxfiles
            ) {
              this.filecountOk = true;
              /**
               * Datei-Upload war erfolgreich
               * ??? Situation? (s.o.)
               * @property {boolean} true
               */
              this.$emit("isOk", true);
              /**
               * Name der betreffenden Datei als Rückgabewert
               * @property {String} storeData Dateiname ???
               */
              this.$emit("newfiles", this.storeData);
            }
          }
        } else {
          this.filecountOk =
            this.fdata.length >= this.minfiles &&
            this.fdata.length <= this.maxfiles;
          /**
           * Datei-Upload war erfolgreich
           * ??? Situation? (s.o.)
           * @property {boolean} filecountOk true/false
           */
          this.$emit("isOk", this.filecountOk);
        }
      },
      deep: true,
    },
  },
  methods: {
    onChange() {
      //console.log(this.$refs.file.files);
      //für alle Files im InputFeld
      this.$refs.file.files.forEach((bfile) => {
        bfile.uploadStatus = "standby";
        this.$servertalk.doLog(bfile.size);
        this.$servertalk.doLog(this.fdata.length);
        this.$servertalk.doLog("MaxFile: " + this.maxfiles);
        this.isFull = !(this.fdata.length + 1 < this.maxfiles);
        if (this.fdata.length + 1 <= this.maxfiles && bfile.size <= 8000000) {
          //geben dem Satz ne hmac

          bfile.hmac = this.$servertalk.getUUID();
          //erzeuge ein reguläres Object
          let _file = {
            blob: bfile,
            name: bfile.name,
            size: String(bfile.size),
            type: bfile.type,
            hmac: bfile.hmac,
            uploadStatus: bfile.uploadStatus,
          };
          //füge den Satz zur List hinzu
          this.fdata.push(_file);
        } else {
          if (this.fdata.length + 1 > this.maxfiles) {
            store.message = {
              text: "nur " + this.maxfiles + " Dateien erlaubt",
              status: "error",
            };
          }
          if (bfile.size > 8000000) {
            store.message = {
              text:
                "Datei " +
                bfile.name +
                " ist zu groß. Die maximale Dateigröße ist 8 Megabyte.",
              status: "error",
            };
          }
        }
      });
      //this.$servertalk.doLog(this.$refs.file.files);
      this.$servertalk.doLog("----------------");
      this.$servertalk.doLog(this.formId + "." + this.docArt);
      this.$servertalk.doLog(this.fdata);
      this.$servertalk.doLog("----------------");
      //this.fdata.push(...this.$refs.file.files);
      //this.$refs.file.filesuploadStatus = 'standby';
      /**
       * Upload einer anderen Datei
       * @property {String} fdata Dateiname ???
       */
      this.$emit("change", this.fdata);

      //hochladen
      //Zähler für die Reihenfolge der Dateien.
      let i = 1;

      //alle Files in der Liste durchgehen
      this.fdata.forEach((file) => {
        this.$servertalk.doLog(file.name);
        this.$servertalk.doLog(file.uploadStatus);

        //nur Files welche aktuell auf Standby stehen
        if (file.uploadStatus == "standby") {
          /**
           * Datei hat Status "standby" Situation ???
           * @property {object} file Datei ???
           */
          this.$emit("status", file);
          //(file.uploadStatus = "starte upload"), (file.me = this);
          file.uploadStatus = "starte upload";
          //this zwischenspeichern. Brauchen wir später, kommen aber anders nicht mehr dran.

          file.me = this;

          //Metablock vorbelegen
          let docmeta = {
            TEXT: file.me.infoText,
            hmac: file.hmac,
            formid: file.me.formId,
            datenart: file.me.docArt,
            filenr: i,
          };
          //file rauschicken
          this.$servertalk.sendDocToDB(
            file,
            docmeta,
            function (file, docmeta, info = "") {
              //in der Callcack Function
              //hier läuft alles asyncron

              //console.log("callback-> " + info);
              //console.log(file);

              if (file) {
                file.uploadStatus = info;
                /*
                 *Versenden der Datei. Situation ???
                 * @property {object} file Datei ???
                 */
                file.me.$emit("status", file);
                file.me.rerenderKey = file.me.rerenderKey + 1;
                //console.log("callback " + file.me.rerenderKey);
                store.masterData.datasent += 1;
                //file.me.trigger();
              }
              if (info == "bereit") {
                store.masterData.datasent = 0;
                let _this = file.me;
                //let defaultData = file.me.defaultData;
                //let _files = file.me.fdata;
                file.me = null;
                file.blob = null;
                delete file.me;
                delete file.blob;
                file.uploadStatus = "hochgeladen";
                //console.log(JSON.stringify(file))
                //console.log(file);

                //console.log("1################");
                //console.log(_this.fdata);
                //console.log(JSON.stringify(_this.fdata));
              }

              //console.log(docmeta.TEXT + " #. " + info);
            }
          );
          //Ende des Async Rausgeschicke
          file.uploadStatus = "bereit";
          /*
           *Versenden der Datei abgeschlossen. Situation ???
           * @property {object} file Datei ???
           */
          this.$emit("status", file);
          //console.log("2################");
          //console.log(this.fdata);
          //console.log(store.draft);
        }
        i++;
      });
    },

    makeName(name) {
      return (
        name.split(".")[0].substring(0, 5) +
        "..." +
        name.split(".")[name.split(".").length - 1]
      );
    },

    remove(i) {
      
      //console.log('#Dokumente ',this.fdata)
      //console.log(this.fdata[i])
      //console.log(JSON.stringify(this.fdata))
      //console.log('index ',i)
      let hmac = this.fdata[i].hmac;
      //console.log('hmac ',hmac)
      //aus der Liste nehmen
      this.fdata.splice(i, 1);
      //console.log(JSON.stringify(this.fdata)) // Dieser Befehl bewirkt einen Fehler > vermutlich stimmt die Datenstruktur nicht (rekursiv?)
      //vom Server löschen

      /**
       * Entfernen einer Datei
       * @property {String} fdata Dateiname ???
       */
      this.$emit("change", this.fdata);
      this.isFull = !(this.fdata.length < this.maxfiles);
      //this.fdata.dbfiles = JSON.parse(JSON.stringify(this.fdata));

      this.$servertalk.delDoc(hmac);
    },

    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },

    dragleave() {
      this.isDragging = false;
    },

    drop(e) {
      e.preventDefault();
      this.$refs.file.files = e.dataTransfer.files;

      this.onChange();
      this.isDragging = false;
      /**
       * Upload einer anderen Datei (drop-Funktion)
       * @property {String} fdata Dateiname ???
       */
      this.$emit("change", this.fdata);
    },
  },
  uploadFiles() {
    const files = this.fdata;
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("selectedFiles", file);
    });
  },
};
</script>
