import { createRouter, createWebHistory } from "vue-router";
import LoginPage from "../views/Login.vue";
import HomePage from "../views/Home.vue";
import VersorgungsDaten from "../views/VersorgungsDaten.vue";
import VersorgungsDokumente from "../views/VersorgungsDokumente.vue";
import Firmendokumente from "../views/Firmendokumente.vue";
import PersoenlicheDaten from "../views/PersoenlicheDaten.vue";
import PersoenlicheDatenAnsprechpartner from "../views/PersoenlicheDatenAnsprechpartner.vue";
import KKDaten from "../views/KKDaten.vue";
import ZugangsdatenAendern from "../views/ZugangsdatenAendern.vue";
import MeineBankverbindung from "../views/MeineBankverbindung.vue";
//import ZugriffsHistorie from '../views/_ZugriffsHistorie.vue'
import KontaktDaten from "../views/KontaktDaten.vue";
import FirmenDaten from "../views/FirmenDaten.vue";
import FirmenAdressdaten from "../views/FirmenAdressdaten.vue";
import FirmenBankdaten from "../views/FirmenBankdaten.vue";
import HilfeServiceportal from "../views/HilfeServiceportal.vue";
import PasswortVergessen from "../views/PasswortVergessen.vue";
import DokumenteHochladen from "../views/DokumenteHochladen.vue";
import AuftragPensus from "../views/AuftragPensus.vue";
import KontaktPensus from "../views/KontaktPensus.vue";
import SelfServiceFirmenaustritt from "../views/SelfService/SelfServiceFirmenaustritt.vue";
import SelfServiceAenderungPersoenlichedaten from "../views/SelfService/SelfServiceAenderungPersoenlichedaten.vue";
import SelfServiceAenderungAnsprechpartnerdaten from "../views/SelfService/SelfServiceAenderungAnsprechpartnerdaten.vue";
import SelfServiceAenderungFirmendaten from "../views/SelfService/SelfServiceAenderungFirmendaten.vue";
import SelfServiceAenderungFirmenAdressdaten from "../views/SelfService/SelfServiceAenderungFirmenAdressdaten.vue";
import SelfServiceAenderungFirmenBankdaten from "../views/SelfService/SelfServiceAenderungFirmenBankdaten.vue";
import SelfServiceErfassungSteuerId from "../views/SelfService/SelfServiceErfassungSteuerId.vue";
import SelfServiceAntragVorausberechnung from "../views/SelfService/SelfServiceAntragVorausberechnung.vue";
import SelfServiceAenderungBeitrag from "../views/SelfService/SelfServiceAenderungBeitrag.vue";
import SelfServiceBeitragsfreistellung from "../views/SelfService/SelfServiceBeitragsfreistellung.vue";
import SelfServiceLeistungsmeldung from "../views/SelfService/SelfServiceLeistungsmeldung.vue";
import SelfServiceWiederinkraftsetzung from "../views/SelfService/SelfServiceWiederinkraftsetzung.vue";
import SelfServiceTeilnahmeBeiratsversammlung from "../views/SelfService/SelfServiceTeilnahmeBeiratsversammlung.vue";
import SelfServiceAenderungKontaktdaten from "../views/SelfService/SelfServiceAenderungKontaktdaten.vue";
import SelfServiceAenderungBankverbindung from "../views/SelfService/SelfServiceAenderungBankverbindung.vue";
//import SelfServiceAntragAuszahlungPm from '../views/SelfService/SelfServiceAntragAuszahlungPm.vue'
import SelfServiceAntragBetriebsrente from "../views/SelfService/SelfServiceAntragBetriebsrente.vue";
import SelfServiceAntragKrankenkassenaenderung from "../views/SelfService/SelfServiceAntragKrankenkassenaenderung.vue";
import Postfach from "../views/Postfach.vue";
import NachrichtAnsehen from "../views/PostfachNachricht.vue";
import NachrichtAnsehenFirma from "../views/PostfachNachrichtFirma.vue";
import FirmenFinanzamtsdaten from "@/views/FirmenFinanzamtsdaten.vue";
import SelfServiceAenderungFirmenFinanzamtsdaten from "@/views/SelfService/SelfServiceAenderungFirmenFinanzamtsdaten.vue";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "login",
      component: LoginPage,
    },
    {
      path: "/startseite",
      name: "mainpage",
      component: HomePage,
    },
    {
      path: "/persoenlichedaten",
      name: "persoenlichedaten",
      component: PersoenlicheDaten,
    },
    {
      path: "/persoenlichedatenansprechpartner",
      name: "persoenlichedatenansprechpartner",
      component: PersoenlicheDatenAnsprechpartner,
    },
    {
      path: "/zugangsdaten-aendern/:open",
      name: "zugangsdatenaendern",
      component: ZugangsdatenAendern,
    },
    {
      path: "/meinebankverbindung",
      name: "meinebankverbindung",
      component: MeineBankverbindung,
    },
    /*
    {
      path: '/zugriffshistorie',
      name: 'zugriffshistorie',
      component: ZugriffsHistorie,
    },
    */
    {
      path: "/kontaktdaten",
      name: "kontaktdaten",
      component: KontaktDaten,
    },
    {
      path: "/stammdaten",
      name: "firmendaten",
      component: FirmenDaten,
    },
    {
      path: "/bankdaten",
      name: "firmenbankdaten",
      component: FirmenBankdaten,
    },
    {
      path: "/firmenadressdaten",
      name: "firmenadressdaten",
      component: FirmenAdressdaten,
    },
    {
      path: "/pensus-faq",
      name: "pensus-faq",
      component: HilfeServiceportal,
    },
    {
      path: "/versorgungsdaten",
      name: "versorgungsdaten",
      component: VersorgungsDaten,
    },
    {
      path: "/versorgungsdokumente",
      name: "versorgungsdokumente",
      component: VersorgungsDokumente,
    },
    {
      path: "/dokumente",
      name: "firmendokumente",
      component: Firmendokumente,
    },
    {
      path: "/krankenkassendaten",
      name: "krankenkassendaten",
      component: KKDaten,
    },
    {
      path: "/passwortVergessen",
      name: "passwortVergessen",
      component: PasswortVergessen,
    },
    {
      path: "/dokumente-hochladen",
      name: "dokumente-hochladen",
      component: DokumenteHochladen,
    },
    {
      path: "/auftrag-an-pensus",
      name: "auftrag-an-pensus",
      component: AuftragPensus,
    },
    {
      path: "/kontaktformular",
      name: "kontaktformular",
      component: KontaktPensus,
    },
    {
      path: "/sservice-firmenaustritt",
      name: "sservice-firmenaustritt",
      component: SelfServiceFirmenaustritt,
    },
    {
      path: "/sservice-aenderung-persoenlichedaten",
      name: "sservice-aenderung-persoenlichedaten",
      component: SelfServiceAenderungPersoenlichedaten,
    },
    ,
    {
      path: "/sservice-aenderung-ansprechpartnerdaten",
      name: "sservice-aenderung-ansprechpartnerdaten",
      component: SelfServiceAenderungAnsprechpartnerdaten,
    },
    {
      path: "/sservice-aenderung-firmendaten",
      name: "sservice-aenderung-firmendaten",
      component: SelfServiceAenderungFirmendaten,
    },
    {
      path: "/sservice-aenderung-firmenadressdaten",
      name: "sservice-aenderung-firmenadressdaten",
      component: SelfServiceAenderungFirmenAdressdaten,
    },
    {
      path: "/sservice-aenderung-firmenbankdaten",
      name: "sservice-aenderung-firmenbankdaten",
      component: SelfServiceAenderungFirmenBankdaten,
    },
    {
      path: "/sservice-aenderung-finanzamtsdaten",
      name: "sservice-aenderung-finanzamtsdaten",
      component: SelfServiceAenderungFirmenFinanzamtsdaten,
    },
    {
      path: "/sservice-erfassung-steuerid",
      name: "sservice-erfassung-steuerid",
      component: SelfServiceErfassungSteuerId,
      //},{
      //  path: '/sservice-auszahlung-pm',
      //  name: 'sservice-auszahlung-pm',
      //  component: SelfServiceAntragAuszahlungPm,
    },
    {
      path: "/sservice-antrag-vorausberechnung",
      name: "sservice-antrag-vorausberechnung",
      component: SelfServiceAntragVorausberechnung,
    },
    {
      path: "/sservice-aenderung-beitrag",
      name: "sservice-aenderung-beitrag",
      component: SelfServiceAenderungBeitrag,
    },
    {
      path: "/sservice-beitragsfreistellung",
      name: "sservice-beitragsfreistellung",
      component: SelfServiceBeitragsfreistellung,
    },
    {
      path: "/sservice-leistungsmeldung",
      name: "sservice-leistungsmeldung",
      component: SelfServiceLeistungsmeldung,
    },
    {
      path: "/sservice-wiederinkraftsetzung",
      name: "sservice-wiederinkraftsetzung",
      component: SelfServiceWiederinkraftsetzung,
    },
    {
      path: "/sservice-teilnahme-beiratsversammlung",
      name: "sservice-teilnahme-beiratsversammlung",
      component: SelfServiceTeilnahmeBeiratsversammlung,
    },
    {
      path: "/sservice-aenderung-kontaktdaten",
      name: "sservice-aenderung-kontaktdaten",
      component: SelfServiceAenderungKontaktdaten,
    },
    {
      path: "/sservice-aenderung-bankverbindung",
      name: "sservice-aenderung-bankverbindung",
      component: SelfServiceAenderungBankverbindung,
    },
    {
      path: "/sservice-antrag-betriebsrente",
      name: "sservice-antrag-betriebsrente",
      component: SelfServiceAntragBetriebsrente,
    },
    {
      path: "/sservice-antrag-aenderungkrankenkasse",
      name: "sservice-antrag-aenderungkrankenkasse",
      component: SelfServiceAntragKrankenkassenaenderung,
    },
    {
      path: "/persoenliches-postfach",
      name: "persoenliche-postfach",
      component: Postfach,
    },
    {
      path: "/nachricht-ansehen",
      name: "nachricht-ansehen",
      component: NachrichtAnsehen,
    },
    {
      path: "/nachricht-ansehen-firma",
      name: "nachricht-ansehen-firma",
      component: NachrichtAnsehenFirma,
    },
    {
      path: "/finanzamtsdaten",
      name: "finanzamtsdaten",
      component: FirmenFinanzamtsdaten,
    },
  ],
});

export default router;
