<template>
    <div @click="toggleAccordion"
        class="sm:font-semibold font-normal text-base py-4 pl-4 pr-12 rounded-md cursor-pointer text-tertiary relative hover:bg-gray-200 text-left transition flex hyphens-manual items-center justify-between w-full duration-500"
        :class="{ 'mb-0 rounded-b-none bg-gray-200': isOpen, 'mb-2 bg-gray-50': !isOpen }">
        {{ title }}
        <div class="toggle translate-x-4 sm:translate-x-8 relative before:h-1 after:transition-all after:duration-500 before:w-6 before:-right-4 before:bg-primary before:absolute -top-0.5 sm:before:right-0 after:h-1 after:w-6 after:bg-primary after:absolute after:-right-4 sm:after:right-0 after:-rotate-90 before:rounded after:rounded"
            :class="{ 'after:rotate-0': isOpen }">
        </div>
    </div>
    <div class="toggle max-h-0 opacity-0 transition-all duration-500 overflow-hidden [&button]:bg-white rounded-b-md  px-4 sm:px-8  lg:px-4 xl:px-8 text-base lg:text-sm xl:text-base"
        :class="{ 'max-h-[2000px] opacity-100 bg-gray-50 py-4 mb-2': isOpen, 'p-0': !isOpen }">
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        isOpen: Boolean,
    },
    emits : ['toggle'],
    methods: {
        toggleAccordion() {
            this.$emit("toggle");
        },
    },
};
</script>