<script setup>
import CustomSelect from "../../components/CustomSelect.vue";
import HauptMenue from "../../components/HauptMenue.vue";
import FooterMenu from "../../components/FooterMenu.vue";
import DatenschutzHinweis from "../../components/DatenschutzHinweis.vue";
import ButtonSendDataSelfService from "../../components/ButtonSendDataSelfService.vue";
import ButtonResetServiceData from "../../components/ButtonResetServiceData.vue";
import Alertbox from "../../components/Alertbox.vue";
import ButtonBack from "../../components/ButtonBack.vue";
import {
  getPostalCodePattern,
  getPhoneNumberPattern,
  getStreetAndNumberPattern,
  getNamePattern,
} from "../../utility";
</script>
<script>
import { store } from "../../components/store.vue";
/** View-Komponente zum Erfassen von Änderungsaufträgen zu den Kontaktdaten
 * @displayName Selfservice Änderung Kontaktdaten
 */
export default {
  name: "SelfServiceAenderungKontaktdaten",
  components: {
    HauptMenue,
    FooterMenu,
    DatenschutzHinweis,
    Alertbox,
    ButtonBack,
  },
  data() {
    return {
      //statusCurrent: "999",
      //meldung: "",
      store,
      sAdresstypOptions: this.$servertalk.getSelectOpts("ADRTYP"),
      sAdresstyp: {
        text: this.$servertalk.getName(
          "ADRTYP",
          store.masterData.data.Privatadresse.PADRTYP
        ),
        key: store.masterData.data.Privatadresse.PADRTYP,
      },
      sLandOptions: this.$servertalk.getSelectOpts("NATIONALITAET"),
      sLand: {
        text: this.$servertalk.getName(
          "NATIONALITAET",
          store.masterData.data.Privatadresse.PLKZ
        ),
        key: store.masterData.data.Privatadresse.PLKZ,
      },
      saved: false,
    };
  },

  mounted() {
    window.scrollTo(0, 0);
    window._paq.push(["setDocumentTitle", this.name]);
    window._paq.push(["trackPageView"]);
  },
  unmounted() {
    if (this.saved) {
      // Nach Beauftragung Service in Draft komplett löschen
      delete store.masterData.olddraft.sService.Entwuerfe.AenderungKontaktdaten;
      delete store.masterData.draft.sService.Entwuerfe.AenderungKontaktdaten;
      this.saved = false;
    }
  },
  beforeCreate: function () {
    this.$servertalk.doLog("Create");
    this.$servertalk.doLog("Inhalt store:");
    this.$servertalk.doLog(store);
    let action = this.$servertalk.initSelfService(
      "AenderungKontaktdaten",
      '{"EMAIL":"","PCONAME":"","PTELEFON":"","PHANDY":"","PADRTYP":"","PSTRASSE":"","PPLZ":"","PORT":"", "PLKZ":"","uuid":"","version":"1.1"}',
      '1.1'
    );
    if (action % 2 == 1) {
      // olddraft wurde initialisiert
      let olddraft = store.masterData.olddraft.sService.Entwuerfe.AenderungKontaktdaten;
      olddraft.EMAIL = store.masterData.data.Privatadresse.EMAIL;
      olddraft.PCONAME = store.masterData.data.Privatadresse.PCONAME;
      olddraft.PTELEFON = store.masterData.data.Privatadresse.PTELEFON;
      olddraft.PHANDY = store.masterData.data.Privatadresse.PHANDY;
      olddraft.PADRTYP = store.masterData.data.Privatadresse.PADRTYP; // Dropdown
      olddraft.PSTRASSE = store.masterData.data.Privatadresse.PSTRASSE;
      olddraft.PPLZ = store.masterData.data.Privatadresse.PPLZ;
      olddraft.PORT = store.masterData.data.Privatadresse.PORT;
      olddraft.PLKZ = store.masterData.data.Privatadresse.PLKZ;
      olddraft.uuid = this.$servertalk.getUUID();
    }
    if (action >= 2) {
      // draft wurde initialisiert
      this.$servertalk.copyToDraftSelfService("AenderungKontaktdaten");
    }
  },
  methods: {
    /*
    setAdresstyp(val) {
      if (val && typeof val === "object" && typeof val.value !== "undefined") {
        store.masterData.draft.sService.Entwuerfe.AenderungKontaktdaten.PADRTYP =
          val.value + "1";
        this.$forceUpdate(); // Erzwingt ein erneutes Rendern der Komponente
      }
    },
    */
    setLand(val) {
      if (val && typeof val === "object" && typeof val.value !== "undefined") {
        store.masterData.draft.sService.Entwuerfe.AenderungKontaktdaten.PLKZ =
          val.value + "";
        //this.$forceUpdate(); // Erzwingt ein erneutes Rendern der Komponente
      }
    },
    validPPLZ() {
      const pattern = getPostalCodePattern();
      return store.masterData.draft.sService.Entwuerfe.AenderungKontaktdaten.PPLZ.match(
        pattern
      );
    },
    validPTELEFON() {
      const pattern = getPhoneNumberPattern();
      return (
        store.masterData.draft.sService.Entwuerfe.AenderungKontaktdaten.PTELEFON.match(
          pattern
        ) ||
        store.masterData.draft.sService.Entwuerfe.AenderungKontaktdaten.PTELEFON === ""
      );
    },
    validPHANDY() {
      const pattern = getPhoneNumberPattern();
      return (
        store.masterData.draft.sService.Entwuerfe.AenderungKontaktdaten.PHANDY.match(
          pattern
        ) || store.masterData.draft.sService.Entwuerfe.AenderungKontaktdaten.PHANDY === ""
      );
    },
    validPSTRASSE() {
      const pattern = getStreetAndNumberPattern();
      return store.masterData.draft.sService.Entwuerfe.AenderungKontaktdaten.PSTRASSE.match(
        pattern
      );
    },
    setAdresstyp(val) {
      if (val && typeof val === "object" && typeof val.value !== "undefined") {
        store.masterData.draft.sService.Entwuerfe.AenderungKontaktdaten.PADRTYP =
          val.value + ""; // Dropdown stets als String speichern wegen modified-Abgleich
      }
    },
    sendDataFinished() {
      let messageCode = this.$servertalk.sendDataSelfService(
        "AenderungKontaktdaten"
      );
      store.message =
        messageCode == 0
          ? {
            text: "Ihre Änderungen im Bereich\n Änderung Kontaktdaten \n wurden erfolgreich beauftragt.",
            status: "ok",
          }
          : {
            text:
              "Beim Speichern des Auftrags im Bereich Änderung Kontaktdaten trat ein Fehler auf (Error-Code " +
              messageCode +
              ").",
            status: "error",
          };
      if (messageCode == 0) this.saved = true;
      this.$matomo?.trackEvent("SendService", "AenderungKontaktdaten");
      this.$router.push("/auftrag-an-pensus");
    },
    resetData() {
      let messageCode = this.$servertalk.copyToDraftSelfService(
        "AenderungKontaktdaten"
      );
      store.message =
        messageCode == 0
          ? {
            text: "Ihre Änderungen im Bereich\n  Änderung Kontaktdaten \n wurden zurückgesetzt.",
            status: "ok",
          }
          : {
            text:
              "Beim Zurücksetzen der Daten im Bereich Änderung Kontaktdaten trat ein Fehler auf (Error-Code " +
              messageCode +
              ").",
            status: "error",
          };
    },
  },
  computed: {
    validPCONAME() {
      const pattern = getNamePattern();
      return (
        (store.masterData.draft.sService.Entwuerfe.AenderungKontaktdaten.PCONAME.match(
          pattern
        ) &&
          store.masterData.draft.sService.Entwuerfe.AenderungKontaktdaten.PCONAME !==
          "") ||
        store.masterData.draft.sService.Entwuerfe.AenderungKontaktdaten.PADRTYP !== "5"
      );
    },
    checkCoName() {
      if (store.masterData.draft.sService.Entwuerfe.AenderungKontaktdaten.PADRTYP !== "5")
        store.masterData.draft.sService.Entwuerfe.AenderungKontaktdaten.PCONAME = "";
      return (
        store.masterData.draft.sService.Entwuerfe.AenderungKontaktdaten.PADRTYP === "5"
      ); // C/O Adresse
    },
    setSchwebendClass() {
      // Erhalte den Wert "Privatadresse" von $servertalk
      const aenderungPrivatadresseValue =
        this.$servertalk.gettAttr("masterData.data.Privatadresse");
      //const aenderungPrivatadresseValue = this.$servertalk.gettAttr("subData.draft.sService.Entwuerfe.AenderungKontaktdaten");

      return aenderungPrivatadresseValue;
    },
    setStatusClass() {
      // Erhalte den Wert "Privatadresse" von $servertalk
      //const aenderungPrivatadresseValue = this.$servertalk.gettAttr("masterData.data.Privatadresse");
      const aenderungPrivatadresseValue = this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.AenderungKontaktdaten"
      );

      return aenderungPrivatadresseValue;
    },
    setStatusClassPCONAME() {
      // Erhalte den Wert "Privatadresse.PCONAME" von $servertalk
      return this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.AenderungKontaktdaten.PCONAME"
      );
    },
    setStatusClassPTELEFON() {
      // Erhalte den Wert "Privatadresse.PTELEFON" von $servertalk
      return this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.AenderungKontaktdaten.PTELEFON"
      );
    },
    setStatusClassPADRTYP() {
      // Erhalte den Wert "Privatadresse.PADRTYP" von $servertalk
      return this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.AenderungKontaktdaten.PADRTYP"
      );
    },
    setStatusClassPSTRASSE() {
      // Erhalte den Wert "Privatadresse.PSTRASSE" von $servertalk
      return this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.AenderungKontaktdaten.PSTRASSE"
      );
    },
    setStatusClassPPLZ() {
      // Erhalte den Wert "Privatadresse.PPLZ" von $servertalk
      return this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.AenderungKontaktdaten.PPLZ"
      );
    },
    setStatusClassPORT() {
      // Erhalte den Wert "Privatadresse.PORT von $servertalk
      return this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.AenderungKontaktdaten.PORT"
      );
    },
    setStatusClassPLKZ() {
      // Erhalte den Wert "Privatadresse.PLKZ von $servertalk
      return this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.AenderungKontaktdaten.PLKZ"
      );
    },
    setStatusClassPHANDY() {
      // Erhalte den Wert "Privatadresse.PHANDY von $servertalk
      return this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.AenderungKontaktdaten.PHANDY"
      );
    },
    setStatusClassEMAIL() {
      // Erhalte den Wert "Privatadresse.EMAIL von $servertalk
      return this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.AenderungKontaktdaten.EMAIL"
      );
    },
  },
};
</script>
<template>
  <div class="main-wrapper" :class="store.accessRestriction">
    <HauptMenue />
    <div class="mt-36 md:mt-48 lg:mt-56 mb-4 max-w-7xl mx-auto px-5 lg:px-0">
      <h1 class="text-primary/75 font-bold text-2xl md:text-3xl lg:text-4xl text-center mb-12 uppercase">
        Kontaktdaten ändern
      </h1>
      <!--modal content-->
      <Alertbox />
      <div class="max-w-5xl mx-auto mb-8">
        <div class="status-label mb-8" v-if="setStatusClass === 'modified'">
          <span class="bearbeitung bg-yellow-400 text-tertiary inline-block py-1 px-2 text-sm uppercase mb-2">
            Entwurf
          </span>
          <span class="infotext text-tertiary text-sm block">
            Dies ist ein Entwurf. Bitte bestätigen Sie diesen Änderungsauftrag,
            indem Sie auf
            <strong>Beauftragen</strong>
            klicken.
          </span>
        </div>
        <form :class="setStatusClass" id="custom-form">
          <label for="anrede" class="text-sm mb-2 block">
            Adresstyp
            <sup>*</sup>
          </label>
          <CustomSelect @input="setAdresstyp" class="select" :class="setStatusClassPADRTYP" required
            :options="sAdresstypOptions" :default="store.masterData.draft.sService.Entwuerfe.AenderungKontaktdaten.PADRTYP
    " :oldDefault="store.masterData.olddraft.sService.Entwuerfe.AenderungKontaktdaten.PADRTYP
    " :tabindex="1" />

          <div v-if="checkCoName">
            <div class="relative">
              <label for="coName" class="text-sm mb-2 block">
                c/o Name
                <sup>*</sup>
              </label>
              <input maxlength="255" type="text" id="coName"
                class="peer w-full p-3 border border-secondary/50 rounded-md mb-8 focus:outline-0 focus:border-tertiary/50 invalid:border-red-500 invalid:bg-red-50 invalid:text-red-500 focus:invalid:border-red-500"
                :class="setStatusClassPCONAME" required v-model="store.masterData.draft.sService.Entwuerfe.AenderungKontaktdaten.PCONAME
    " :pattern="getNamePattern().source" />
              <span class="invisible peer-invalid:visible text-red-500 text-sm absolute bottom-2 left-0 min-w-max">
                Der als c/o Name angegebene Wert muss gültig sein.
              </span>
            </div>
          </div>
          <div class="relative">
            <label for="strasse" class="text-sm mb-2 block">
              Straße / Hausnummer
              <sup>*</sup>
            </label>
            <input type="text" id="strasse"
              class="peer w-full p-3 border border-secondary/50 rounded-md mb-8 focus:outline-0 focus:border-tertiary/50 invalid:border-red-500 invalid:bg-red-50 invalid:text-red-500 focus:invalid:border-red-500"
              :class="setStatusClassPSTRASSE" required v-model="store.masterData.draft.sService.Entwuerfe.AenderungKontaktdaten.PSTRASSE
    " :pattern="getStreetAndNumberPattern().source" />
            <span class="invisible peer-invalid:visible text-red-500 text-sm absolute bottom-2 left-0 min-w-max">
              Straße und Hausnummer müssen erfasst werden und gültig sein.
            </span>
          </div>
          <div class="plz-stadt grid lg:grid-cols-6 gap-x-8">
            <div class="plz lg:col-span-1 relative">
              <label for="plz" class="text-sm mb-2 block">
                Postleitzahl
                <sup>*</sup>
              </label>
              <input type="text" id="plz"
                class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 focus:border-tertiary/50 peer invalid:text-red-500 focus:invalid:border-red-500"
                :class="setStatusClassPPLZ" required v-model="store.masterData.draft.sService.Entwuerfe.AenderungKontaktdaten.PPLZ
    " inputmode="numeric" ref="plz" :pattern="getPostalCodePattern().source" />
              <span class="invisible peer-invalid:visible text-red-500 text-sm absolute bottom-2 left-0 min-w-max">
                Die Postleitzahl stimmt nicht.
              </span>
            </div>
            <div class="ort lg:col-span-5">
              <label for="ort" class="text-sm mb-2 block">
                Ort
                <sup>*</sup>
              </label>
              <input type="text" id="ort"
                class="w-full p-3 border border-secondary/50 rounded-md mb-8 focus:outline-0 focus:border-tertiary/50"
                :class="setStatusClassPORT" required v-model="store.masterData.draft.sService.Entwuerfe.AenderungKontaktdaten.PORT
    " />
            </div>
          </div>
          <label for="land" class="text-sm mb-2 block">
            Land
            <sup>*</sup>
          </label>
          <CustomSelect @input="setLand" class="select" :class="setStatusClassPLKZ" required keyEmpty="0"
            :options="sLandOptions" :default="store.masterData.draft.sService.Entwuerfe.AenderungKontaktdaten.PLKZ" :oldDefault="store.masterData.olddraft.sService.Entwuerfe.AenderungKontaktdaten.PLKZ
    " :tabindex="1" />
          <div class="grid lg:grid-cols-2 gap-x-8">
            <div class="relative">
              <label for="telefonnummer" class="text-sm mb-2 block">
                Telefonnummer
              </label>
              <input type="tel" id="telefonnummer"
                class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500 peer"
                :class="setStatusClassPTELEFON" v-model="store.masterData.draft.sService.Entwuerfe.AenderungKontaktdaten.PTELEFON
    " inputmode="numeric" :pattern="getPhoneNumberPattern().source" />
              <span class="invisible peer-invalid:visible text-red-500 text-sm absolute bottom-2 left-0">
                Bitte tragen Sie hier Ihre Telefonnummer ein.
              </span>
            </div>
            <div class="relative">
              <label for="handynummer" class="text-sm mb-2 block">
                Handynummer
              </label>
              <input type="tel" id="handynummer"
                class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500 peer"
                :class="setStatusClassPHANDY" v-model="store.masterData.draft.sService.Entwuerfe.AenderungKontaktdaten.PHANDY
    " inputmode="numeric" :pattern="getPhoneNumberPattern().source" />
              <span class="invisible peer-invalid:visible text-red-500 text-sm absolute bottom-2 left-0">
                Bitte tragen Sie hier Ihre Handynummer ein.
              </span>
            </div>
          </div>

          <div class="relative">
            <div class="email" :class="setStatusClassEMAIL">
              {{ store.masterData.draft.sService.Entwuerfe.AenderungKontaktdaten.EMAIL }}
            </div>
          </div>
          <div class="button-wrapper flex justify-center gap-4 [&>button]:mx-0">
            <ButtonSendDataSelfService :disabled="setSchwebendClass === 'schwebend' ||
    setStatusClass !== 'modified' ||
    !validPPLZ() ||
    !validPTELEFON() ||
    !validPSTRASSE() ||
    !validPHANDY() ||
    !validPCONAME ||
    store.masterData.draft.sService.Entwuerfe.AenderungKontaktdaten.PLKZ == '0'
    " @finished="sendDataFinished" v-if="setSchwebendClass != 'schwebend'" />
            <div v-else>
              Ihre Kontaktdaten werden aktuell verarbeitet<br />
              und können daher nicht geändert werden.
            </div>
            <ButtonResetServiceData :disabled="setStatusClass !== 'modified'" @reset="resetData" />
          </div>
        </form>
        <DatenschutzHinweis />
        <div v-if="setSchwebendClass === 'schwebend'">
          <div class="intro-content p-4 bg-gray-50 rounded-md max-w-3xl mx-auto lg:text-xl text-primary">
            <p>
              Ihre Daten werden derzeit verarbeitet. Bitte haben Sie etwas
              Geduld und schauen zu einem späteren Zeitpunkt nochmal in Ihr
              Portal.
            </p>
          </div>
        </div>
        <ButtonBack />
      </div>
    </div>
  </div>
  <FooterMenu />
</template>
