<script setup>
import DragAndDrop from "./DragAndDrop.vue";
import CameraCapture from "./CameraCapture.vue";
</script>
<script>
import { store } from "./store.vue";
/** Komponente zum Hochladen von Attachments via Drag'n Drop
 * (nicht mehr benötigt? Importiert aber nirgends eingebunden)
 * @displayName Service zum Datei-Upload
 */
export default {
  name: "DokumenteHochladenService",
  components: {
    // myRouter: router,
    DragAndDrop,
    CameraCapture,
  },
  data() {
    return {
      store,
    };
  },
  mounted() {
    this.$servertalk.doLog("s this.defaultData");
    this.$servertalk.doLog(this.defaultData);
    this.$servertalk.doLog(this.formId);
  },
  methods: {
    status(s) {
      this.$emit("status", s);
      //s.uploadStatus = s.uploadStatus + '+';
    },
    isOk(s) {
      this.$emit("isOk", s, this.lfn);
    },
    newfiles(s) {
      this.$servertalk.doLog("newfiles");
      this.$servertalk.doLog(s);
      this.$emit("newfiles", s, this.lfn);
      this.$servertalk.doLog(store.draft);
    },
    change(s) {
      this.$servertalk.doLog("change");
      this.$servertalk.doLog(s);
      this.$emit("change", s, this.lfn);
      this.$servertalk.doLog(store.draft);
    },
  },
  emits: ["change", "status", "isOk", "newfiles"],
  props: {
    formId: {
      type: String,
      required: true,
    },
    defaultData: {
      type: Array,
      required: true,
    },
    infoText: {
      type: String,
      default: "Upload",
    },
    docArt: {
      type: String,
      default: "Document",
    },
    maxfiles: {
      default: 1,
    },
    minfiles: {
      default: 0,
    },
    lfn: {
      type: Number,
      default: 0,
    },
  },
};
</script>
<template>
  <div class="">
    <DragAndDrop
      :formId="formId"
      :infoText="infoText"
      :docArt="docArt"
      :maxfiles="parseInt(maxfiles)"
      :minfiles="parseInt(minfiles)"
      :defaultData="defaultData"
      @status="status"
      @isOk="isOk"
      @newfiles="newfiles"
      @change="change" />
  </div>
</template>
