<script setup>
import HauptMenue from "../../components/HauptMenue.vue";
import FooterMenu from "../../components/FooterMenu.vue";
import DatenschutzHinweis from "../../components/DatenschutzHinweis.vue";
import ButtonSendDataSelfService from "../../components/ButtonSendDataSelfService.vue";
import ButtonResetServiceData from "../../components/ButtonResetServiceData.vue";
import CustomSelect from "../../components/CustomSelect.vue";
import Alertbox from "../../components/Alertbox.vue";
import ButtonBack from "../../components/ButtonBack.vue";
import { store } from "../../components/store.vue";
import { bankDataByIBAN } from "bankdata-germany";
import { isValidIBAN } from "ibantools-germany";
//import { friendlyFormatIBAN, electronicFormatIBAN } from "ibantools";
import { getNamePatternEmpty } from "../../utility";
/** View-Komponente zum Erfassen von Änderungsaufträgen zur Bankverbindung
 * @displayName Selfservice Änderung Bankverbindung
 */
</script>
<script>
export default {
  name: "SelfServiceAenderungBankverbindung",

  components: {
    // myRouter: router,
    HauptMenue,
    FooterMenu,
    DatenschutzHinweis,
    ButtonSendDataSelfService,
    ButtonResetServiceData,
    CustomSelect,
    Alertbox,
    ButtonBack,
    bankDataByIBAN,
    isValidIBAN,
  },
  data() {
    return {
      store,
      validIBAN: false, // Hier wird der Validitätsstatus der IBAN gespeichert
      bankData: {
        bankName: "",
        bic: "",
      }, // Hier werden die Bankdaten gespeichert
      ibanPrefix: "", // Hier werden die ersten zwei Stellen der IBAN für das Feld Sitz des Geldinstituts gespeichert
      vorgefuellteIBAN: "", // Hier wird die IBAN aus dem Store gespeichert
      saved: false,
      bankDataDisabled: false,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    window._paq.push(["setDocumentTitle", this.name]);
    window._paq.push(["trackPageView"]);
    const validiban = isValidIBAN(
      store.masterData.draft.sService.Entwuerfe.AenderungBankverbindung.PRB_IBAN.replaceAll(' ', '')
    );
    const iban = bankDataByIBAN(
      store.masterData.draft.sService.Entwuerfe.AenderungBankverbindung.PRB_IBAN
    );
    /*
    const friendlyiban = friendlyFormatIBAN(
      store.masterData.draft.sService.Entwuerfe.AenderungBankverbindung.PRB_IBAN
    );
    const electronicformat = electronicFormatIBAN(
      store.masterData.draft.sService.Entwuerfe.AenderungBankverbindung.PRB_IBAN
    );
    // this.$servertalk.doLog(iban);
    // this.$servertalk.doLog(validiban);
    // this.$servertalk.doLog(friendlyiban);
    // this.$servertalk.doLog(electronicformat);
    */
    this.validiereIBAN(); // Führt die Validierung und Aktualisierung der Bankdaten aus
  },
  unmounted() {
    if (this.saved) {
      // Nach Beauftragung Service in Draft komplett löschen
      delete store.masterData.olddraft.sService.Entwuerfe.AenderungBankverbindung;
      delete store.masterData.draft.sService.Entwuerfe.AenderungBankverbindung;
      this.saved = false;
    }
  },
  beforeCreate: function () {
    // this.$servertalk.doLog("Create");
    // this.$servertalk.doLog("Inhalt store:");
    // this.$servertalk.doLog(store);
    let action = this.$servertalk.initSelfService(
      "AenderungBankverbindung",
      '{"PRB_IBAN":"","PRB_BIC":"","PRB_BNAME":"","PRB_LAND":"","PRB_LKZ":"","PRB_ABWNAME":"","uuid":"","version":"1.0"}',
      "1.0"
    );
    if (action % 2 == 1) {
      // olddraft wurde initialisiert
      let olddraft = store.masterData.olddraft.sService.Entwuerfe.AenderungBankverbindung;
      olddraft.PRB_IBAN = store.masterData.data.Privatbank.PRB_IBAN;
      olddraft.PRB_BIC = store.masterData.data.Privatbank.PRB_BIC;
      olddraft.PRB_BNAME = store.masterData.data.Privatbank.PRB_BNAME;
      olddraft.PRB_LAND = store.masterData.data.Privatbank.PRB_LAND;
      olddraft.PRB_LKZ = store.masterData.data.Privatbank.PRB_LKZ;
      olddraft.PRB_ABWNAME = store.masterData.data.Privatbank.PRB_ABWNAME;
      olddraft.uuid = this.$servertalk.getUUID();
    }
    if (action >= 2) {
      // draft wurde initialisiert
      this.$servertalk.copyToDraftSelfService("AenderungBankverbindung");
    }
  },
  methods: {
    validiereIBAN() {
      store.masterData.draft.sService.Entwuerfe.AenderungBankverbindung.PRB_IBAN =
        store.masterData.draft.sService.Entwuerfe.AenderungBankverbindung.PRB_IBAN.toUpperCase();
      // Führt die Validierung und Abfrage der Bankdaten durch
      this.validIBAN = isValidIBAN(
        store.masterData.draft.sService.Entwuerfe.AenderungBankverbindung.PRB_IBAN.replaceAll(' ', '')
      );
      const fetchedBankData = bankDataByIBAN(
        store.masterData.draft.sService.Entwuerfe.AenderungBankverbindung.PRB_IBAN
      );
      // Überprüfe, ob die IBAN mit "DE" beginnt
      if (
        store.masterData.draft.sService.Entwuerfe.AenderungBankverbindung.PRB_IBAN.startsWith(
          "DE"
        )
      ) {
        // Wenn ja, aktualisiere die Bankdaten aus dem abgerufenen Objekt
        if (fetchedBankData) {
          store.masterData.draft.sService.Entwuerfe.AenderungBankverbindung.PRB_BNAME =
            fetchedBankData.bankName;
          store.masterData.draft.sService.Entwuerfe.AenderungBankverbindung.PRB_BIC =
            fetchedBankData.bic;
          //this.bankData.blz = fetchedBankData.blz;
          this.bankDataDisabled = true; // IBAN beginnt mit "DE", daher sind die Inputfelder gesperrt
        }
      } else {
        // Wenn nicht, lösche die Felder bankName und bic
        store.masterData.draft.sService.Entwuerfe.AenderungBankverbindung.PRB_BNAME = "";
        store.masterData.draft.sService.Entwuerfe.AenderungBankverbindung.PRB_BIC = "";
        this.bankDataDisabled = false; // IBAN beginnt nicht mit "DE", deshalb Inputfelder freischalten für Eingabe
      }
      if (
        store.masterData.draft.sService.Entwuerfe.AenderungBankverbindung.PRB_IBAN.length <
        2
      ) {
        this.bankDataDisabled = true; // Keine IBAN erfasst: Banknamen und BIC gegen Eingaben sperren.
        store.masterData.draft.sService.Entwuerfe.AenderungBankverbindung.PRB_LAND = "";
      } else {
        // Extrahiere die ersten zwei Zeichen der IBAN und speicher sie in der Datenvariable ibanPrefix
        store.masterData.draft.sService.Entwuerfe.AenderungBankverbindung.PRB_LAND =
          store.masterData.draft.sService.Entwuerfe.AenderungBankverbindung.PRB_IBAN.slice(
            0,
            2
          );
      }
    },
    sendDataFinished() {
      let messageCode = this.$servertalk.sendDataSelfService(
        "AenderungBankverbindung"
      );
      store.message =
        messageCode == 0
          ? {
              text: "Ihre Änderungen im Bereich\n Änderung Bankverbindung \n wurden erfolgreich beauftragt.",
              status: "ok",
            }
          : {
              text:
                "Beim Speichern des Auftrags im Bereich\n Änderung Bankverbindung \n trat ein Fehler auf (Error-Code " +
                messageCode +
                ").",
              status: "error",
            };
      if (messageCode == 0) this.saved = true;
      this.$matomo?.trackEvent("SendService", "AenderungBankverbindung");
      this.$router.push("/auftrag-an-pensus"); // Nach dem Beauftragen weiterleiten zur Auftragsübersicht
    },
    resetData() { // Button zum Zurücksetzen der Änderungen gedrückt
      let messageCode = this.$servertalk.copyToDraftSelfService(
        "AenderungBankverbindung"
      );
      this.validiereIBAN();
      store.message =
        messageCode == 0
          ? {
              text: "Ihre Änderungen im Bereich\n Änderung Bankverbindung \n wurden zurückgesetzt.",
              status: "ok",
            }
          : {
              text:
                "Beim Zurücksetzen der Daten im Bereich\n Änderung Bankverbindung \n trat ein Fehler auf (Error-Code " +
                messageCode +
                ").",
              status: "error",
            };
    },
  },
  computed: {
    validPRB_ABWNAME() {
      const pattern = getNamePatternEmpty();
      return store.masterData.draft.sService.Entwuerfe.AenderungBankverbindung.PRB_ABWNAME.match(
        pattern
      );
    },
    setSchwebendClass() {
      // Erhalte den Wert "Privatadresse" von $servertalk
      const aenderungPrivatadresseValue = this.$servertalk.gettAttr("masterData.data.Privatbank");
      //const aenderungPrivatadresseValue = this.$servertalk.gettAttr("subData.draft.sService.Entwuerfe.AenderungKontaktdaten");
      
      return aenderungPrivatadresseValue;
    },
    setStatusClass() {
      // Erhalte den Wert "AenderungBankverbindung" von $servertalk
      const aenderungBankverbindungValue = this.$servertalk.gettAttr("masterData.draft.sService.Entwuerfe.AenderungBankverbindung");
      return aenderungBankverbindungValue;
    },
    setStatusClassPRB_IBAN() {
      // Erhalte den Wert "PRB_IBAN" von $servertalk
      return this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.AenderungBankverbindung.PRB_IBAN"
      );
    },
    setStatusClassPRB_BIC() {
      // Erhalte den Wert "PRB_BIC" von $servertalk
      return this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.AenderungBankverbindung.PRB_BIC"
      );
    },
    setStatusClassPRB_BNAME() {
      // Erhalte den Wert "PRB_BNAME" von $servertalk
      return this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.AenderungBankverbindung.PRB_BNAME"
      );
    },
    setStatusClassPRB_ABWNAME() {
      // Erhalte den Wert "PRB_ABWNAME" von $servertalk
      return this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.AenderungBankverbindung.PRB_ABWNAME"
      );
    },
    setStatusClassPRB_LAND() {
      // Erhalte den Wert "PRB_ABWNAME" von $servertalk
      return this.$servertalk.gettAttr(
        "masterData.draft.sService.Entwuerfe.AenderungBankverbindung.PRB_LAND"
      );
    },
  },
};
</script>
<template>
  <div class="main-wrapper" :class="store.accessRestriction">
    <HauptMenue />
    <Transition>
      <div class="mt-36 md:mt-48 lg:mt-56 max-w-7xl mx-auto">
        <div class="max-w-5xl mx-auto mb-8 px-5 lg:px-0">
          <h1
            class="text-primary/75 font-bold text-xl sm:text-2xl md:text-3xl lg:text-4xl text-center mb-12 uppercase"
          >
            Ich möchte die Änderung der Bankverbindung melden
          </h1>
          <div class="intro-content [&amp;>p]:mb-8 max-w-3xl mx-auto">
            <p>
              Bitte ändern Sie hier Ihre IBAN und eventuell den Namen des
              abweichenden Kontoinhabers. Haben Sie Ihr Bankkonto
              <strong>nicht</strong>
              in Deutschland? Dann geben sie bitte zusätzlich den Banknamen und
              die BIC ein.
            </p>
          </div>
          <!--modal content-->
          <Alertbox />
          <div class="status-label mb-8" v-if="setStatusClass === 'modified'">
            <span
              class="bearbeitung bg-yellow-400 text-tertiary inline-block py-1 px-2 text-sm uppercase mb-2"
            >
              Entwurf
            </span>
            <span class="infotext text-tertiary text-sm block">
              Dies ist ein Entwurf. Bitte bestätigen Sie diesen
              Änderungsauftrag, indem Sie auf
              <strong>Beauftragen</strong>
              klicken.
            </span>
          </div>
          <form :class="setStatusClass">
            <div class="grid xl:grid-cols-1 sm:gap-5">
              <div>
                <label for="aendBankname" class="text-sm mb-2 block">
                  Bankname
                  <sup>*</sup>
                </label>
                <input
                  maxlength="100"
                  type="text"
                  id="aendBankname"
                  :class="setStatusClassPRB_BNAME"
                  required
                  class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500"
                  :disabled="bankDataDisabled"
                  v-model="
                    store.masterData.draft.sService.Entwuerfe.AenderungBankverbindung
                      .PRB_BNAME
                  "
                />
              </div>
            </div>
            <div class="grid xl:grid-cols-2 sm:gap-5">
              <div class="relative">
                <label for="aendIban" class="text-sm mb-2 block">
                  IBAN
                  <sup>*</sup>
                </label>
                <input
                  maxlength="100"
                  type="text"
                  id="aendIban"
                  :class="setStatusClassPRB_IBAN"
                  class="w-full p-3 border rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 border-secondary/50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500"
                  @input="validiereIBAN"
                  :pattern="validIBAN ? '.*' : 'invalid'"
                  v-model="
                    store.masterData.draft.sService.Entwuerfe.AenderungBankverbindung
                      .PRB_IBAN
                  "
                />
                <span
                  class="text-red-500 text-sm absolute left-0 bottom-2"
                  v-if="!validIBAN"
                >
                  Bitte geben Sie eine gültige IBAN ein.
                </span>
              </div>
              <div>
                <label for="aendBic" class="text-sm mb-2 block">
                  BIC
                  <sup>*</sup>
                </label>
                <input
                  maxlength="100"
                  type="text"
                  id="aendBic"
                  required
                  class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500"
                  :class="setStatusClassPRB_BIC"
                  :disabled="bankDataDisabled"
                  v-model="
                    store.masterData.draft.sService.Entwuerfe.AenderungBankverbindung
                      .PRB_BIC
                  "
                />
              </div>
            </div>
            <div class="grid xl:grid-cols-1 sm:gap-5">
              <div class="relative">
                <label for="aendAbwname" class="text-sm mb-2 block">
                  Name des abweichenden Kontoinhabers
                </label>
                <input
                  maxlength="100"
                  type="text"
                  id="aendAbwname"
                  :class="setStatusClassPRB_ABWNAME"
                  class="peer w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500"
                  v-model="
                    store.masterData.draft.sService.Entwuerfe.AenderungBankverbindung
                      .PRB_ABWNAME
                  "
                  :pattern="getNamePatternEmpty().source"
                />
                <span
                  class="invisible peer-invalid:visible text-red-500 text-sm absolute bottom-2 left-0 min-w-max"
                >
                  Der angegebene Name muss gültig sein.
                </span>
              </div>
            </div>
            <div class="grid xl:grid-cols-1 sm:gap-5">
              <div>
                <label for="aendLand" class="text-sm mb-2 block">
                  Sitz des Geldinstituts
                </label>
                <input
                  maxlength="100"
                  disabled
                  type="text"
                  id="aendLand"
                  :class="{
                    'border-yellow-400 !focus:border-yellow-400 bg-yellow-50':
                      setStatusClassPRB_LAND,
                    'focus:border-tertiary/50': !setStatusClassPRB_LAND,
                  }"
                  class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500"
                  v-model="
                    store.masterData.draft.sService.Entwuerfe.AenderungBankverbindung
                      .PRB_LAND
                  "
                />
              </div>
            </div>
            <div
              class="button-wrapper flex justify-center gap-4 [&>button]:mx-0"
            >
              <ButtonSendDataSelfService
                :disabled="
                  setSchwebendClass === 'schwebend' ||
                  setStatusClass !== 'modified' ||
                  !validIBAN ||
                  !validPRB_ABWNAME
                "
                @finished="sendDataFinished"
                v-if="setSchwebendClass != 'schwebend'"
              />
              <div v-else>Ihre Bankdaten werden aktuell verarbeitet<br/> und können daher nicht geändert werden.</div>
              <ButtonResetServiceData
                :disabled="setStatusClass !== 'modified'"
                @reset="resetData"
              />
            </div>
          </form>
          <DatenschutzHinweis />
          <ButtonBack />
        </div>
      </div>
    </Transition>

    <FooterMenu />
  </div>
</template>
