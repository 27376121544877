<script>
import { store } from "./store.vue";

/** Button zum Speichern der erfassten Daten zum Selfservice
 *   @displayName Änderungsaufträge Selfservices
 */

export default {
  name: "ButtonSendDataSelfService",
  components: {},
  data() {
    return {
      store,
    };
  },
  methods: {
    doSend(e) {
      // Click-Function auf Button
      if (e) e.preventDefault();
      /**
       * Das Speichern wurde initiiert und ist durch die rufende Komponente auszuführen.
       */
      this.$emit("finished");
    },
  },
};
</script>
<template>
  <button
    @click="doSend"
    type="submit"
    class="bg-primary text-white py-4 w-64 rounded-md text-base lg:text-xl mx-auto block hover:bg-tertiary transition disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-primary"
    :disabled="store.masterData.isReadOnly"
  >
    Beauftragen
  </button>
</template>
