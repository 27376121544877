<script setup>
import CustomSelect from "./CustomSelect.vue";
</script>
<script>
import { store } from "./store.vue";
/** Komponente zur Anzeige der Kontaktdaten des Benutzers ohne Änderungsmöglichkeit
 * @displayName Anzeige der Kontaktdaten
 */
export default {
  name: "DataContactTab",
  components: {},
  data() {
    return {
      statusCurrent: "999",
      meldung: "",
      store,
      // Auf folgenden Feldern liegen Plausis, sie dürfen deshalb nur wenn korrekt in den Store übertragen werden
      EMAIL: store.masterData.data.Stammdaten.EMAIL,
      PTELEFON: store.masterData.data.Privatadresse.PTELEFON,
      PHANDY: store.masterData.data.Privatadresse.PHANDY,
      sAdresstypOptions: this.$servertalk.getSelectOpts("ADRTYP"),
      sAdresstyp: {
        text: this.$servertalk.getName(
          "ADRTYP",
          store.masterData.data.Privatadresse.PADRTYP
        ),
        key: store.masterData.data.Privatadresse.PADRTYP,
      },
      sLandOptions: this.$servertalk.getSelectOpts("NATIONALITAET"),
      sLand: {
        text: this.$servertalk.getName(
          "NATIONALITAET",
          store.masterData.data.Privatadresse.PLKZ
        ),
        key: store.masterData.data.Privatadresse.PLKZ,
      },
    };
  },

  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    setMeldung(msg) {
      this.meldung = msg;
    },
    sendData(e) {
      // Click-Function auf Button
      if (e) e.preventDefault();
      this.statusCurrent = "999";
      this.setMeldung("starte");
      this.$servertalk.sendDataToDB(this.$event, this.setStatus);
    },
    setStatus(status) {
      // Callback serverTalk erhält Status nach asynchroner Verarbeitung
      this.statusCurrent = status;
    },
  },
  computed: {
    statusModified: function () {
      return this.statusCurrent;
    },
  },
  watch: {
    EMAIL: function (EMAIL) {
      // Lokales Feld nur in den Store übertragen, wenn Plausi erfolgreich war.
      if (
        EMAIL.match(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
      ) {
        store.masterData.data.Stammdaten.EMAIL = EMAIL.toLowerCase();
      }
    },
    PTELEFON: function (PTELEFON) {
      // Lokales Feld nur in den Store übertragen, wenn Plausi erfolgreich war.
      if (PTELEFON.match(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g)) {
        store.masterData.data.Privatadresse.PTELEFON = PTELEFON;
      }
    },
    PHANDY: function (PHANDY) {
      // Lokales Feld nur in den Store übertragen, wenn Plausi erfolgreich war.
      if (PHANDY.match(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g)) {
        store.masterData.data.Privatadresse.PHANDY = PHANDY;
      }
    },

    statusModified: function (neuerStatus) {
      this.statusCurrent = neuerStatus;
    },
    statusCurrent: function (neuerStatus) {
      let meldg = "Die Daten wurden erfolgreich übertragen.";
      if (neuerStatus != 200 && neuerStatus != 999) {
        meldg =
          "Bei der Datenübertragung ist ein Fehler aufgetreten. Status " +
          neuerStatus;
      }
      this.setMeldung(meldg);
      setTimeout(
        function (setMeldung) {
          setMeldung("");
        },
        5000,
        this.setMeldung
      );
    },
  },
};
</script>
<template>
  <form class="schwebend">
    <label for="anrede" class="text-sm mb-2 block">Adresstyp</label>
    <CustomSelect
      @input="sAdresstyp"
      class="select"
      :options="sAdresstypOptions"
      :default="store.masterData.data.Privatadresse.PADRTYP"
      :oldDefault="store.masterData.oldData.Privatadresse.PADRTYP"
    />
    <div v-if="store.masterData.data.Privatadresse.PCONAME">
      <label for="strasse" class="text-sm mb-2 block">c/o Name</label>
      <input
        maxlength="255"
        type="text"
        id="strasse"
        class="w-full p-3 border border-secondary/50 rounded-md mb-8 focus:outline-0 focus:border-tertiary/50"
        required
        v-model="store.masterData.data.Privatadresse.PCONAME"
      />
    </div>
    <label for="strasse" class="text-sm mb-2 block">Straße / Hausnummer</label>
    <input
      maxlength="255"
      type="text"
      id="strasse"
      class="w-full p-3 border border-secondary/50 rounded-md mb-8 focus:outline-0 focus:border-tertiary/50"
      required
      v-model="store.masterData.data.Privatadresse.PSTRASSE"
    />
    <div class="plz-stadt grid lg:grid-cols-6 gap-x-8">
      <div class="plz lg:col-span-1 relative">
        <label for="plz" class="text-sm mb-2 block">Postleitzahl</label>
        <input
          maxlength="255"
          type="text"
          id="plz"
          class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 focus:border-tertiary/50 peer invalid:text-red-500 focus:invalid:border-red-500"
          required
          v-model="store.masterData.data.Privatadresse.PPLZ"
          inputmode="numeric"
        />
      </div>
      <div class="ort lg:col-span-5">
        <label for="ort" class="text-sm mb-2 block">Ort</label>
        <input
          maxlength="255"
          type="text"
          id="ort"
          class="w-full p-3 border border-secondary/50 rounded-md mb-8 focus:outline-0 focus:border-tertiary/50"
          required
          v-model="store.masterData.data.Privatadresse.PORT"
        />
      </div>
    </div>
    <label for="land" class="text-sm mb-2 block">Land</label>
    <CustomSelect
      @input="sLand"
      class="select"
      :options="sLandOptions"
      :default="store.masterData.data.Privatadresse.PLKZ"
      :oldDefault="store.masterData.oldData.Privatadresse.PLKZ"
    />
    <div class="grid lg:grid-cols-2 gap-x-8">
      <div class="relative">
        <label for="telefonnummer" class="text-sm mb-2 block">
          Telefonnummer
        </label>
        <input
          maxlength="255"
          type="tel"
          id="telefonnummer"
          class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500 peer"
          v-model="PTELEFON"
          inputmode="numeric"
        />
        <span
          class="invisible peer-invalid:visible text-red-500 text-sm absolute bottom-2 left-0"
        >
          Bitte tragen Sie hier Ihre Telefonnummer ein.
        </span>
      </div>
      <div class="relative">
        <label for="handynummer" class="text-sm mb-2 block">Handynummer</label>
        <input
          maxlength="255"
          type="tel"
          id="handynummer"
          class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500 peer"
          v-model="PHANDY"
          inputmode="numeric"
        />
        <span
          class="invisible peer-invalid:visible text-red-500 text-sm absolute bottom-2 left-0"
        >
          Bitte tragen Sie hier Ihre Handynummer ein.
        </span>
      </div>
    </div>

    <div class="relative">
      <label for="email" class="text-sm mb-2 block">E-Mail Adresse</label>
      <input
        maxlength="255"
        type="email"
        id="email"
        class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 focus:border-tertiary/50 peer invalid:text-red-500 focus:invalid:border-red-500"
        required
        v-model="EMAIL"
        inputmode="email"
      />
      <span
        class="invisible peer-invalid:visible text-red-500 text-sm absolute bottom-2 left-0"
      >
        Bitte tragen Sie hier Ihre E-Mail Adresse ein.
      </span>
    </div>
    <div class="button-wrapper flex justify-center gap-4 [&amp;>button]:mx-0">
      <router-link
        to="/sservice-aenderung-kontaktdaten"
        custom
        v-slot="{ navigate }"
      >
        <button
          @click="navigate"
          :disabled="store.masterData.isReadOnly"
          role="link"
          type="submit"
          class="bg-primary text-white py-4 w-64 rounded-md text-lg lg:text-xl mx-auto block hover:bg-tertiary transition disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-primary"
        >
          Kontaktdaten ändern
        </button>
      </router-link>
      <router-link
        to="/zugangsdaten-aendern/EMail"
        custom
        v-slot="{ navigate }"
      >
        <button
          @click="navigate"
          :disabled="store.masterData.isReadOnly"
          type="submit"
          class="bg-primary text-white py-4 w-64 rounded-md text-lg lg:text-xl mx-auto block hover:bg-tertiary transition disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-primary"
        >
          E-Mail Adresse ändern
        </button>
      </router-link>
    </div>
  </form>
</template>
