<script setup>
import HauptMenue from "../../components/HauptMenue.vue";
import FooterMenu from "../../components/FooterMenu.vue";
import DatenschutzHinweis from "../../components/DatenschutzHinweis.vue";
import ButtonSendDataSelfService from "../../components/ButtonSendDataSelfService.vue";
import ButtonResetServiceData from "../../components/ButtonResetServiceData.vue";
import CustomSelect from "../../components/CustomSelect.vue";
//import DokumenteHochladen from "../../components/DokumenteHochladenService.vue";
import Alertbox from "../../components/Alertbox.vue";
import ButtonBack from "../../components/ButtonBack.vue";
import { store } from "../../components/store.vue";
import { onMounted } from "vue";
//import { Tooltip } from "flowbite";
import {
    getPostalCodePattern,
    getStreetAndNumberPattern,
    getNamePatternEmpty,
    getIntegerPattern,
} from "../../utility";

/** View-Komponente zum Erfassen von Änderungsaufträgen zu FirmenAdressdaten
 * @displayName Selfservice Änderung Firmen-Adressdaten
 */
onMounted(() => {
    // set the tooltip content element
    /*
        const $targetElOrtzusatz = document.getElementById("tooltipContentOrtzusatz");
    
        const $triggerElOrtzusatz = document.getElementById("tooltipButtonOrtzusatz");
        // options with default values
        const options = {
            placement: "top",
            triggerType: "hover",
        };
    
        const tooltipOrtzusatz = new Tooltip(
            $targetElOrtzusatz,
            $triggerElOrtzusatz,
            options
        );
      */
    // instance options with default values
});
</script>
<script>
export default {
    name: "SelfServiceAenderungFirmenAdressdaten",

    components: {
        // myRouter: router,
        HauptMenue,
        FooterMenu,
        DatenschutzHinweis,
        ButtonSendDataSelfService,
        ButtonResetServiceData,
        CustomSelect,
        Alertbox,
        ButtonBack,
    },
    data() {
        return {
            store,
            saved: false,
            sLandOptions: this.$servertalk.getSelectOpts("NATIONALITAET"),
            sLand: {
                text: this.$servertalk.getName(
                    "NATIONALITAET",
                    store.subData.data.Geschaeftsadresse.GLKZ
                ),
                key: store.subData.data.Geschaeftsadresse.GLKZ,
            },
        };
    },
    mounted() {
        window.scrollTo(0, 0);
        window._paq.push(["setDocumentTitle", this.name]);
        window._paq.push(["trackPageView"]);
    },
    unmounted() {
        if (this.saved) {
            // Nach Beauftragung Service in Draft komplett löschen
            delete store.subData.olddraft.sService.Entwuerfe.AenderungFirmenAdressdaten;
            delete store.subData.draft.sService.Entwuerfe.AenderungFirmenAdressdaten;
            this.saved = false;
        }
    },
    beforeCreate: function () {
        this.$servertalk.doLog("Create");
        this.$servertalk.doLog("Inhalt store:");
        this.$servertalk.doLog(store);
        let action = this.$servertalk.initSelfService(
            "AenderungFirmenAdressdaten",
            '{"GSTRASSE":"","GORT":"","GORTZUS":"","GPLZ":"","GPOSTFACH":"","GPOSTPLZ":"","GLKZ":"","version":"1.1"}',
            "1.1",
            store.subData
        );
        if (action % 2 == 1) {
            // olddraft wurde initialisiert
            let olddraft =
                store.subData.olddraft.sService.Entwuerfe.AenderungFirmenAdressdaten;
            olddraft.GSTRASSE = store.subData.data.Geschaeftsadresse.GSTRASSE;
            olddraft.GORT = store.subData.data.Geschaeftsadresse.GORT;
            olddraft.GORTZUS = store.subData.data.Geschaeftsadresse.GORTZUS;
            olddraft.GPLZ = store.subData.data.Geschaeftsadresse.GPLZ;
            olddraft.GPOSTFACH = store.subData.data.Geschaeftsadresse.GPOSTFACH;
            olddraft.GPOSTPLZ = store.subData.data.Geschaeftsadresse.GPOSTPLZ;
            olddraft.GLKZ = store.subData.data.Geschaeftsadresse.GLKZ;
        }
        if (action >= 2) {
            // draft wurde initialisiert
            this.$servertalk.copyToDraftSelfService(
                "AenderungFirmenAdressdaten",
                store.subData
            );
        }
    },
    methods: {
        /*
        infoDocStatus(val) {
            this.$servertalk.doLog("infoDocStatus: ");
            this.$servertalk.doLog(val);
        },
        infoDocOk(val) {
            this.$servertalk.doLog("infoDocOk: " + val.uploadStatus);
        },
        docDocStatus(val) {
            this.$servertalk.doLog("docDocStatus: " + val.uploadStatus);
        },
        docDocOk(val) {
            this.$servertalk.doLog("docDocOk: " + val.uploadStatus);
        },
        newfiles(val, i) {
            this.$servertalk.doLog("newFile");
            this.$servertalk.doLog(val);
            store.subData.draft.sService.Entwuerfe.AenderungFirmenAdressdaten.uploadsDokumente =
                val;
        },
        */
        setLand(val) {
            if (val && typeof val === "object" && typeof val.value !== "undefined") {
                store.subData.draft.sService.Entwuerfe.AenderungFirmenAdressdaten.GLKZ =
                    val.value + "";
                //this.$forceUpdate(); // Erzwingt ein erneutes Rendern der Komponente
            }
        },
        validGPLZ() {
            const pattern = getPostalCodePattern();
            return pattern.test(store.subData.draft.sService.Entwuerfe.AenderungFirmenAdressdaten.GPLZ);
        },
        validGPOSTPLZ() {
            const pattern = getPostalCodePattern();
            return pattern.test(store.subData.draft.sService.Entwuerfe.AenderungFirmenAdressdaten.GPOSTPLZ);
        },
        validGSTRASSE() {
            const pattern = getStreetAndNumberPattern();
            return pattern.test(store.subData.draft.sService.Entwuerfe.AenderungFirmenAdressdaten.GSTRASSE);
        },
        sendDataFinished() {
            let messageCode = this.$servertalk.sendDataSelfService(
                "AenderungFirmenAdressdaten",
                store.subData
            );
            store.message =
                messageCode == 0
                    ? {
                        text: "Ihre Änderungen im Bereich\n Änderung Firmen-Adressdaten \n wurden erfolgreich beauftragt.",
                        status: "ok",
                    }
                    : {
                        text:
                            "Beim Speichern des Auftrags im Bereich\n Änderung Firmen-Adressdaten \n trat ein Fehler auf (Error-Code " +
                            messageCode +
                            ").",
                        status: "error",
                    };
            if (messageCode == 0) this.saved = true;
            this.$router.push("/auftrag-an-pensus");
        },
        resetData() {
            //this.$refs.SelectFamStand.reset(); // Zurücksetzen Select
            let messageCode = this.$servertalk.copyToDraftSelfService(
                "AenderungFirmenAdressdaten",
                store.subData
            );
            /*
                  this.sFamStand = {
                    text: this.$servertalk.getName(
                      "FAMSTAND",
                      store.subData.draft.sService.Entwuerfe.AenderungAnsprechpartnerdaten.FAMSTD
                    ),
                    key: store.subData.draft.sService.Entwuerfe.AenderungAnsprechpartnerdaten.FAMSTD
                  };*/
            store.message =
                messageCode == 0
                    ? {
                        text: "Ihre Änderungen im Bereich\n Änderung Firmen-Adressdaten \n wurden zurückgesetzt.",
                        status: "ok",
                    }
                    : {
                        text:
                            "Beim Zurücksetzen der Daten im Bereich\n Änderung Firmen-Adressdaten \n trat ein Fehler auf (Error-Code " +
                            messageCode +
                            ").",
                        status: "error",
                    };
        },
    },
    computed: {
        setSchwebendClass() {
            // Erhalte den Wert "Privatadresse" von $servertalk
            //const aenderung = this.$servertalk.gettAttr("fadata.Firmenstammdaten");
            //const aenderungPrivatadresseValue = this.$servertalk.gettAttr("subData.draft.sService.Entwuerfe.AenderungKontaktdaten");
            return null;
        },
        setStatusClass() {
            // Erhalte den Wert "AenderungAnsprechpartnerdaten" von $servertalk
            const AenderungFirmenAdressdaten = this.$servertalk.gettAttr(
                "subData.draft.sService.Entwuerfe.AenderungFirmenAdressdaten"
            );
            return AenderungFirmenAdressdaten;
        },
        setStatusClassGSTRASSE() {
            // Erhalte den Wert "GSTRASSE" von $servertalk
            return this.$servertalk.gettAttr(
                "subData.draft.sService.Entwuerfe.AenderungFirmenAdressdaten.GSTRASSE"
            );
        },
        setStatusClassGORT() {
            // Erhalte den Wert "GORT" von $servertalk
            return this.$servertalk.gettAttr(
                "subData.draft.sService.Entwuerfe.AenderungFirmenAdressdaten.GORT"
            );
        },
        setStatusClassGORTZUS() {
            // Erhalte den Wert "GORTZUS" von $servertalk
            return this.$servertalk.gettAttr(
                "subData.draft.sService.Entwuerfe.AenderungFirmenAdressdaten.GORTZUS"
            );
        },
        setStatusClassGPLZ() {
            // Erhalte den Wert "GPLZ" von $servertalk
            return this.$servertalk.gettAttr(
                "subData.draft.sService.Entwuerfe.AenderungFirmenAdressdaten.GPLZ"
            );
        },
        setStatusClassGPOSTFACH() {
            // Erhalte den Wert "GPOSTFACH" von $servertalk
            return this.$servertalk.gettAttr(
                "subData.draft.sService.Entwuerfe.AenderungFirmenAdressdaten.GPOSTFACH"
            );
        },
        setStatusClassGPOSTPLZ() {
            // Erhalte den Wert "GPOSTPLZ" von $servertalk
            return this.$servertalk.gettAttr(
                "subData.draft.sService.Entwuerfe.AenderungFirmenAdressdaten.GPOSTPLZ"
            );
        },
        setStatusClassGLKZ() {
            // Erhalte den Wert "GLKZ" von $servertalk
            return this.$servertalk.gettAttr(
                "subData.draft.sService.Entwuerfe.AenderungFirmenAdressdaten.GLKZ"
            );
        },
    },
};
</script>
<template>
    <div class="main-wrapper" :class="store.accessRestriction">
        <HauptMenue />
        <Transition>
            <div class="mt-36 md:mt-48 lg:mt-56 max-w-7xl mx-auto">
                <div class="max-w-5xl mx-auto mb-8 px-5 lg:px-0">
                    <h1
                        class="text-primary/75 font-bold text-xl sm:text-2xl md:text-3xl lg:text-4xl text-center mb-12 uppercase">
                        Änderung der Firmen-Adressdaten für
                        <br />
                        <span>
                            "{{ store.subData.data.Firmenstammdaten.FNAME }}
                            {{
                                $servertalk.getName(
                                    "GESFORM",
                                    store.subData.data.Firmenstammdaten?.FGESFORM
                                )
                            }}/{{ store.subData.data.Firmenstammdaten.FKENNR }}/{{
                                store.subData.data.Firmenstammdaten.FABRGR
                            }}"
                        </span>
                        melden
                    </h1>
                    <!--modal content-->
                    <Alertbox />
                    <div class="status-label mb-8" v-if="setStatusClass === 'modified'">
                        <span
                            class="bearbeitung bg-yellow-400 text-tertiary inline-block py-1 px-2 text-sm uppercase mb-2">
                            Entwurf
                        </span>
                        <span class="infotext text-tertiary text-sm block">
                            Dies ist ein Entwurf. Bitte bestätigen Sie diesen
                            Änderungsauftrag, indem Sie auf
                            <strong>Beauftragen</strong>
                            klicken.
                        </span>
                    </div>
                    <form :class="setStatusClass">
                        <div class="grid xl:grid-cols-1 sm:gap-5">
                            <div class="relative">
                                <label for="aendStr" class="text-sm mb-2 block">
                                    Strasse Firmensitz
                                    <sup>*</sup>
                                </label>
                                <input type="text" id="aendStr" :class="setStatusClassGSTRASSE" required
                                    class="peer w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500"
                                    v-model="store.subData.draft.sService.Entwuerfe.AenderungFirmenAdressdaten
                                        .GSTRASSE
                                        " :pattern="getStreetAndNumberPattern().source" />
                                <span
                                    class="invisible peer-invalid:visible text-red-500 text-sm absolute bottom-2 left-0 min-w-max">
                                    Bitte geben Sie eine Straße mit Hausnummer ein.
                                </span>
                            </div>
                        </div>
                        <div class="plz-stadt grid lg:grid-cols-6 gap-x-8">
                            <div class="plz lg:col-span-1 relative">
                                <label for="plz" class="text-sm mb-2 block">
                                    PLZ Firmensitz
                                    <sup>*</sup>
                                </label>
                                <input type="text" id="plz"
                                    class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 focus:border-tertiary/50 peer invalid:text-red-500 focus:invalid:border-red-500"
                                    :class="setStatusClassGPLZ" required v-model="store.subData.draft.sService.Entwuerfe.AenderungFirmenAdressdaten
                                        .GPLZ
                                        " inputmode="numeric" ref="plz" :pattern="getPostalCodePattern().source" />
                                <span
                                    class="invisible peer-invalid:visible text-red-500 text-sm absolute bottom-2 left-0 min-w-max">
                                    Die Postleitzahl stimmt nicht.
                                </span>
                            </div>
                            <div class="ort lg:col-span-3">
                                <label for="ort" class="text-sm mb-2 block">
                                    Ort Firmensitz
                                    <sup>*</sup>
                                </label>
                                <input type="text" id="ort"
                                    class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 focus:border-tertiary/50 peer invalid:text-red-500 focus:invalid:border-red-500"
                                    :class="setStatusClassGORT" required v-model="store.subData.draft.sService.Entwuerfe.AenderungFirmenAdressdaten
                                        .GORT
                                        " />
                            </div>
                            <div class="ortzusatz lg:col-span-2">
                                <label for="ortzusatz" class="text-sm mb-2 block">
                                    Ort Zusatz
                                </label>
                                <input maxlength="255" type="text" id="ortzusatz"
                                    class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 focus:border-tertiary/50 peer invalid:text-red-500 focus:invalid:border-red-500"
                                    :class="setStatusClassGORTZUS" v-model="store.subData.draft.sService.Entwuerfe.AenderungFirmenAdressdaten
                                        .GORTZUS
                                        " />
                            </div>
                        </div>
                        <div class="plz-stadt-postfach grid lg:grid-cols-6 gap-x-8">
                            <div class="plz-postfach lg:col-span-1 relative">
                                <label for="plz-postfacj" class="text-sm mb-2 block">
                                    PLZ Postfach
                                </label>
                                <input type="text" id="plz-postfach"
                                    class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 focus:border-tertiary/50 peer invalid:text-red-500 focus:invalid:border-red-500"
                                    :class="setStatusClassGPOSTPLZ" v-model="store.subData.draft.sService.Entwuerfe.AenderungFirmenAdressdaten
                                        .GPOSTPLZ
                                        " inputmode="numeric" ref="plz" :pattern="getPostalCodePattern().source" />
                                <span
                                    class="invisible peer-invalid:visible text-red-500 text-sm absolute bottom-2 left-0 min-w-max">
                                    Die Postleitzahl stimmt nicht.
                                </span>
                            </div>
                            <div class="postfach lg:col-span-5">
                                <label for="postfach" class="text-sm mb-2 block">
                                    Postfach
                                </label>
                                <input type="text" id="postfach"
                                    class="w-full p-3 border border-secondary/50 rounded-md mb-8 focus:outline-0 focus:border-tertiary/50"
                                    :class="setStatusClassGPOSTFACH" required v-model="store.subData.draft.sService.Entwuerfe.AenderungFirmenAdressdaten
                                        .GPOSTFACH
                                        " />
                            </div>
                        </div>
                        <div class="grid xl:grid-cols-1 sm:gap-5">
                            <div class="relative">
                                <label for="land" class="text-sm mb-2 block">
                                    Land
                                    <sup>*</sup>
                                </label>
                                <CustomSelect @input="setLand" class="select" :class="setStatusClassGLKZ" required
                                    keyEmpty="0" :options="sLandOptions"
                                    :default="store.subData.draft.sService.Entwuerfe.AenderungFirmenAdressdaten.GLKZ"
                                    :oldDefault="store.subData.olddraft.sService.Entwuerfe.AenderungFirmenAdressdaten.GLKZ
                                        " :tabindex="1" />

                            </div>
                        </div>
                        <div class="button-wrapper flex justify-center gap-4 [&>button]:mx-0">
                            <ButtonSendDataSelfService :disabled="setStatusClass !== 'modified' ||
                                !validGSTRASSE() ||
                                !validGPLZ() ||
                                !validGPOSTPLZ() ||
                                store.subData.draft.sService.Entwuerfe.AenderungFirmenAdressdaten.GLKZ == '0' ||
                                (store.subData.isReadOnly || (store.selectedFirma?.VERMITTLER == 1))
                                " @finished="sendDataFinished" />
                            <ButtonResetServiceData :disabled="setStatusClass !== 'modified' ||
                                (store.subData.isReadOnly || (store.selectedFirma?.VERMITTLER == 1))"
                                @reset="resetData" />
                        </div>
                    </form>
                    <DatenschutzHinweis />
                    <ButtonBack />
                </div>
            </div>
        </Transition>

        <FooterMenu />
    </div>
</template>
