<script setup>
import CustomSelect from "./CustomSelect.vue";
</script>

<script>
import { store } from "./store.vue";
/** Komponente zur Anzeige persönlicher Daten des Benutzers ohne Änderungsmöglichkeit
 * @displayName Anzeige persönlicher Daten
 */
export default {
  name: "DataDetailsTab",
  data() {
    return {
      store,
      sAnredeOptions: this.$servertalk.getSelectOpts("ADRANREDE"),
      sVersorgungsnummer: Array.isArray(store.masterData.data.Versorgung.PMNR)
        ? store.masterData.data.Versorgung.PMNR.map(
          (item) => item["@attributes"].PMNR
        ).join(", ")
        : typeof store.masterData.data.Versorgung === "object"
          ? store.masterData.data.Versorgung.PMNR["@attributes"].PMNR
          : "",
      sFamStandOptions: this.$servertalk.getSelectOpts("FAMSTAND"),
    };
  },

  mounted() {
    window.scrollTo(0, 0);
  },
  computed: {
    showFamStand() {
      let r = false;
      if (Array.isArray(store.masterData.data.Versorgung.PMNR)) {
        //es ist ein Array. Alles ok.
        let vari = store.masterData.data.Versorgung.PMNR;
        let ausk = vari.find((PMNR) => PMNR["AUSZAHLKNR"] == "4");
        r = ausk && ausk.length > 0;
      } else if (typeof store.masterData.data.Versorgung.PMNR == "object") {
        r = store.masterData.data.Versorgung.PMNR["AUSZAHLKNR"] == "4";
      }
      return r;
    }
  },
  methods: {
    /*
    selectAnrede(key) {
      store.masterData.data.Stammdaten.GESCHL = key.value;
    },
    selectFamstand(key) {
      store.masterData.data.Stammdaten.FAMSTD = key.value;
    },
    aendern(e) {
      this.$servertalk.saveStore();
      e.preventDefault();
    },
    async reload() {
      this.render = false;
      await this.$nextTick();
      this.render = true;
    },
    */
  },
  //props: ["options", "value"],
};
</script>

<template>
  <form>
    <div class="kennummer mb-8">
      <h2 class="text-2xl text-primary" v-if="store.masterData.data.Versorgung">
        PM-Nr : {{ sVersorgungsnummer }}
      </h2>
    </div>
    <label for="anrede" class="text-sm mb-2 block">
      Anrede
    </label>
    <CustomSelect class="schwebend" :options="sAnredeOptions" :default="store.masterData.data.Stammdaten.GESCHL"
      :oldDefault="store.masterData.oldData.Stammdaten.GESCHL" />
    <div class="grid lg:grid-cols-3 gap-x-8">
      <div>
        <label for="titel" class="text-sm mb-2 block">Titel</label>
        <input maxlength="255" type="text" id="titel" class="w-full p-3 border border-secondary/50 rounded-md mb-8"
          required v-model="store.masterData.data.Stammdaten.TITEL" disabled />
      </div>
      <div>
        <label for="namensvorsatz" class="text-sm mb-2 block">
          Namensvorsatz
        </label>
        <input maxlength="255" type="text" id="namensvorsatz" v-model="store.masterData.data.Stammdaten.NAMEVS"
          class="w-full p-3 border border-secondary/50 rounded-md mb-8" disabled />
      </div>
      <div>
        <label for="namenszusatz" class="text-sm mb-2 block">
          Namenszusatz
        </label>
        <input maxlength="255" type="text" id="namenszusatz" v-model="store.masterData.data.Stammdaten.NAMEZUS"
          class="w-full p-3 border border-secondary/50 rounded-md mb-8" disabled />
      </div>
    </div>
    <div class="grid lg:grid-cols-2 gap-x-8">
      <div class="relative">
        <label for="vorname" class="text-sm mb-2 block">
          Vorname
        </label>
        <input maxlength="255" type="text" id="vorname"
          class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 focus:border-tertiary/50 peer invalid:text-red-500 focus:invalid:border-red-500"
          disabled v-model="store.masterData.data.Stammdaten.VORNAME" />
        <span class="invisible peer-invalid:visible text-red-500 text-sm absolute bottom-2 left-0">
          Bitte tragen Sie hier Ihren Vornamen ein.
        </span>
      </div>
      <div class="relative">
        <label for="nachname" class="text-sm mb-2 block">Nachname</label>
        <input maxlength="255" type="text" id="nachname" v-model="store.masterData.data.Stammdaten.NAME"
          class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 focus:border-tertiary/50 peer invalid:text-red-500 focus:invalid:border-red-500"
          disabled />
        <span class="invisible peer-invalid:visible text-red-500 text-sm absolute bottom-2 left-0">
          Bitte tragen Sie hier Ihren Nachnamen ein.
        </span>
      </div>
    </div>


    <div class="grid lg:grid-cols-2 gap-x-8">

      <div v-if="showFamStand">
        <label for="familienstand" class="text-sm mb-2 block">
          Familienstand
        </label>
        <CustomSelect class="schwebend" :options="sFamStandOptions" :default="store.masterData.data.Stammdaten.FAMSTD"
          :oldDefault="store.masterData.oldData.Stammdaten.FAMSTD" :tabindex="2" />
      </div>
      <div v-if="store.masterData.data.Stammdaten.EHEDAT.length">
        <label for="datumehe" class="text-sm mb-2 block">
          Datum der Eheschließung
        </label>
        <input maxlength="255" type="date" id="datumehe" v-model="store.masterData.data.Stammdaten.EHEDAT"
          class="w-full p-3 border border-secondary/50 rounded-md mb-8" disabled />
      </div>
    </div>
    <div class="grid lg:grid-cols-3 gap-x-8">
      <div>
        <label for="geburtsdatum" class="text-sm mb-2 block">
          Geburtsdatum
        </label>
        <input maxlength="255" type="date" id="geburtsdatum" v-model="store.masterData.data.Stammdaten.GEBDAT"
          class="w-full p-3 border border-secondary/50 rounded-md mb-8" disabled />
      </div>
      <div>
        <label for="sozialversicherungsnummer" class="text-sm mb-2 block">
          Sozialversicherungsnummer
        </label>
        <input maxlength="255" type="text" id="sozialversicherungsnummer"
          class="w-full p-3 border border-secondary/50 rounded-md mb-8" required v-model="store.masterData.data.Stammdaten.SVNR"
          inputmode="numeric" disabled />
      </div>
      <div>
        <label for="steuer-id" class="text-sm mb-2 block">Steuer-ID</label>
        <input maxlength="255" type="text" id="steuer-id" class="w-full p-3 border border-secondary/50 rounded-md mb-8"
          required v-model="store.masterData.data.Stammdaten.STEUERIDNR" inputmode="numeric" disabled />
      </div>
    </div>
    <router-link to="/sservice-aenderung-persoenlichedaten" custom v-slot="{ navigate }">
      <button @click="navigate" :disabled="store.masterData.isReadOnly" role="link" type="submit"
        class="bg-primary text-white py-4 w-72 rounded-md text-lg lg:text-xl mx-auto block hover:bg-tertiary transition disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-primary">
        Persönliche Daten ändern
      </button>
    </router-link>
  </form>
</template>
